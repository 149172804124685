import { flexRender } from "@tanstack/react-table";
import tw from "twin.macro";
import "styled-components/macro";

import { Spinner } from "../Spinner";

import { CommonCells } from "./common";
import { getCellStyle } from "./helpers";
import { ColumnExtenedDef, GenericRecord, Row } from "./types";

type PropsCustom<T extends GenericRecord> = {
  row: Row<T>;
  loading?: boolean;
};

type Props<T extends GenericRecord, SUB_DATA extends GenericRecord> = PropsCustom<T> & {
  data: SUB_DATA[];
};

// for iterating through data and displaying them as table rows
export const SubRows = <DATA extends GenericRecord, SUB_DATA extends GenericRecord>({
  loading,
  data,
  row,
}: Props<DATA, SUB_DATA>) =>
  loading ? (
    <Spinner size={42} tw="absolute top-[calc(50% - 21px)] left-[calc(50% - 21px)] z-10" />
  ) : (
    <>
      {data.map((item, i) => (
        <div className="tr" key={i}>
          {row.getVisibleCells().map((cell, i) => (
            <div className="td" key={cell.id} style={getCellStyle(cell.column)}>
              {CommonCells.expander.id === cell.column.columnDef.id
                ? null
                : // eslint-disable-next-line
                // @ts-ignore
                (cell.column.columnDef as ColumnExtenedDef<DATA>).subAccessor
                ? // eslint-disable-next-line
                  // @ts-ignore
                  (cell.column.columnDef as ColumnExtenedDef<DATA>).subAccessor(item, i)
                : flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          ))}
        </div>
      ))}
    </>
  );

// for displaying some custom component inside subrow
export const CustomSubRows = <DATA extends GenericRecord>({
  loading,
  row,
  children,
}: React.PropsWithChildren<PropsCustom<DATA>>) =>
  loading ? (
    <Spinner size={42} tw="absolute top-[calc(50% - 21px)] left-[calc(50% - 21px)] z-10" />
  ) : (
    <div tw="flex">
      {/* display only first column - expander column */}
      {row
        .getVisibleCells()
        .slice(0, 1)
        .map((cell) => (
          <div className="td" key={cell.id} style={getCellStyle(cell.column)} />
        ))}

      <div className="td" tw="flex-[1 0 100%]">
        {children}
      </div>
    </div>
  );
