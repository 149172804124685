import { createContext, useContext, useEffect, useMemo, useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { FormProvider, UseLiveConfig, useFieldData, useForm, useFormGenerator } from "common/form";
import { BaseOption, CustomRenderFieldTypes, CustomRenderFields, customRender } from "common/form/renderFields";
import { Container } from "common/guideline";
import { makeSorter } from "common/helpers";
import { GetReportHeaderQuery, useGetReportHeaderQuery } from "generated";

type SiteGroupData = {
  siteGroup: string | null;
  location: string | null;
  machine: string | null;
};

type TSiteGroupContext = SiteGroupData & {
  setSiteGroup: (data: Partial<SiteGroupData>) => void;
};

const getEmptyContext = (): SiteGroupData => ({
  siteGroup: null,
  location: null,
  machine: null,
});

const SiteGroupContext = createContext<TSiteGroupContext>({} as TSiteGroupContext);
const NOT_GROUPPED_VALUE = "*/not_group";

const getFields = ({ getReportHeader }: GetReportHeaderQuery, filterByMachineUuid?: boolean): CustomRenderFields[] => {
  const siteGroupOptions = [
    { label: "common.notGrouped", value: NOT_GROUPPED_VALUE, data: { locations: [] } },
    ...(getReportHeader?.smartLocationGroups?.map((s) => ({
      label: s?.name,
      value: s?.locations?.map((l?) => l?.nodeId),
      data: { ...s, locations: s?.locations?.flatMap((l) => l?.nodeId || []).sort(makeSorter("label")) || [] },
    })) || []),
  ];
  const locationOptions =
    getReportHeader?.locations?.map((s) => ({ label: s?.name, value: s?.nodeId, data: s })).sort(makeSorter("label")) ||
    [];
  const machineOptions =
    getReportHeader?.machines
      ?.map((s) => ({ label: s?.name, value: filterByMachineUuid ? s?.uuid : s?.nodeId }))
      .sort(makeSorter("label")) || [];

  const grouppedLocations = siteGroupOptions.flatMap((g) => g.data.locations);
  const notGrouppedLocationsOptions = locationOptions.filter((o) => !grouppedLocations.includes(o.value || ""));

  const useConfigLocation: UseLiveConfig<CustomRenderFieldTypes["select"]> = (prev) => {
    const siteGroup = useFieldData<SiteGroupData["siteGroup"]>("siteGroup", "values");

    return [
      {
        ...prev,
        options: !siteGroup
          ? prev.options
          : siteGroup === NOT_GROUPPED_VALUE
          ? notGrouppedLocationsOptions
          : siteGroupOptions
              .find((o) => o.value === siteGroup)
              ?.data.locations.flatMap((id) => (prev.options || []).find((o) => (o as BaseOption).value === id)) || [],
      },
      true,
    ];
  };

  const useConfigMachine: UseLiveConfig<CustomRenderFieldTypes["select"]> = (prev) => {
    const location = useFieldData("location", "values");

    return [
      {
        ...prev,
        options: !location
          ? prev.options
          : locationOptions
              .find((o) => o.value === location)
              ?.data?.machineNodeIds?.flatMap(
                (id) => (prev.options || []).find((o) => (o as BaseOption).value === id) || [],
              ) || [],
      },
      true,
    ];
  };

  return [
    {
      type: "select",
      name: "siteGroup",
      label: "administration.lg.title_one",
      variant: ["sm", "dropdown"],
      labelVariant: "responsive",
      isClearable: true,
      options: siteGroupOptions,
      calculation: {
        // clear all values when site group change
        updates: (siteGroup) => ({ ...getEmptyContext(), siteGroup }),
      },
    },
    {
      type: "live",
      name: "location",
      useConfig: useConfigLocation as any,
      fieldConfig: {
        type: "select",
        name: "location",
        label: "location.location_one",
        variant: ["sm", "dropdown"],
        labelVariant: "responsive",
        isClearable: true,
        options: locationOptions,
        calculation: {
          // clear machine value when location change
          updates: (location, _, { siteGroup }) => ({ ...getEmptyContext(), siteGroup, location }),
        },
      },
    },
    {
      type: "live",
      name: "machine",
      useConfig: useConfigMachine as any,
      fieldConfig: {
        type: "select",
        name: "machine",
        label: "machine.machine_one",
        variant: ["sm", "dropdown"],
        labelVariant: "responsive",
        isClearable: true,
        options: machineOptions,
      },
    },
  ];
};

export const SiteGroupContextProvider = ({ children }) => {
  const [data, setData] = useState<SiteGroupData>(getEmptyContext);
  const value = useMemo(() => ({ ...data, setSiteGroup: (d) => setData((p) => ({ ...p, ...d })) }), [data, setData]);
  return <SiteGroupContext.Provider value={value}>{children}</SiteGroupContext.Provider>;
};

export const useSiteGroupContext = () => useContext(SiteGroupContext);

const getValue = (v: string | string[] | null, asList: boolean) =>
  !v ? undefined : asList ? (Array.isArray(v) ? v : [v]) : Array.isArray(v) ? v[0] : v;

export const useMappedSiteGroupContext = <T extends boolean>(
  asList: T,
): [
  {
    location: (true extends T ? string[] : string) | undefined;
    machine: (true extends T ? string[] : string) | undefined;
    siteGroup: (true extends T ? string[] : string) | undefined;
  },
  TSiteGroupContext,
] => {
  const context = useSiteGroupContext();
  const mapped = useMemo(
    () => ({
      location: getValue(context.location, asList),
      machine: getValue(context.machine, asList),
      siteGroup: getValue(context.siteGroup, asList),
    }),
    [context.location, context.machine, context.siteGroup, asList],
  );

  return [mapped as any, context];
};

const StyledContainer = styled(Container)`
  ${tw`grid grid-cols-3 gap-1 sm:gap-3 z-10 print:hidden`}
  > * {
    ${tw`sm:(max-w-xs)`}
  }
`;

type Props = {
  filterByMachineUuid?: boolean;
};

export const SiteGroupPicker: React.FC<Props> = ({ filterByMachineUuid }) => {
  const { setSiteGroup, location, machine, siteGroup } = useSiteGroupContext();
  const form = useForm<SiteGroupData>({ onSubmit: () => null, initial: { location, machine, siteGroup } });
  const { data } = useGetReportHeaderQuery();
  const dom = useFormGenerator(
    useMemo(() => getFields(data || {}, filterByMachineUuid), [data, filterByMachineUuid]),
    customRender,
  );

  useEffect(
    () =>
      form.useStore.subscribe(
        (s) => s.values,
        (s) =>
          setSiteGroup({
            ...getEmptyContext(),
            ...s,
            siteGroup: s.siteGroup === NOT_GROUPPED_VALUE ? null : s.siteGroup,
          }),
      ),
    [form.useStore, setSiteGroup],
  );

  useEffect(() => {
    machine && form.useStore.getState().onChangeField("machine", "onChange", machine);
  }, [machine, form.useStore]);

  return (
    <FormProvider form={form}>
      <StyledContainer variant="shadow">{dom}</StyledContainer>
    </FormProvider>
  );
};
