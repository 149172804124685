import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as UserCircleSVG } from "assets/icons/UserCircle.svg";
import { useAuth } from "auth/hooks";
import { Breadcrumb, Breadcrumbs, ChangeThemeButton, Container, Line, Text } from "common/guideline";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "common.myProfile",
    route: "USER_PROFILE",
  },
];

export const UserProfile = () => {
  const user = useAuth(useAuth.actions.getUser);

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Container variant="borderBottom">
        <div tw="flex items-center gap-2 mt-3">
          <UserCircleSVG width={48} height={48} />
          <Text variant="heading" tKey="common.helloProfile" tValue={{ name: user?.sub }} />
        </div>
      </Container>
      <Container variant="pageWrapper">
        <Text variant="heading" tKey="common.myProfile" />
        <Text variant="paragraph" tKey="common.profileDesc" />
        <Line tw="my-3" variant="horizontal" />
        <Text tKey="user.settings.appearance" variant="heading" />
        <ChangeThemeButton />
      </Container>
    </>
  );
};
