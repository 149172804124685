import { Download } from "phosphor-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { downloadConfigurationFile } from "administration/helpers";
import { ReactComponent as ConfigTemplateSVG } from "assets/icons/ConfigTemplate.svg";
import { PageLayout } from "base/components";
import { Badge, Button, CommonCells, Table, TableTypes, usePagination } from "common/guideline";
import { GetAllTemplatesQuery, useGetAllTemplatesQuery } from "generated";

import { breadcrumbs } from "./breadcrumbs";
import { CreateConfigTemplate } from "./ConfigTemplateForm/ConfigTemplateForm";
import { RowActions } from "./RowActions";

type ConfigTemplate = NonNullable<NonNullable<GetAllTemplatesQuery["getAllTemplates"]>["0"]>;

const getColumns: TableTypes.TranslatedColumns<ConfigTemplate> = (t) => [
  {
    header: t("administration.rc.name"),
    accessorKey: "name",
  },
  {
    header: t("administration.rc.version"),
    accessorKey: "version",
    cell({ getValue }) {
      return getValue() ? <Badge>{getValue<ConfigTemplate["version"]>()}</Badge> : "-";
    },
  },
  {
    header: t("administration.rc.file"),
    accessorKey: "packageName",
    minSize: 300,
    cell({ getValue, row }) {
      return (
        <div tw="flex flex-nowrap items-center gap-2">
          <div>{(getValue() as any) || ""}</div>
          <Button variant={["primary", "sm"]} data-test={`downloadTemplate-${row.index}`}>
            <Download
              size={18}
              weight="duotone"
              onClick={() => downloadConfigurationFile(getValue<ConfigTemplate["packageName"]>())}
            />
          </Button>
        </div>
      );
    },
  },
  {
    header: t("administration.rc.description"),
    accessorKey: "description",
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const ConfigTemplatesTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(10);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGetAllTemplatesQuery({
    variables: {
      cpPageRequest: {
        page: pageIndex,
        size: pageSize,
      },
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const values = data?.getAllTemplates || [];

  return (
    <Table<ConfigTemplate>
      tableName="configTemplates"
      columns={columns}
      data={values as ConfigTemplate[]}
      loading={loading}
      initialLoading={previousData === undefined}
      onPagination={setPagination}
      error={error}
    />
  );
};

export const ConfigTemplates = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.rc.title"
    subtitle="administration.rc.desc"
    Icon={ConfigTemplateSVG}
    actions={<CreateConfigTemplate />}
  >
    <ConfigTemplatesTable />
  </PageLayout>
);
