import { Fragment, memo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CopySVG } from "assets/icons/Copy.svg";
import { Badge, CustomSubRows, FullLine, Spinner, TableTypes, Text, useToast } from "common/guideline";
import { copyToClipboard, numberWithDecimalCount, withDefault } from "common/helpers";
import { useFindValueBagsForRmTransactionQuery } from "generated";

import type { TransactionRows } from "./Transactions";

export const TransactionsSubRow: TableTypes.SubRowsComponent<TransactionRows> = memo(({ row }) => {
  const isCorrectionTransaction = row.original.__typename === "CorrectionTransactionReportRowDto";
  const { data, loading } = useFindValueBagsForRmTransactionQuery({
    variables: { nodeId: row.original.nodeId },
  });
  const valueBags = data?.findValueBagsForRmTransaction || [];

  return (
    <CustomSubRows row={row}>
      <div tw="flex space-x-6">
        {loading ? (
          <Spinner />
        ) : valueBags.length == 0 ? (
          <Text tKey="machine.devInfoMissing" />
        ) : (
          valueBags.map((valueBag, i) => (
            <div tw="flex flex-row gap-2" key={i}>
              <div tw="grid [grid-template-columns:max-content_1fr] [grid-gap:12px] ">
                {i === 0 ? null : <FullLine tw="mt-3" />}
                <Text variant="labelAlt" tKey="tranReport.UUID" tw="pt-3" />
                <div tw="w-auto inline-flex items-center gap-1">
                  <Badge variant="info">{withDefault(row.original.uuid)}</Badge>
                  <CopySVG
                    tw="cursor-pointer"
                    width={20}
                    height={20}
                    onClick={() =>
                      copyToClipboard(row.original.uuid || "", () => useToast.actions.show("common.clipboard"))
                    }
                  />
                </div>
                <Text variant="labelAlt" tKey="tranReport.timestamp" />
                <Text tKey="dateFormat" tValue={{ date: new Date(row.original.dateTime), formatString: "PPp" }} />
                <Text variant="labelAlt" tKey="tranReport.timezoneOffset" />
                <span>{row.original.timeZoneUtcOffset}</span>
                <Text variant="labelAlt" tKey="tranReport.valBag.totalAmount" />
                <span>{valueBag?.totalAmount}</span>
                <Text variant="labelAlt" tKey="tranReport.valBag.type" />
                <span>{valueBag?.type}</span>
                <Text variant="labelAlt" tKey="tranReport.valBag.rejects" />
                <span>{valueBag?.rejects}</span>
                {isCorrectionTransaction ? (
                  <>
                    <Text variant="labelAlt" tKey="tranCorr.corrReason" />
                    <span>{withDefault(row.original.correctionReason)}</span>
                    <Text variant="labelAlt" tKey="tranCorr.createdBy" />
                    <span>{withDefault(row.original.createdBy)}</span>
                    <Text variant="labelAlt" tKey="tranCorr.machineUser" />
                    <span>{withDefault(row.original.machineUserName)}</span>
                  </>
                ) : (
                  <>
                    <Text variant="labelAlt" tKey="tranReport.cardIdentifier" />
                    <span>{withDefault(row.original.cardIdentifier)}</span>
                    <Text variant="labelAlt" tKey="tranReport.workUnitGroup" />
                    <span>{withDefault(row.original.workUnitName)}</span>
                    <Text variant="labelAlt" tKey="tranReport.mixEdited" />
                    <span>{withDefault(row.original.mixEdited)}</span>
                  </>
                )}
              </div>
              <div tw="flex flex-row gap-2">
                {!valueBag?.values?.length ? (
                  <Text tKey="machine.devInfoMissing" />
                ) : (
                  valueBag.values.map((value, j) => (
                    <div key={value?.nodeId || j} tw="grid [grid-template-columns:max-content_1fr] [grid-gap:12px] ">
                      {j === 0 ? null : <FullLine tw="mt-3" />}
                      <Text variant="labelAlt" tKey="tranReport.valBag.currency" />
                      <span>{value?.currency}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.denomination" />
                      <span>{numberWithDecimalCount.merge(value?.denomination || 0, value?.decimals || 0)}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.count" />
                      <span>{value?.count}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.pieceValue" />
                      <span>{numberWithDecimalCount.merge(value?.pieceValue || 0, value?.decimals || 0)}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.exchangeRate" />
                      <span>
                        {numberWithDecimalCount.merge(value?.exchangeRate || 0, value?.exchangeRateDecimals || 0)}
                      </span>

                      <Text variant="labelAlt" tKey="tranReport.valBag.reference" />
                      <span>{value?.reference}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.valType" />
                      <span>{value?.type}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.claimedValueType" />
                      <span>{value?.claimedValueType}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.customType" />
                      <span>{value?.customType}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.c3Count" />
                      <span>{value?.c3Count}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.c4bCount" />
                      <span>{value?.c4bCount}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.creditCat2" />
                      <span>{value?.creditCat2}</span>
                      <Text variant="labelAlt" tKey="tranReport.valBag.creditCat3" />
                      <span>{value?.creditCat3}</span>
                    </div>
                  ))
                )}
              </div>
            </div>
          ))
        )}
        {isCorrectionTransaction && row.original.totalAmounts && (
          <div tw="grid [grid-template-columns:max-content_1fr] [grid-gap:12px] self-start">
            <Text variant="labelAlt" tKey="tranReport.currency" />
            <Text variant="labelAlt" tKey="tranReport.total" />
            {row.original.totalAmounts.map((a, i) => (
              <Fragment key={i}>
                <FullLine />
                <span>{a?.key}</span>
                <span>{a?.value}</span>
              </Fragment>
            ))}
            <FullLine />
          </div>
        )}
      </div>
    </CustomSubRows>
  );
});

TransactionsSubRow.displayName = "TransactionsSubRow";
