import { createBrowserHistory } from "history";

import { HistoryBlockerState, withBlocker } from "./withBlocker";
import { PreserveQueryState, withPreserveQuery } from "./withPreserveQuery";
import { withPreviousRoute } from "./withPreviousRoute";

export type { BlockerArg } from "./withBlocker";
export type { PreviousRoute } from "./withPreviousRoute";
export { useNavigatonBlocker } from "./hooks";

export type DefaultHistoryState = HistoryBlockerState & PreserveQueryState;

// decorated history
export const history = withBlocker(withPreviousRoute(withPreserveQuery(createBrowserHistory({ window }))));
