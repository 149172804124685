import { isValid } from "date-fns";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text, useModalComponent, useToast } from "common/guideline";
import {
  TIME_ZONE_UTC_OFFSET_REG_EXP,
  currencyOptions,
  evictQuery,
  getTimeZoneUtcOffset,
  numberWithDecimalCount,
  stringToPossibleFloat,
} from "common/helpers";
import {
  CorrectionTransactionDtoIn,
  GenerateTransactionsReportDocument,
  useCreateCorrectionTransactionMutation,
} from "generated";
import { getSelectMachinesField } from "machine/components";
import { getSelectMachineUserField } from "machineUser/components";

import { getSelectCorrectionReasonsField } from "../../components";

export type CorrectionTransactionDtoInFormData = CorrectionTransactionDtoIn;

const Wrapper = styled.div`
  ${tw`grid gap-4`}
`;

const WrapperColumns = styled(Wrapper)`
  ${tw`sm:[grid-template-columns:1fr_0.5fr]`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      getSelectMachineUserField({
        name: "userId",
        label: "tranCorr.machineUser",
        isMulti: false,
        validate: { type: "string", required: true },
        getValue: (m) => m?.userId || "",
        configWithOptions: (options) => ({
          calculation: {
            updates: {
              userName: (userId) => options.find((o) => o.value === userId)?.label || undefined,
            },
          },
        }),
      }),
      getSelectMachinesField({
        name: "machineNodeId",
        isMulti: false,
        validate: { type: "string", required: true },
      }),
      getSelectCorrectionReasonsField({
        name: "correctionReason",
        isMulti: false,
        validate: { type: "string", required: true },
      }),
      {
        type: "container",
        Component: WrapperColumns,
        fields: [
          {
            type: "number",
            name: "totalAmount",
            label: "tranCorr.value",
            validate: { type: "number", required: true },
            parse: stringToPossibleFloat,
          },
          {
            type: "select",
            name: "currency",
            label: "tranCorr.currency",
            validate: { type: "string", required: true },
            options: currencyOptions,
          },
        ],
      },
      {
        type: "container",
        Component: WrapperColumns,
        fields: [
          {
            type: "date",
            name: "dateTime",
            label: "tranCorr.date",
            toDate: new Date(),
            dateFormat: "PPp",
            validate: (v) => (v ? (isValid(v) ? null : "common.form.err.invalidDate") : "common.form.err.required"),
            calculation: {
              updates: {
                timeZoneUtcOffset: getTimeZoneUtcOffset,
              },
            },
          },
          {
            type: "text",
            name: "timeZoneUtcOffset",
            label: "tranCorr.utcOffset",
            parseOnBlur: getTimeZoneUtcOffset,
            defaultValue: getTimeZoneUtcOffset(new Date()),
            validate: {
              type: "string",
              required: true,
              pattern: [TIME_ZONE_UTC_OFFSET_REG_EXP, "tranCorr.offsetPatternErr"],
            },
          },
        ],
      },
      {
        type: "textarea",
        name: "comment",
        label: "tranCorr.comment",
        rows: 4,
      },
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<CorrectionTransactionDtoInFormData>;
};

const CorrectionTransactionForm: React.FC<Props> = ({ onSubmit }) => (
  <>
    <Text tKey="tranCorr.create" variant="heading" tw="block pb-4" />
    <SchemaForm<CorrectionTransactionDtoInFormData>
      fields={fields}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-end">
          <FormData type="isSubmittig">
            {(isSubmittig) => (
              <Button disabled={isSubmittig} type="submit" tw="mt-6" data-test="submitForm">
                <Text tKey="tranCorr.submit" />
              </Button>
            )}
          </FormData>
        </div>
      )}
    />
  </>
);

const useCreateCorrectionReasonModal = () => {
  const [createCorrectionTransaction] = useCreateCorrectionTransactionMutation();

  const modalData = useModalComponent({
    modalProps: {
      variant: ["modal", "md"],
      backdropClose: false,
    },
    Component: (
      <CorrectionTransactionForm
        onSubmit={(input) => {
          const [totalAmount, decimals] = numberWithDecimalCount.split(input.totalAmount);

          createCorrectionTransaction({
            variables: { input: { ...input, totalAmount, decimals } },
            ignoreResults: true,
            update: (cache) => evictQuery(GenerateTransactionsReportDocument, cache),
          })
            .then(() => {
              useToast.actions.show("tranCorr.createSuccess", { variant: "success" });
              modalData[0]();
            })
            .catch(() => useToast.actions.show("tranCorr.createError", { variant: "error" }));
        }}
      />
    ),
  });

  return modalData;
};

export const CreateCorrectionReasonModalForm = () => {
  const [show] = useCreateCorrectionReasonModal();

  return (
    <Button onClick={() => show()} data-test="showCreateModal">
      <Text tKey="tranCorr.create" />
    </Button>
  );
};
