import { Map as LMap, LatLngExpression, LatLngTuple } from "leaflet";
import { Broadcast, CaretDown, Eye } from "phosphor-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import tw, { theme } from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { OnlineStatusBadge, OnlineStatusBadgeEmpty, useSiteGroupContext } from "base/components";
import { InputRaw } from "common/form/renderFields";
import { Button, FullLine, LeafletMap, MapMarker, MarkerClusterGroup, Text } from "common/guideline";
import { calculateDistance, getCenterPoint, logarithmNumber } from "common/helpers/mapGeography";
import {
  GetReportHeaderQuery,
  MachineOnlineStatusDto,
  useGetMachinesOnlineStatusQuery,
  useGetReportHeaderQuery,
} from "generated";
import { useTenant } from "tenant/context";

type Location = NonNullable<NonNullable<NonNullable<GetReportHeaderQuery["getReportHeader"]>["locations"]>[0]>;
type Machine = NonNullable<NonNullable<NonNullable<GetReportHeaderQuery["getReportHeader"]>["machines"]>[0]>;

type PopupProps = {
  machine?: Machine;
  onlineStatus?: MachineOnlineStatusDto["onlineStatus"];
};

const MapPopup: React.FC<PopupProps> = ({ machine, onlineStatus }) => {
  const context = useSiteGroupContext();

  return (
    <div tw="flex flex-col items-center gap-2">
      <data data-machine-id={machine?.nodeId} />
      <Text variant="labelAlt">{machine?.name ?? "machine.noName"}</Text>
      <FullLine />
      <OnlineStatusBadge value={onlineStatus} />
      <div tw="inline-flex gap-2">{/* Error status placeholder */}</div>
      <Link route="CASH_OPERATIONS" onClick={() => context.setSiteGroup({ machine: machine?.nodeId })}>
        <Button variant={["primary", "withIcon", "sm"]}>
          <Eye size={16} weight="duotone" />
          <Text tKey="common.viewDetails" />
        </Button>
      </Link>
    </div>
  );
};

type MachineUuid = string;

const getLatLngTupleFromLocation = (location: Location): LatLngTuple | null =>
  location?.latitude && location?.longitude ? [parseFloat(location?.latitude), parseFloat(location?.longitude)] : null;

const colors = {
  CatchingUp: theme`colors.warning.default`,
  Offline: theme`colors.error.default`,
  Online: theme`colors.success.default`,
};

const getOnlineStatusColor = (status?: MachineOnlineStatusDto["onlineStatus"]) =>
  colors[status ?? ""] ?? theme`colors.gray.6`;

export const OperationsMap = () => {
  const mapRef = useRef<LMap | null>(null);

  const { data } = useGetReportHeaderQuery();
  const locationsWithMachines = useMemo(() => {
    const machines = data?.getReportHeader?.machines || [];
    const locations = data?.getReportHeader?.locations || [];

    return locations.map((l) => ({
      ...l,
      machines: l?.machineNodeIds?.flatMap((nodeId) => machines.find((m) => m?.nodeId === nodeId) || []) || [],
    }));
  }, [data]);

  const tenantId = useTenant(useTenant.actions.getTenantId) || "";
  const onlineStatuses = useGetMachinesOnlineStatusQuery({ variables: { input: { tenantId } } });
  const onlineStatusesByMachineUuid = useMemo(
    () =>
      (onlineStatuses.data?.getMachinesOnlineStatus || []).reduce((acc, curr) => {
        if (curr?.onlineStatus && curr?.machineUuid) acc[curr.machineUuid] = curr;
        return acc;
      }, {} as Record<MachineUuid, MachineOnlineStatusDto>),
    [onlineStatuses.data],
  );

  const onlineStatusesCounter = useMemo(() => {
    const result = { Online: 0, Offline: 0, CatchingUp: 0, Unknown: 0 };

    locationsWithMachines.forEach((l) => {
      l.machines.forEach((machine) => {
        const onlineStatus = onlineStatusesByMachineUuid[machine.uuid ?? ""]?.onlineStatus ?? "Unknown";
        result[onlineStatus] += 1;
      });
    });

    return result;
  }, [onlineStatusesByMachineUuid, locationsWithMachines]);

  const onlineStatusFromMachineUuid = useCallback(
    (machineUuid: Machine["uuid"]) =>
      // uuid was used insted of nodeId because useGetMachinesOnlineStatusQuery not provided nodeId to bind status with machine
      onlineStatusesByMachineUuid[machineUuid ?? ""],
    [onlineStatusesByMachineUuid],
  );

  const [filterSite, setFilterSite] = useState("");
  const [filterMachine, setFilterMachine] = useState("");
  const [mapZoom, setMapZoom] = useState<number | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLngExpression | null>(null);

  const filteredLocations = filterSite
    ? locationsWithMachines.filter((location) => location.name?.toLowerCase().includes(filterSite.toLowerCase()))
    : locationsWithMachines;

  useEffect(() => {
    if (locationsWithMachines.length == 0) return;
    const inputs: LatLngTuple[] = locationsWithMachines
      .flatMap((location) =>
        location.machines
          .filter((m) => onlineStatusFromMachineUuid(m.uuid))
          .map(() => getLatLngTupleFromLocation(location)),
      )
      .filter((v) => v != null && !isNaN(v[0]) && !isNaN(v[1])) as LatLngTuple[];

    const center: LatLngTuple = getCenterPoint(inputs);
    if (isNaN(center[0]) || isNaN(center[1])) return;
    const distance = Math.max(...inputs.map((value) => calculateDistance(center[0], center[1], value[0], value[1])));

    setMapCenter(center);
    // TODO: Measure many points corelation with zoom level to find optimal base and divider
    setMapZoom(logarithmNumber(8, distance));
  }, [locationsWithMachines, onlineStatusFromMachineUuid]);

  return (
    <div tw="flex flex-row w-full gap-2 bg-gray-1 border border-gray-3 rounded-md">
      <div tw="w-1/3 overflow-scroll h-full text-xs px-2">
        <div tw="flex gap-2 flex-col lg:flex-row">
          <div tw="w-1/2 min-w-max">
            <div tw="py-2 grid space-y-2 w-full">
              <OnlineStatusBadge value="Online" isSm>
                <span>: {onlineStatusesCounter.Online}</span>
              </OnlineStatusBadge>
              <OnlineStatusBadge value="Offline" isSm>
                : {onlineStatusesCounter.Offline}
              </OnlineStatusBadge>
              <OnlineStatusBadge value="CatchingUp" isSm>
                : {onlineStatusesCounter.CatchingUp}
              </OnlineStatusBadge>
              <OnlineStatusBadge value="Unknown" isSm>
                : {onlineStatusesCounter.Unknown}
              </OnlineStatusBadge>
            </div>
          </div>
          <div tw="w-1/2 min-w-max">
            <div tw="my-2 space-y-2">
              <InputRaw
                name="filterSites"
                placeholder="location.filterSites"
                type="text"
                variant="sm"
                value={filterSite}
                onChange={(e) => setFilterSite(e.target.value)}
              />
              <InputRaw
                name="filterMachines"
                placeholder="machine.filterMachines"
                type="text"
                variant="sm"
                value={filterMachine}
                onChange={(e) => setFilterMachine(e.target.value)}
              />
            </div>
          </div>
        </div>
        <FullLine />
        {filteredLocations.map((location) => {
          const position = getLatLngTupleFromLocation(location);
          const filteredMachines = filterMachine
            ? location.machines.filter((machine) => machine.name?.toLowerCase().includes(filterMachine.toLowerCase()))
            : location.machines;

          return filteredMachines.length ? (
            <details tw="my-2" key={location.nodeId} open>
              <summary>
                <span tw="cursor-pointer inline-flex items-center font-semibold">
                  <CaretDown tw="text-gray-8 transition-all duration-300 text-gray-8" weight="bold" />
                  <Text tw="ml-2" variant="labelAlt">
                    {location.name}
                  </Text>
                </span>
              </summary>
              <ul tw="border-b border-gray-3 pb-2">
                {filteredMachines.map((machine) => (
                  <li
                    tw="flex items-center py-1 cursor-pointer"
                    key={machine.nodeId as string}
                    onClick={() => {
                      position && onlineStatusFromMachineUuid(machine.uuid) && mapRef.current?.flyTo(position);
                    }}
                  >
                    <div tw="flex items-center truncate">
                      <OnlineStatusBadgeEmpty value={onlineStatusFromMachineUuid(machine.uuid)?.onlineStatus}>
                        <Broadcast size={16} weight="duotone" />
                      </OnlineStatusBadgeEmpty>
                      <span tw="ml-2">{machine.name}</span>
                      <FullLine />
                    </div>
                  </li>
                ))}
              </ul>
            </details>
          ) : null;
        })}
      </div>
      <div tw="w-2/3 h-full">
        <LeafletMap
          ready={locationsWithMachines.length > 0 && mapCenter != null && mapZoom != null}
          center={mapCenter ?? [0, 0]}
          zoom={mapZoom ?? 1}
          zoomSnap={0.75}
          ref={mapRef}
        >
          <MarkerClusterGroup maxClusterRadius={50} spiderfyOnMaxZoom showCoverageOnHover>
            {locationsWithMachines.flatMap((location) =>
              location.machines
                .filter((m) => onlineStatusFromMachineUuid(m.uuid))
                .map((machine) => (
                  <MapMarker.Point
                    key={machine.nodeId as string}
                    color={getOnlineStatusColor(onlineStatusFromMachineUuid(machine.uuid)?.onlineStatus)}
                    position={getLatLngTupleFromLocation(location) ?? [0, 0]}
                    popupContent={
                      <MapPopup
                        machine={machine}
                        onlineStatus={onlineStatusFromMachineUuid(machine.uuid)?.onlineStatus}
                      />
                    }
                  />
                )),
            )}
          </MarkerClusterGroup>
        </LeafletMap>
      </div>
    </div>
  );
};
