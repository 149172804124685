import administration from "administration/translations";
import alert from "alert/translations";
import auth from "auth/translations";
import base from "base/translations";
import common from "common/translations";
import location from "location/translations";
import machine from "machine/translations";
import machineUser from "machineUser/translations";
import operations from "operations/translations";
import report from "report/translations";
import tenant from "tenant/translations";
import transport from "transport/translations";
import user from "user/translations";
import zts from "zts/translations";

import en from "./en";

export const translations = {
  "en-US": {
    translation: {
      // TODO - in future we might sepearete en and en-US translations
      ...en,
      ...administration.en,
      ...auth.en,
      ...alert.en,
      ...base.en,
      ...common.en,
      ...location.en,
      ...machine.en,
      ...machineUser.en,
      ...operations.en,
      ...report.en,
      ...tenant.en,
      ...transport.en,
      ...user.en,
      ...zts.en,
    },
  },
} as const;
