import { TKeys } from "i18next";
import { useMemo } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getActiveOrInActiveStatusField } from "base/fields";
import { OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { getRemoteMgmtSection } from "common/remoteMgmt";
import { MachineGroupDtoIn } from "generated";

import { getAvailableMachinesField } from "./AvailableMachinesField";

export type MachineGroupFormData = Pick<MachineGroupDtoIn, "name" | "machineNodeIds" | "nodeStatus"> & {
  timeOption?: string;
  remoteMgmtOption?: string;
  templateNodeId?: string;
  softwarePackageNodeId?: string;
  scheduleInfo?: string;
};

const Wrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const getFields: (updateForm: boolean | undefined) => CustomRenderFields[] = () => [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "administration.mg.name",
        validate: { type: "string", required: true },
      },
      getAvailableMachinesField({ name: "machineNodeIds" }),
      getActiveOrInActiveStatusField({ name: "nodeStatus", defaultValue: "ACTIVE" }),
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<MachineGroupFormData>;
  submitLabel: TKeys;
  initial?: MachineGroupFormData;
  updateForm?: boolean;
};

export const MachineGroupForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm }) => {
  const fields = useMemo(() => getFields(updateForm).concat(getRemoteMgmtSection()), [updateForm]);

  return (
    <SchemaForm<MachineGroupFormData>
      fields={fields}
      initial={initial}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-between">
          <Button
            tw="mt-6"
            variant="side"
            onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "MACHINE_GROUPS" })}
            data-test="goBack"
          >
            <Text tKey="administration.mg.goBack" />
          </Button>
          <Button type="submit" tw="mt-6" data-test="submitForm">
            <Text tKey={submitLabel} />
          </Button>
        </div>
      )}
    />
  );
};
