import { addDays, startOfDay } from "date-fns";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { FieldData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  GetAllCommandsDocument,
  GetAllLogsDocument,
  usePostRebootCommandMutation,
  usePostRestartCommandMutation,
  usePostRetrieveLogsMutation,
} from "generated";

import { getLogTypesField } from "../logs/components/LogTypesField";

import { getCommandTypesField } from "./CommandTypesField";

// Max toDate for retrieve logs
const defaultTo = startOfDay(new Date());
// Max fromDate for retrieve logs
const defaultFrom = startOfDay(addDays(defaultTo, -1));
// Min fromDate for retrieve logs
const minFromDate = startOfDay(addDays(defaultTo, -15));
// Min toDate for retrieve logs
const minToDate = startOfDay(addDays(defaultTo, -14));

type CommandFormData = {
  command: string;
  machineNodeId: string;
  fromDate: Date | undefined | null;
  toDate: Date | undefined | null;
  logTypes: string[] | undefined | null;
  systemDump: boolean | undefined | null;
};

type Props = {
  onSubmit: OnSubmit<CommandFormData>;
};

const Wrapper = styled.div`
  ${tw`grid gap-2 mb-6 sm:[grid-template-columns:0.9fr_0.9fr]`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [getCommandTypesField({ name: "command", defaultValue: "RETRIEVE_LOGS" })],
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "condition",
        when: "command",
        is: (value) => value === "RETRIEVE_LOGS",
        fields: [
          {
            type: "date",
            name: "fromDate",
            label: "zts.commands.logs.from",
            defaultValue: defaultFrom,
            fromDate: minFromDate,
            toDate: defaultFrom,
            validate: {
              type: "object",
              custom: (value, formData) =>
                value < formData.values.toDate ? null : "zts.commands.logs.fromMustBeSmallerThanTo",
            },
          },
          {
            type: "date",
            name: "toDate",
            label: "zts.commands.logs.to",
            defaultValue: defaultTo,
            fromDate: minToDate,
            toDate: defaultTo,
            validate: {
              type: "object",
              custom: (value, formData) =>
                value > formData.values.fromDate ? null : "zts.commands.logs.toMustBeGreaterThanFrom",
            },
          },
          getLogTypesField({ name: "logTypes" }),
          {
            type: "checkbox",
            name: "systemDump",
            label: "zts.commands.logs.fullSystemDump",
          },
        ],
      },
      {
        type: "condition",
        when: "command",
        is: (value) => value === "RESTART",
        fields: [
          {
            type: "checkbox",
            name: "confirmRestart",
            label: "zts.commands.temporarilyOffline",
          },
        ],
      },
      {
        type: "condition",
        when: "command",
        is: (value) => value === "REBOOT",
        fields: [
          {
            type: "checkbox",
            name: "confirmReboot",
            label: "zts.commands.temporarilyOffline",
          },
        ],
      },
    ],
  },
];

const CommandForm: React.FC<Props> = ({ onSubmit }) => (
  <>
    <Text variant="heading" tw="pb-4 block mb-2" tKey="zts.commands.executeCommand" />
    <Text variant="paragraph" tw="pb-4 block mb-2" tKey="zts.commands.desc" />
    <SchemaForm<CommandFormData>
      onSubmit={onSubmit}
      fields={fields}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-end mt-6">
          <FieldData name="command" type="values">
            {(command) => (
              <FieldData name="confirmReboot" type="values">
                {(confirmReboot) => (
                  <FieldData name="confirmRestart" type="values">
                    {(confirmRestart) => (
                      <Button
                        type="submit"
                        data-test="submitForm"
                        disabled={
                          (command === "RESTART" && !confirmRestart) || (command === "REBOOT" && !confirmReboot)
                        }
                      >
                        <Text tKey="zts.commands.execute" />
                      </Button>
                    )}
                  </FieldData>
                )}
              </FieldData>
            )}
          </FieldData>
        </div>
      )}
    />
  </>
);

const getRetrieveLogsPayload = (mNodeId: string, formData: CommandFormData) => {
  return {
    machineNodeId: mNodeId,
    fromDate: formData.fromDate,
    toDate: formData.toDate,
    logTypes: formData.logTypes,
    systemDump: formData.systemDump,
  };
};

export const useCommandModal = (mNodeId: string) => {
  const [postRetrieveLogs] = usePostRetrieveLogsMutation({
    ignoreResults: true,
    update: (cache) => {
      evictQuery(GetAllCommandsDocument, cache);
      evictQuery(GetAllLogsDocument, cache);
    },
  });

  const [postRestartCommand] = usePostRestartCommandMutation({
    ignoreResults: true,
    update: (cache) => evictQuery(GetAllCommandsDocument, cache),
  });

  const [postRebootCommand] = usePostRebootCommandMutation({
    ignoreResults: true,
    update: (cache) => evictQuery(GetAllCommandsDocument, cache),
  });

  const modalData = useModalComponent({
    modalProps: {
      variant: ["modal", "md"],
    },
    Component: (
      <CommandForm
        onSubmit={(formData) => {
          if (formData.command === "RETRIEVE_LOGS") {
            postRetrieveLogs({
              variables: { logSearch: getRetrieveLogsPayload(mNodeId, formData) },
              onCompleted: () => modalData[0](),
            })
              .then(() => useToast.actions.show("zts.commands.logs.retrieveLogsSuccess", { variant: "success" }))
              .catch(() => useToast.actions.show("zts.commands.logs.retrieveLogsFailure", { variant: "error" }));
          } else if (formData.command === "RESTART") {
            postRestartCommand({
              variables: { machineNodeId: mNodeId },
              onCompleted: () => modalData[0](),
            })
              .then(() => useToast.actions.show("zts.commands.restartSuccess", { variant: "success" }))
              .catch(() => useToast.actions.show("zts.commands.restartFailure", { variant: "error" }));
          } else if (formData.command === "REBOOT") {
            postRebootCommand({
              variables: { machineNodeId: mNodeId },
              onCompleted: () => modalData[0](),
            })
              .then(() => useToast.actions.show("zts.commands.rebootSuccess", { variant: "success" }))
              .catch(() => useToast.actions.show("zts.commands.rebootFailure", { variant: "error" }));
          }
        }}
      />
    ),
  });

  return modalData;
};
