import { Check, Minus } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { Button, Container, FullLine, Text } from "common/guideline";
import { InputMaybe } from "generated";

type Props = {
  data: any;
  onClick?: HTMLElement["click"] | undefined;
};

const isIncluded = (parts: InputMaybe<InputMaybe<string>[]> | undefined, part: InputMaybe<string>) =>
  parts !== undefined && parts !== null ? parts.includes(part) : false;

const getInclusionIconFor = (included: boolean) => {
  return included === true ? (
    <span>
      <Check size={18} weight="bold" tw="mr-20 float-right text-success-default" />
    </span>
  ) : (
    <span>
      <Minus size={18} weight="regular" tw="mr-20 float-right" />
    </span>
  );
};

export const ConfigTemplateParts: React.FC<Props> = ({ data, onClick }) => (
  <>
    <FullLine />
    <Container variant="card" tw="text-sm my-4">
      <Text variant="heading" tw="block mb-2" tKey="administration.rc.info" />
      <div tw="grid grid-cols-[max-content 1fr] [grid-gap:12px] ">
        <FullLine />
        <Text tKey="administration.rc.version" />
        <span tw="ml-6">{data?.version}</span>
        <FullLine />
        <Text tKey="administration.rc.configurationFile" />
        <div tw="flex justify-between truncate">
          <span tw="truncate ml-6">{data?.packageName}</span>
          {onClick && (
            <Button onClick={onClick} tw="ml-2" variant={["sm", "side"]} data-test="changeFile">
              <Text tKey="administration.rc.changeFile" />
            </Button>
          )}
        </div>
      </div>
    </Container>
    <FullLine />
    <Container variant="card" tw="[font-size:small]">
      <Text variant="heading" tw="block mb-2" tKey="administration.rc.included" />
      <div tw="grid grid-cols-[max-content 1fr] [grid-gap:8px]">
        <FullLine />
        <Text tKey="administration.rc.configuration" />
        {getInclusionIconFor(isIncluded(data?.parts, "configuration"))}
        <FullLine />
        <Text tKey="administration.rc.receipts" />
        {getInclusionIconFor(isIncluded(data?.parts, "receipts"))}
        <FullLine />
        <Text tKey="administration.rc.i18n" />
        {getInclusionIconFor(isIncluded(data?.parts, "i18n"))}
        <FullLine />
        <Text tKey="administration.rc.themes" />
        {getInclusionIconFor(isIncluded(data?.parts, "themes"))}
        <FullLine />
        <Text tKey="administration.rc.sdk" />
        {getInclusionIconFor(isIncluded(data?.parts, "sdk"))}
        <FullLine />
        <Text tKey="administration.rc.scripts" />
        {getInclusionIconFor(isIncluded(data?.parts, "scripts"))}
        <FullLine />
        <Text tKey="administration.rc.help" />
        {getInclusionIconFor(isIncluded(data?.parts, "help"))}
      </div>
    </Container>
    <FullLine />
  </>
);
