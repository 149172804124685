export const en = {
  transport: {
    title: "Transport",
    overview: "Transport overview",
    desc: "Transport is a platform for managing and monitoring transport services.",
    corReasons: {
      title_other: "Correction reasons",
      title_one: "Correction reason",
      desc: "View, manage, and create correction reasons",
      id: "Correction reason ID",
      name: "Name",
      active: "Active",
      lastEdited: "Last edited",
      lastEditedBy: "Last edited by",
      create: "Create correction",
      remove: {
        title: "Remove correction reason",
        success: "Correction reason removed",
        error: "Failed to remove correction reason, please try again",
        desc: "Correction <0>{{name}}</0> will be removed permanently.",
        close: "Discard",
        submit: "Remove",
      },
      form: {
        name: "Name",
        active: "Activate",
        create: "Create correction reason",
        createSubmit: "Create",
        update: "Update correction reason",
        updateSubmit: "Update",
      },
      createSuccess: "Correction reason created successfully",
      createError: "Correction reason creation failed",
      updateSuccess: "Correction reason updated successfully",
      updateError: "Correction reason update failed",
    },
    rBalance: {
      title: "Rolling balance",
      desc: "Report visualizing a rolling balance for drivers",
      date: "Date",
      currencyCode: "Currency",
      balance: "Balance",
      machineUser: "Machine user",
    },
    dashboard: {
      pickMachine: "Select a machine to see transport summary",
    },
    transactionType: {
      all: "All transactions",
      correction: "Correction transactions",
      machine: "Machine transactions",
    },
  },
  tranCorr: {
    title: "Correction transactions",
    desc: "Use the Correction transactions view to view correction transactions and to modify the values of existing transactions in order to correct reporting.",
    datePeriod: "Correction period",
    machine: "Machine",
    sequence: "Sequence",
    type: "Type",
    time: "Time",
    date: "Date",
    utcOffset: "UTC offset",
    machineUser: "Machine user",
    corrReason: "Correction reason",
    comment: "Comment",
    createdBy: "Created by",
    value: "Value",
    currency: "Currency",
    create: "Create correction",
    submit: "Submit",
    offsetPatternErr: "Must be of pattern -xx.00 or +xx.00",
    createSuccess: "Correction transaction created successfully",
    createError: "Correction transaction creation failed",
  },
  tranTypes: {
    DISPENSE: "Dispense",
    DEPOSIT: "Deposit",
    EMPTY_OUT: "Empty out",
    END_OF_SHIFT: "End of shift",
    MANUAL_REFILL: "Manual refill",
    EXCHANGE: "Exchange",
    PURGE: "Purge",
    POSSIBLE_TAMPERING: "Possible tampering",
    UNFINISHED_TRANSACTION: "Unfinished transaction",
    REFILL: "Refill",
  },
};
