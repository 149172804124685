import { useEffect } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { SelectRaw } from "common/form/renderFields";
import { makeSorter } from "common/helpers";
import { useFindMyTenantsMetadataQuery } from "generated";

import { useTenant } from "../context";

export const TenantSelect = () => {
  const tenantId = useTenant(useTenant.actions.getTenantId);

  // on initial render - fetch tenant data for storage tenantId
  useEffect(() => {
    if (tenantId) {
      useTenant.actions.setTenantId(tenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, loading } = useFindMyTenantsMetadataQuery({
    onCompleted: (d) => {
      if (!tenantId || !d.findMyTenantsMetadata?.some((t) => t?.tenantId == tenantId)) {
        useTenant.actions.setTenantId(d.findMyTenantsMetadata?.[0]?.tenantId);
      }
    },
  });

  const tenants = (data?.findMyTenantsMetadata || [])
    .map((t) => ({
      value: t?.tenantId,
      label: t?.tenantName,
    }))
    .sort(makeSorter("label"));

  return (
    <SelectRaw
      tw="w-24 sm:w-44 print:hidden"
      name="tenant"
      placeholder="tenant.tenant_one"
      value={tenantId || ""}
      onChange={useTenant.actions.setTenantId}
      options={tenants}
      isLoading={loading}
      variant={["sm", "dropdown"]}
    />
  );
};
