import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const CashierManagement = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="cashierManagementOverview"
    subtitle="report.cashierManagementDescription"
  />
);
