import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { EntityDelete } from "base/components";
import { useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindListOfUsersDocument, useDeleteUserMutation } from "generated";
import { Trans } from "i18n";

import { IsUserData } from "../UsersTable/types";

type UserData = WithoutNullableKeys<Pick<IsUserData, "userName">>;

type Props = {
  userName: string;
  onClose?: () => void;
};

export const DeleteUser: React.FC<Props> = ({ userName, onClose }) => {
  const [deleteUser, { loading }] = useDeleteUserMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "user.deleteError" : "user.deleteSuccess", {
        variant: error ? "error" : "success",
      });

    try {
      const result = await deleteUser({
        variables: { userName },
        ignoreResults: true,
        update: (cache) => evictQuery(FindListOfUsersDocument, cache),
      });
      if (result.data?.deleteUser) {
        onClose?.();
      }
      showToast(!!result.data?.deleteUser);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="user.deleteUser" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="user.deleteUserDesc" values={{ name }}>
        {[<strong key="1" />]}
      </Trans>
    </EntityDelete>
  );
};

export const useDeleteUserModal = ({ userName }: UserData) =>
  useModalComponent({
    Component: <DeleteUser userName={userName} />,
  });
