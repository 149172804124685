import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";
import { CreateMachineUserGroup } from "./MachineUserGroupFrom";
import { MachineUserGroupsTable } from "./MachineUserGroupsTable";

export const MachineUserGroups = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="machineUserGroup.title_other"
    subtitle="machineUserGroup.desc"
    actions={<CreateMachineUserGroup />}
  >
    <MachineUserGroupsTable />
  </PageLayout>
);
