import { Download } from "phosphor-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { downloadSoftwarePackageFile } from "administration/helpers";
import { ReactComponent as InfoSVG } from "assets/icons/Info.svg";
import { ReactComponent as SoftwarePackageSVG } from "assets/icons/SoftwarePackage.svg";
import { PageLayout } from "base/components";
import { Badge, Button, CommonCells, Popover, Table, TableTypes, accessors, usePagination } from "common/guideline";
import { withDefault } from "common/helpers";
import { FindAllSoftwarePackagesQuery, useFindAllSoftwarePackagesQuery } from "generated";

import { breadcrumbs } from "./breadcrumbs";
import { RowActions } from "./RowActions";
import { CreateSoftwarePackage } from "./SoftwarePackagesForm";

type SoftwarePackage = NonNullable<NonNullable<FindAllSoftwarePackagesQuery["findAllSoftwarePackages"]>["0"]>;

const getColumns: TableTypes.TranslatedColumns<SoftwarePackage> = (t) => [
  {
    header: t("administration.sp.name"),
    accessorKey: "name",
  },
  {
    header: t("administration.sp.version"),
    accessorKey: "version",
    cell({ getValue }) {
      return withDefault(getValue() ? <Badge>{getValue<string>()}</Badge> : undefined);
    },
  },
  {
    header: t("administration.sp.revision"),
    accessorKey: "revision",
  },
  {
    header: t("administration.sp.file"),
    accessorKey: "fileName",
    minSize: 270,
    cell({ getValue, row }) {
      return (
        <div>
          <span tw="inline-flex items-start">
            {getValue<string>()}
            {"    "}
            <Button variant={["primary", "sm"]} tw="ml-6" data-test={`downloadFile-${row.index}`}>
              <Download
                size={18}
                weight="duotone"
                onClick={() => row.original.nodeId && downloadSoftwarePackageFile(row.original.nodeId)}
              />
            </Button>
          </span>
        </div>
      );
    },
  },
  {
    header: t("administration.sp.release"),
    accessorFn: (v) => t(v.common === true ? "administration.sp.official" : "administration.sp.custom"),
  },
  {
    header: t("administration.sp.createdBy"),
    accessorFn: (v) => withDefault(v.createdBy),
  },
  {
    header: t("administration.sp.createdDate"),
    id: "createdDate",
    accessorFn: (v) => accessors.date(v.createdDate, t),
    sortingFn: "dateString",
  },
  {
    header: t("administration.sp.description"),
    accessorKey: "description",
  },
  {
    header: t("administration.sp.releaseInfo"),
    enableSorting: false,
    cell({ row }) {
      const { releaseInfo } = row.original;

      return releaseInfo ? (
        <Popover
          auto
          overflowContainer
          placement="left-start"
          possiblePlacements={["left-center", "left-end", "left-start"]}
          content={() => <pre tw="max-w-[70vw] overflow-auto text-xs p-2">{releaseInfo}</pre>}
        >
          <InfoSVG width={24} height={24} />
        </Popover>
      ) : (
        "-"
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const SoftwarePackagesTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(10);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllSoftwarePackagesQuery({
    variables: {
      cpPageRequest: {
        page: pageIndex,
        size: pageSize,
      },
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const values = data?.findAllSoftwarePackages || [];

  return (
    <Table<SoftwarePackage>
      tableName="softwarePackages"
      columns={columns}
      data={values as SoftwarePackage[]}
      loading={loading}
      onPagination={setPagination}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};

export const SoftwarePackages = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.sp.title"
    subtitle="administration.sp.desc"
    Icon={SoftwarePackageSVG}
    actions={<CreateSoftwarePackage />}
  >
    <SoftwarePackagesTable />
  </PageLayout>
);
