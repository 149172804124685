import { AdministrationPaths } from "administration/paths";
import { AlertPaths } from "alert/paths";
import { AuthPaths } from "auth/paths";
import { BasePaths } from "base/paths";
import { LocationPaths } from "location/paths";
import { MachinePaths } from "machine/paths";
import { MachineUserPaths } from "machineUser/paths";
import { OperationsPaths } from "operations/paths";
import { PersonalizedPaths } from "personalized/paths";
import { ReportPaths } from "report/paths";
import { createRouting } from "routing";
import { TenantPaths } from "tenant/paths";
import { TransportPaths } from "transport/paths";
import { UserPaths } from "user/paths";

export type Paths = AdministrationPaths &
  AlertPaths &
  AuthPaths &
  BasePaths &
  OperationsPaths &
  TenantPaths &
  LocationPaths &
  MachinePaths &
  MachineUserPaths &
  UserPaths &
  PersonalizedPaths &
  ReportPaths &
  TransportPaths;

export {
  useQueryParamsSelector,
  history,
  useQueryParams,
  useNavigatonBlocker,
  useQueryParamsGetByPath,
  HistoryRouter,
} from "routing";

export type { LinkProps, NavigateArgs, NavigateProps, RouteConfig, RouteValue, UseNavigateToArgs } from "routing";
export { Outlet, useLocation, useParams } from "react-router-dom";

export const {
  navigateTo,
  Navigate,
  getMatchRoutes,
  Link,
  NavLink,
  getPath,
  getPathGlobal,
  isRoute,
  useIsRoute,
  useNavigateTo,
  getRoutingComponent,
} = createRouting<Paths>();
