import { Row, flexRender } from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import { Fragment, useRef } from "react";

import { getCellStyle } from "../helpers";
import { TableProps } from "../types";

const VirtualRow: React.FC<{ height: number }> = ({ height }) =>
  height > 0 ? (
    <div className="tr">
      <div className="td" style={{ height }} />
    </div>
  ) : null;

export const TableBody: React.FC<{ SubRows: TableProps<any>["SubRows"]; rows: Row<any>[] }> = ({ SubRows, rows }) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { virtualItems, totalSize } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });

  const paddingTop = virtualItems?.[0]?.start;
  const paddingBottom = virtualItems.length > 0 ? totalSize - (virtualItems?.[virtualItems.length - 1]?.end || 0) : 0;

  return (
    <div className="tBody" ref={tableContainerRef}>
      <VirtualRow height={paddingTop} />

      {virtualItems.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const isExpaned = row.getIsExpanded();

        return (
          <Fragment key={row.id}>
            <div className={`tr ${virtualRow.index % 2 === 0 ? "odd" : "even"} ${isExpaned ? "expanded" : ""}`}>
              {row.getVisibleCells().map((cell) => (
                <div className="td" key={cell.id} style={getCellStyle(cell.column)}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
            </div>

            {isExpaned && SubRows && <SubRows row={row} />}
          </Fragment>
        );
      })}

      <VirtualRow height={paddingBottom} />
    </div>
  );
};
