import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const CashierTransactions = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="cashierTransactions" subtitle="report.cashierTransactionsDescription" />
);
