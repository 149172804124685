import { addYears } from "date-fns";
import { TKeys } from "i18next";
import { useMemo, useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getFormTitle } from "base/fields";
import { getActiveStatuses } from "base/fields/activeStatus";
import { OnSubmit, SchemaForm, UseLiveConfig, useFieldData } from "common/form";
import { CustomRenderFieldTypes, CustomRenderFields, TextLabel, customRender } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { MachineUserDtoIn } from "generated";
import { getMachineUserRoleField } from "machineUser/fields";

export type MachineUserFormData = Pick<
  MachineUserDtoIn,
  | "name"
  | "roleName"
  | "userId"
  | "deactivated"
  | "noPin"
  | "pin"
  | "noPinCard"
  | "cardIdentifier"
  | "noPinBio"
  | "noPinDoor"
  | "noPinLogin"
  | "doors"
  | "changePinOnLogin"
  | "validFrom"
  | "validUntil"
  | "nbrOfLogins"
> & { nodeId: string; confirmPin?: string; unlimitedNoLogins?: boolean };

const validFromDefault = new Date();
const validUntilDefault = addYears(validFromDefault, 2);

const Wrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-2`}
`;

const OptionsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div tw="flex flex-col justify-center">
    <TextLabel tKey="mu.loginWith" />
    <div tw="grid gap-1 sm:grid-cols-4">{children}</div>
  </div>
);

const getCustomConfig = (field: string): UseLiveConfig<CustomRenderFieldTypes["input"]> =>
  function useCustomConfig(prev) {
    const customConfig = useFieldData(field, "values");
    return [useMemo(() => ({ ...prev, disabled: !!customConfig }), [prev, customConfig]), true];
  };

const getFields: (updateForm: boolean | undefined) => CustomRenderFields[] = (updateForm) => [
  {
    type: "container",
    Component: getFormTitle("mu.userSettings"),
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "mu.name",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "userId",
        label: "mu.userId",
        validate: { type: "string", required: true },
        disabled: updateForm,
      },
      getMachineUserRoleField({ name: "roleName", label: "mu.role", defaultValue: false }),
      getActiveStatuses({ name: "deactivated", label: "mu.status", defaultValue: false }),
      {
        type: "live",
        fieldConfig: {
          type: "text",
          name: "cardIdentifier",
          label: "mu.cardLogin",
          validate: { type: "string" },
        },
        name: "cardIdentifier",
        useConfig: getCustomConfig("noPinCard") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "noPinCard",
        label: "mu.noCardLogin",
      },
      {
        type: "live",
        fieldConfig: {
          type: "text",
          name: "doors",
          label: "mu.doorLogin",
          validate: { type: "string" },
        },
        name: "doors",
        useConfig: getCustomConfig("noPinDoor") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "noPinDoor",
        label: "mu.noDoorLogin",
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("mu.loginSettings"),
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "live",
        fieldConfig: {
          type: "password",
          name: "pin",
          label: "user.password",
          validate: {
            type: "string",
          },
        },
        name: "pin",
        useConfig: getCustomConfig("noPin") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "noPin",
        label: "mu.noPasswordRequired",
      },
      {
        type: "live",
        fieldConfig: {
          type: "password",
          name: "confirmPin",
          label: "mu.confirmPassword",
          validate: {
            type: "string",
            custom: (value, formData) =>
              value === formData.values.pin || formData.values.noPin === true ? null : "mu.passwordsDoNotMatch",
          },
        },
        name: "confirmPin",
        useConfig: getCustomConfig("noPin") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "condition",
        when: "noPin",
        is: Boolean,
        fields: [
          {
            type: "container",
            Component: OptionsWrapper,
            fields: [
              {
                type: "checkbox",
                name: "noPinLogin",
                label: "mu.noPinLogin",
              },
              {
                type: "checkbox",
                name: "noPinCard",
                label: "mu.noPinCard",
              },
              {
                type: "checkbox",
                name: "noPinBio",
                label: "mu.noPinBio",
              },
              {
                type: "checkbox",
                name: "noPinDoor",
                label: "mu.noPinDoor",
              },
            ],
          },
        ],
      },
      {
        type: "condition",
        when: "noPin",
        is: (value) => !value,
        fields: [
          {
            type: "checkbox",
            name: "changePinOnLogin",
            label: "mu.changePinOnFirstLogin",
          },
        ],
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("mu.accountSettings"),
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "date",
        name: "validFrom",
        label: "mu.validFrom",
        defaultValue: validFromDefault,
        validate: {
          type: "object",
          custom: (value, formData) => (value < formData.values.validUntil ? null : "mu.incorrectTimespan"),
        },
      },
      {
        type: "date",
        name: "validUntil",
        label: "mu.validUntil",
        defaultValue: validUntilDefault,
        validate: {
          type: "object",
          custom: (value, formData) => (value > formData.values.validFrom ? null : "mu.incorrectTimespan"),
        },
      },
      {
        type: "live",
        fieldConfig: {
          type: "number",
          name: "nbrOfLogins",
          label: "mu.noAllowedLogins",
          defaultValue: 3,
        },
        name: "nbrOfLogins",
        useConfig: getCustomConfig("unlimitedNoLogins") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        calculation: {
          updates: {
            nbrOfLogins: (noLogins) => (noLogins === true ? undefined : 3),
          },
        },
        name: "unlimitedNoLogins",
        label: "mu.unlimited",
      },
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<MachineUserFormData>;
  submitLabel: TKeys;
  initial?: MachineUserFormData;
  updateForm?: boolean;
};

export const MachineUserForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm }) => {
  const [fields] = useState(() => getFields(updateForm));

  return (
    <SchemaForm<MachineUserFormData>
      fields={fields}
      initial={initial}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-between">
          <Button
            tw="mt-6"
            variant="side"
            onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "MACHINE_USERS" })}
            data-test="goBack"
          >
            <Text tKey="mu.goBack" />
          </Button>
          <Button type="submit" tw="mt-6" data-test="submitForm">
            <Text tKey={submitLabel} />
          </Button>
        </div>
      )}
    />
  );
};
