import tw from "twin.macro";
import "styled-components/macro";

import { LeafletMap, MapMarker } from "common/guideline";
import { useFindAllLocationsQuery } from "generated";

export const LocationsMap = () => {
  const { data, loading } = useFindAllLocationsQuery();
  const locations = data?.findAllLocations || [];

  const firstLocationCords =
    locations[0]?.latitude && locations[0]?.longitude
      ? ([Number(locations[0].latitude), Number(locations[0].longitude)] as [number, number])
      : undefined;

  return (
    <div tw="h-80">
      <LeafletMap center={firstLocationCords} ready={!loading}>
        {locations.map((location) =>
          location?.latitude && location.longitude ? (
            <MapMarker.Point
              key={location.name as string}
              position={[Number(location.latitude), Number(location.longitude)]}
              popupContent={location.name}
            />
          ) : null,
        )}
      </LeafletMap>
    </div>
  );
};
