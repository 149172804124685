import { CellContext } from "@tanstack/react-table";
import { MinusCircle, PlusCircle } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

export const ExpandRowCell = ({ row }: CellContext<any, any>) =>
  row.getCanExpand() ? (
    <span
      onClick={row.getToggleExpandedHandler()}
      tw="flex items-center space-x-1 cursor-pointer"
      title={`expand ${row.subRows.length || 0} rows`}
    >
      {row.getIsExpanded() ? (
        <MinusCircle size={24} weight="duotone" tw="text-gray-6" />
      ) : (
        <PlusCircle size={24} weight="duotone" tw="text-success-default" />
      )}
    </span>
  ) : null;
