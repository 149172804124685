import tw from "twin.macro";
import "styled-components/macro";

import { deleteSoftwarePackageFile } from "administration/helpers";
import { EntityDelete } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllSoftwarePackagesDocument, useDeleteSoftwarePackageMutation } from "generated";
import { Trans } from "i18n";

type Props = {
  nodeId: string;
  name: string;
  onClose?: () => void;
};

export const DeleteSoftwarePackage: React.FC<Props> = ({ nodeId, name, onClose }) => {
  const [deleteSoftwarePackage, { loading }] = useDeleteSoftwarePackageMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "administration.sp.deleteError" : "administration.sp.deleteSuccess", {
        variant: error ? "error" : "success",
      });
    try {
      const result = await deleteSoftwarePackage({
        variables: {
          nodeId,
        },
        ignoreResults: true,
        update: (cache) => evictQuery(FindAllSoftwarePackagesDocument, cache),
      });
      if (result.data?.deleteSoftwarePackage) onClose?.();
      deleteSoftwarePackageFile(nodeId);
      showToast(!result.data?.deleteSoftwarePackage);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="administration.sp.delete" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="administration.sp.deleteDescription" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};
