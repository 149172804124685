import { CaretRight } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { TableTypes, Text, useModal } from "common/guideline";
import { withDefault } from "common/helpers";

import { LocationData } from "./types";

const LocationDetailsModal: React.FC<TableTypes.VerticalHeaderProps<LocationData>> = ({ row }) => (
  <div>
    <div tw="flex justify-between pr-6 mb-2">{row.original.name}</div>
    <div tw="grid text-xs [grid-template-columns:max-content_1fr] [grid-gap:12px] justify-items-start">
      <Text tKey="location.name" />
      <span>{row.original.name}</span>
      <Text tKey="location.longitude" />
      <span>{row.original.longitude}</span>
      <Text tKey="location.latitude" />
      <span>{row.original.latitude}</span>
      <Text tKey="location.nodeChangeDate" />
      <span>
        {withDefault(row.original.nodeChangeDate ? new Date(row.original.nodeChangeDate).toLocaleString() : undefined)}
      </span>
      <Text tKey="location.siteId" />
      <span>{withDefault(row.original.nodeId)}</span>
    </div>
  </div>
);

const openLocationDetailModal = (row: TableTypes.Row<LocationData>) =>
  useModal.actions.open({ Component: <LocationDetailsModal row={row} />, closeOnRouteChange: true });

export const LocationVerticalHeader: React.FC<TableTypes.VerticalHeaderProps<LocationData>> = (props) => (
  <div tw="flex justify-between pb-4 items-center">
    <div tw="flex">
      <span>{props.row.original.name}</span>
      <CaretRight size={24} weight="duotone" tw="text-gray-6" onClick={() => openLocationDetailModal(props.row)} />
    </div>
  </div>
);
