import { Box, BoxContent, BoxHeading } from "components";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { ReactComponent as ConfigTemplateSVG } from "assets/icons/ConfigTemplate.svg";
import { ReactComponent as GearSVG } from "assets/icons/Gear.svg";
import { ReactComponent as MachineGroupSVG } from "assets/icons/MachineGroup.svg";
import { ReactComponent as MachineUserSVG } from "assets/icons/MachineUser.svg";
import { ReactComponent as MapPinSVG } from "assets/icons/MapPin.svg";
import { ReactComponent as SiteGroupSVG } from "assets/icons/SiteGroup.svg";
import { ReactComponent as SoftwarePackageSVG } from "assets/icons/SoftwarePackage.svg";
import { ReactComponent as SquaresFourSVG } from "assets/icons/SquaresFour.svg";
import { ReactComponent as UsersSVG } from "assets/icons/Users.svg";
import { PageLayout } from "base/components";
import { Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";

export const Administration = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.title"
    subtitle="administration.description"
    Icon={GearSVG}
  >
    <div tw="gap-4 md:grid-cols-2 lg:grid-cols-3 grid">
      <Link route="TENANT_LIST">
        <Box>
          <BoxHeading>
            <Text tKey="tenant.list.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <SquaresFourSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="LOCATIONS">
        <Box>
          <BoxHeading>
            <Text tKey="administration.locations" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MapPinSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="LOCATION_GROUPS">
        <Box>
          <BoxHeading>
            <Text tKey="administration.lg.title_other" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <SiteGroupSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MACHINE_LIST">
        <Box>
          <BoxHeading>
            <Text tKey="administration.machines" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <ComputerTowerSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MACHINE_GROUPS">
        <Box>
          <BoxHeading>
            <Text tKey="administration.mg.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineGroupSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MACHINE_USERS">
        <Box>
          <BoxHeading>
            <Text tKey="mu.title_other" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineUserSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="USERS">
        <Box>
          <BoxHeading>
            <Text tKey="user.title_other" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <UsersSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="SOFTWARE_PACKAGES">
        <Box>
          <BoxHeading>
            <Text tKey="administration.sp.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <SoftwarePackageSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="CONFIG_TEMPLATES">
        <Box>
          <BoxHeading>
            <Text tKey="administration.configTemplates" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <ConfigTemplateSVG />
          </BoxContent>
        </Box>
      </Link>
    </div>
  </PageLayout>
);
