import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CorrectionTransactionSVG } from "assets/icons/CorrectionTransaction.svg";
import { NamesByIds, PageLayout, useMappedSiteGroupContext } from "base/components";
import { Table, TableTypes, accessors, useDateRangeFilter, usePagination } from "common/guideline";
import { withDefault } from "common/helpers";
import {
  GenerateTransactionsReportQuery,
  TransactionsReportOrderColumn,
  useGenerateTransactionsReportQuery,
} from "generated";
import { FilterBox, getColumnFilter, useColumnFilters } from "report/components";
import { getTotalAmountsPairRows } from "report/helpers";
import { SortByColumnsData, useSorting } from "report/hooks";

import { TransactionType } from "../../components";

import { breadcrumbs } from "./breadcrumbs";
import { CreateCorrectionReasonModalForm } from "./CorrectionTransactionForm";

type TransactionRows = NonNullable<
  NonNullable<NonNullable<GenerateTransactionsReportQuery["generateTransactionsReport"]>["rows"]>[0]
> & { __typename: "CorrectionTransactionReportRowDto" };

type Filters = NonNullable<GenerateTransactionsReportQuery["generateTransactionsReport"]>["columns"];

const getColumns: (
  filters: Filters,
) => TableTypes.TranslatedColumns<TransactionRows, SortByColumnsData<TransactionsReportOrderColumn>> =
  (filters) => (t) =>
    [
      {
        header: t("tranCorr.machine"),
        accessorKey: "machineNodeId",
        id: "MACHINE",
        cell({ getValue }) {
          return <NamesByIds entity="machines" ids={getValue<string[]>() || ""} />;
        },
      },
      {
        header: t("tranCorr.sequence"),
        accessorKey: "sequence",
        enableSorting: false,
      },
      {
        header: t("tranCorr.time"),
        accessorFn: (v) =>
          withDefault(
            v.dateTime ? `${accessors.date(v.dateTime, t)} (UTC ${withDefault(v.timeZoneUtcOffset)})` : undefined,
          ),
        id: "DATE",
      },
      // TODO - wait for API to return machineUserId
      // {
      //   header: t("cashier id"),
      //   accessorFn: () => "-",
      //   enableSorting: false,
      // },
      {
        header: t("tranReport.machineUser"),
        id: "MACHINE_USER",
        accessorKey: "machineUserName",
        enableSorting: false,
        meta: {
          filter: getColumnFilter(filters, "machineUserName"),
        },
      },
      {
        header: t("tranCorr.corrReason"),
        accessorKey: "correctionReason",
        enableSorting: false,
      },
      {
        header: t("tranCorr.comment"),
        accessorFn: (v) => withDefault(v.comment),
        enableSorting: false,
      },
      {
        header: t("tranCorr.createdBy"),
        accessorKey: "createdBy",
        enableSorting: false,
      },
      {
        header: t("tranCorr.type"),
        accessorKey: "type",
        id: "TYPE",
        cell: TransactionType,
        meta: {
          filter: getColumnFilter(filters, "type"),
        },
      },
    ];

const columnFiltersData = [
  ["transactionType", "TYPE"],
  ["machineUserNames", "MACHINE_USER"],
] as const;

const TransactionsTable = () => {
  const { t, i18n } = useTranslation();
  const [{ pageIndex, pageSize }, setPagination] = usePagination();
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<TransactionsReportOrderColumn>();
  const [dateFilter, DateRange] = useDateRangeFilter("30days", { label: "tranCorr.datePeriod" });
  const [filter, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const [{ location, machine }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateTransactionsReportQuery({
    variables: {
      input: {
        messageType: "CORRECTION_TRANSACTIONS",
        limit: pageSize,
        skip: pageIndex * pageSize,
        order,
        orderColumn,
        fromDate: dateFilter.from,
        toDate: dateFilter.to,
        locationNodeIds: location,
        machineNodeIds: machine,
        ...filter,
      },
    },
  });

  const rows = data?.generateTransactionsReport?.rows as TransactionRows[] | undefined;
  const filters = data?.generateTransactionsReport?.columns;

  const columns = useMemo<TableTypes.ColumnDef<TransactionRows>[]>(
    () => (!rows ? [] : [...getColumns(filters)(t, i18n.language), ...getTotalAmountsPairRows(rows, t)]),
    [t, i18n.language, rows, filters],
  );

  return (
    <>
      <FilterBox>{DateRange}</FilterBox>
      <Table<TransactionRows>
        tableName="correctionTransactions"
        columns={columns}
        data={rows || []}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagination={setPagination}
        sorting={sorting}
        onSorting={setSorting}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        totalCount={data?.generateTransactionsReport?.fullSize || 0}
      />
    </>
  );
};

export const CorrectionTransactions = () => (
  <PageLayout
    title="tranCorr.title"
    subtitle="tranCorr.desc"
    breadcrumbs={breadcrumbs}
    Icon={CorrectionTransactionSVG}
    actions={<CreateCorrectionReasonModalForm />}
  >
    <TransactionsTable />
  </PageLayout>
);
