import { RouteConfig, RouteValue } from "routing";

import { MachineCreate, MachineEdit, MachineList, MachineUpdateParams } from "./pages";

export type MachinePaths = {
  MACHINE_LIST: RouteValue;
  MACHINE_CREATE: RouteValue;
  MACHINE_EDIT: RouteValue<{ params: MachineUpdateParams }>;
};

export const MACHINE: RouteConfig<MachinePaths> = {
  MACHINE_LIST: {
    path: "/machine-list",
    Component: MachineList,
  },
  MACHINE_CREATE: {
    path: "/machine-create",
    Component: MachineCreate,
  },
  MACHINE_EDIT: {
    path: "/machine-edit/:nodeId",
    Component: MachineEdit,
  },
};
