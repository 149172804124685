import { useMemo } from "react";

import { GetReportHeaderQuery, useGetReportHeaderQuery } from "generated";

type Props = {
  ids: string | string[];
  entity: keyof Omit<NonNullable<GetReportHeaderQuery["getReportHeader"]>, "__typename">;
  children?: (names: string[], loading: boolean) => JSX.Element;
};

const getNames = (res: GetReportHeaderQuery | undefined, entity: Props["entity"], ids: Props["ids"]) => {
  const data = (res?.getReportHeader?.[entity] || []) as { nodeId: string; name: string }[];
  const idsArray = Array.isArray(ids) ? ids : [ids];

  return idsArray.flatMap((id) => data.find((d) => d.nodeId === id)?.name || []);
};

export const NamesByIds = ({ entity, ids, children }: Props) => {
  const { data, loading } = useGetReportHeaderQuery({ skip: !ids });
  const result = useMemo(() => getNames(data, entity, ids), [data, entity, ids]) || [];

  return children ? children(result, loading) : <>{result.join(", ")}</>;
};
