import { RouteConfig, RouteValue } from "routing";

import { CreateTenantScreen, TenantList, TenantUpdateParams, UpdateTenantScreen } from "./pages";

export type TenantPaths = {
  TENANT_LIST: RouteValue;
  TENANT_CREATE: RouteValue;
  TENANT_UPDATE: RouteValue<{ params: TenantUpdateParams }>;
};

export const TENANT: RouteConfig<TenantPaths> = {
  TENANT_LIST: {
    path: "/administration/tenants",
    Component: TenantList,
  },
  TENANT_CREATE: {
    path: "/administration/tenants/create",
    Component: CreateTenantScreen,
  },
  TENANT_UPDATE: {
    path: "/administration/tenants/update/:tenantId",
    Component: UpdateTenantScreen,
  },
};
