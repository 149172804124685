import { TKeys } from "i18next";
import { useMemo } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getFormTitle } from "base/fields";
import { FormData, OnSubmit, SchemaForm, UseLiveConfig, useFieldData } from "common/form";
import { CustomRenderFieldTypes, CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text } from "common/guideline";

import { getDomainNamesField, getUserPermissionsField, getUserRolesField } from "../fields";
import { UserUpdateFormData } from "../types";

type UpdateProps = {
  onSubmit: OnSubmit<UserUpdateFormData>;
  submitLabel: TKeys;
  initial?: UserUpdateFormData;
};

const TwoColsWrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-2`}
`;

const Wrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-3`}
`;

const ThreeColsWrapper = styled(Wrapper)`
  ${tw`sm:[grid-template-columns:0.35fr_0.35fr_0.3fr]`}
`;

const getCustomConfig = (field: string): UseLiveConfig<CustomRenderFieldTypes["input"]> =>
  function useCustomConfig(prev) {
    const customConfig = useFieldData(field, "values");
    return [useMemo(() => ({ ...prev, disabled: !customConfig }), [prev, customConfig]), true];
  };

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: getFormTitle("user.identity.title"),
  },
  {
    type: "container",
    Component: ThreeColsWrapper,
    fields: [
      {
        type: "text",
        name: "userName",
        label: "user.userName",
        disabled: true,
        validate: { type: "string", required: true },
      },
      {
        type: "live",
        fieldConfig: {
          type: "password",
          name: "password",
          label: "user.password",
          validate: (value, formData) =>
            formData.values.updatePassword !== true ||
            (formData.values.updatePassword === true && value !== undefined && value !== null && value.length > 0)
              ? null
              : "user.passwordRequired",
        },
        name: "password",
        useConfig: getCustomConfig("updatePassword") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "updatePassword",
        label: "user.updatePassword",
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("user.access.accessLevel"),
  },
  {
    type: "container",
    Component: TwoColsWrapper,
    fields: [
      getDomainNamesField({ name: "domainName" }),
      getUserRolesField({ name: "userRoleNames" }),
      getUserPermissionsField({ name: "userPermissions" }),
    ],
  },
];

export const UserUpdateForm: React.FC<UpdateProps> = ({ onSubmit, submitLabel, initial }) => (
  <SchemaForm<UserUpdateFormData>
    fields={fields}
    onSubmit={onSubmit}
    initial={initial}
    customRender={customRender}
    SubmitComponent={() => (
      <FormData type="isSubmittig">
        {(isLoading) => (
          <div tw="flex justify-between">
            <Button
              tw="mt-6"
              variant="side"
              onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "USERS" })}
              data-test="goBack"
            >
              <Text tKey="machine.goBack" />
            </Button>
            <LoadingButton type="submit" tw="mt-6" isLoading={isLoading} disabled={isLoading} data-test="submitForm">
              <Text tKey={submitLabel} />
            </LoadingButton>
          </div>
        )}
      </FormData>
    )}
  />
);
