import { useRef, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { changeFormData, useFormContext } from "common/form";
import { TextLabel } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { unZipFile } from "common/helpers";

import { ConfigTemplateFormData } from "./ConfigTemplateForm";
import { ConfigTemplateParts } from "./ConfigTemplateParts";

const errors = {
  _1: "administration.rc.err._1",
  _2: "administration.rc.err._2",
  _3: "administration.rc.err._3",
  _4: "administration.rc.err._4",
};

export type Data = { error: string } | Omit<ConfigTemplateFormData, "name">;

const readPackageJsonFile = (files: File) =>
  new Promise<Data>((res) =>
    unZipFile(files, errors._1, errors._2)
      .then(async (file) => {
        const data = await file
          .file("META-INF/package.json")
          ?.async("string")
          .then(JSON.parse)
          .then((v) =>
            v.kind === "CONFIGURATION"
              ? ({
                  packageName: files.name,
                  version: v.version,
                  parts: v.parts,
                  description: v.description,
                } as Data)
              : errors._3,
          )
          .catch(() => errors._4);

        if (typeof data === "string" || !data) throw new Error(data || errors._4);

        res({ ...data, file: files });
      })
      .catch((e) => res({ error: e.message })),
  );

export const ConfigTemplateFileSelection: React.FC = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [data, setData] = useState<Data | null>(null);
  const form = useFormContext().useStore;

  const readFile = (files: FileList | null) => {
    if (files) {
      readPackageJsonFile(files[0]).then((v) => {
        setData(v);

        if ("error" in v) {
          form.getState().reset({ name: form.getState().values.name });
        } else {
          changeFormData(form, v, { revalidate: true });
        }
      });
    }
  };

  return (
    <div tw="pt-4 pb-1">
      <input
        ref={ref}
        tw="h-0 w-0 opacity-0 fixed top-[-100px] left-[-100px]"
        type="file"
        onChange={(e) => readFile(e.target.files)}
        accept="application/zip"
      />

      {data && "error" in data ? <TextLabel error>{data.error}</TextLabel> : null}

      {data && "packageName" in data ? (
        <ConfigTemplateParts data={data} onClick={() => ref.current?.click()} />
      ) : (
        <Button onClick={() => ref.current?.click()} variant={["sm", "side"]} data-test="importByFile">
          <Text tKey="administration.rc.import" />
        </Button>
      )}
    </div>
  );
};
