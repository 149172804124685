import { FormatFunction } from "i18next";

import { CONFIG } from "common/config";

const CURR_FORMAT_START = "numberFormat";

export const numberFormat: FormatFunction = (value, formatter, lang, options) => {
  if (formatter === CURR_FORMAT_START) {
    const currency = options?.currency;
    const numberValue = options?.value;
    const minimumFractionDigits = options?.minimumFractionDigits || 2;

    if (!CONFIG.isProd && (numberValue === "" || isNaN(numberValue))) {
      throw new Error("must provide value");
    }

    try {
      return new Intl.NumberFormat(lang, {
        style: options?.style || (currency ? "currency" : "decimal"),
        currency,
        minimumFractionDigits,
      }).format(numberValue);
    } catch (error) {
      return `${new Intl.NumberFormat(lang, { style: "decimal", minimumFractionDigits }).format(numberValue)}${
        currency ? ` ${currency}` : ""
      }`;
    }
  }

  return value;
};
