import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as OnlineStatusSVG } from "assets/icons/OnlineStatus.svg";
import { PageLayout, useSiteGroupContext } from "base/components";
import { Breadcrumb, Table, TableDownload, TableTypes, accessors, useDateRangeFilter } from "common/guideline";
import { GetMachinesOnlineStatusHistoryQuery, useGetMachinesOnlineStatusHistoryQuery } from "generated";
import { FilterBox } from "report/components";
import { useTenant } from "tenant/context";

import { StatusBadge } from "./components";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "machineManagement",
    route: "MACHINE_MANAGEMENT",
  },
  {
    text: "mos.title",
    route: "ONLINE_STATUS",
  },
];

type OnlineStatusType = NonNullable<
  NonNullable<NonNullable<GetMachinesOnlineStatusHistoryQuery["getMachinesOnlineStatusHistory"]>>[0]
>;

const getColumns: TableTypes.TranslatedColumns<OnlineStatusType> = (t) => [
  {
    header: t("mos.machine"),
    id: "machineName",
    accessorKey: "machineName",
  },
  {
    header: t("mos.site"),
    id: "locationName",
    accessorKey: "locationName",
  },
  {
    header: t("mos.status"),
    id: "onlineStatus",
    accessorKey: "onlineStatus",
    cell: StatusBadge,
  },
  {
    header: t("mos.timeOfStatusChange"),
    id: "receivedDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.date(v.receivedDate, t),
    meta: {
      csv: {
        accessorKey: "receivedDate",
      },
    },
  },
];

export const OnlineStatus = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [date, DateRange] = useDateRangeFilter("hour", {
    label: "common.rPeriod",
    availableOptions: ["hour", "day", "7days", "custom"],
  });
  const { machine, location } = useSiteGroupContext();
  const tenantId = useTenant(useTenant.actions.getTenantId) || "";
  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useGetMachinesOnlineStatusHistoryQuery({
    variables: {
      input: {
        fromDateTime: date.from,
        toDateTime: date.to,
        tenantId,
        machineUuid: machine,
        locationId: location,
      },
    },
  });
  const rows = (data?.getMachinesOnlineStatusHistory || []) as OnlineStatusType[];

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="mos.title"
      subtitle="mos.desc"
      Icon={OnlineStatusSVG}
      withPicker
      filterByMachineUuid
    >
      <FilterBox>{DateRange}</FilterBox>
      <Table
        tableName="onlineStatus"
        columns={columns}
        data={rows}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        actions={<TableDownload title="mos.title" disabled={!rows.length} getCsv />}
      />
    </PageLayout>
  );
};
