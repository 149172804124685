import { client } from "client";
import {
  ActivateLicenseByNodeIdDocument,
  ActivateLicenseByNodeIdMutation,
  ActivateLicenseByNodeIdMutationVariables,
  DeactivateLicenseByNodeIdDocument,
  DeactivateLicenseByNodeIdMutation,
  DeactivateLicenseByNodeIdMutationVariables,
  PaymentIntervalType,
  UpgradeMachineLatestLicenseDocument,
  UpgradeMachineLatestLicenseMutation,
  UpgradeMachineLatestLicenseMutationVariables,
} from "generated";

export const isEnterpriseSubscription = (licenseNodeId?: string, edition?: string) =>
  licenseNodeId !== undefined &&
  licenseNodeId !== null &&
  edition !== undefined &&
  edition !== null &&
  edition !== "BASIC";

export const manageLicenseSubscription = async (
  licensingOption: string | undefined | null,
  nodeId: string | undefined | null,
  machineUuid: string,
  paymentIntervalType: PaymentIntervalType | null,
  subscriptionActive: boolean | undefined | null,
  licenseKey: string | undefined | null,
  comment: string | undefined | null,
  serialNo: string | undefined | null,
  deactivatedDate: Date | undefined | null,
) => {
  if (
    (!licenseKey && !deactivatedDate && !subscriptionActive && licensingOption === "upgrade") ||
    (licenseKey && subscriptionActive)
  ) {
    return (
      await client.mutate<UpgradeMachineLatestLicenseMutation, UpgradeMachineLatestLicenseMutationVariables>({
        mutation: UpgradeMachineLatestLicenseDocument,
        variables: {
          licenseSubscription: {
            machineUuid,
            serialNo,
            comment,
            paymentIntervalType,
          },
        },
      })
    ).data?.upgradeMachineLatestLicense?.nodeId;
  } else if (licenseKey && !subscriptionActive && !deactivatedDate && nodeId !== undefined && nodeId !== null) {
    return (
      await client.mutate<DeactivateLicenseByNodeIdMutation, DeactivateLicenseByNodeIdMutationVariables>({
        mutation: DeactivateLicenseByNodeIdDocument,
        variables: {
          nodeId,
        },
      })
    ).data?.deactivateLicenseByNodeId?.nodeId;
  } else if (deactivatedDate && subscriptionActive && !licenseKey && nodeId !== undefined && nodeId !== null) {
    return (
      await client.mutate<ActivateLicenseByNodeIdMutation, ActivateLicenseByNodeIdMutationVariables>({
        mutation: ActivateLicenseByNodeIdDocument,
        variables: {
          nodeId,
        },
      })
    ).data?.activateLicenseByNodeId?.nodeId;
  }
};
