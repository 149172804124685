import { Table } from "@tanstack/react-table";
import { createContext, useContext, useMemo } from "react";

import { TableStorageConfig } from "./TableStorageConfig";

export type TableContextType<TData = any> = Pick<
  Table<TData>,
  "getPrePaginationRowModel" | "getState" | "setState" | "getAllLeafColumns"
> & {
  tableName: string;
};

const TableContext = createContext({} as TableContextType<any>);

export const useTableDataContext = <TData,>() => useContext(TableContext) as TableContextType<TData>;

export const TableProvider = ({
  getPrePaginationRowModel,
  getState,
  setState,
  tableName,
  children,
  getAllLeafColumns,
}: React.PropsWithChildren<
  TableContextType<any> & {
    tableName: string;
  }
>) => {
  const value = useMemo<TableContextType<any>>(
    () => ({
      getPrePaginationRowModel,
      getState,
      setState,
      tableName,
      getAllLeafColumns,
    }),
    [getPrePaginationRowModel, getState, setState, tableName, getAllLeafColumns],
  );

  return (
    <TableContext.Provider value={value}>
      <TableStorageConfig tableContext={value} />
      {children}
    </TableContext.Provider>
  );
};
