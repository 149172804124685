import { CaretRight } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { TableTypes, Text, useModal } from "common/guideline";
import { withDefault } from "common/helpers";

import { MachineViewData } from "./types";

const UserDetailsModal: React.FC<TableTypes.VerticalHeaderProps<MachineViewData>> = ({ row }) => (
  <div>
    <div tw="flex justify-between pr-6 mb-2">{row.original.machine?.name}</div>
    <div tw="grid text-xs [grid-template-columns:max-content_1fr] [grid-gap:12px] justify-items-start">
      <Text tKey="machine.nodeStatus" />
      <span>{row.original.machine?.nodeStatus}</span>
      <Text tKey="machine.nodeChangeDate" />
      <span>
        {withDefault(
          row.original.machine?.nodeChangeDate
            ? new Date(row.original.machine?.nodeChangeDate).toLocaleString()
            : undefined,
        )}
      </span>
      <Text tKey="machine.machineType_one" />
      <span>{withDefault(row.original.machine?.machineType)}</span>
    </div>
  </div>
);

const openUserDetailModal = (row: TableTypes.Row<MachineViewData>) =>
  useModal.actions.open({ Component: <UserDetailsModal row={row} />, closeOnRouteChange: true });

export const MachineVerticalHeader: React.FC<TableTypes.VerticalHeaderProps<MachineViewData>> = (props) => (
  <div tw="flex justify-between pb-4 items-center">
    <div tw="flex">
      <span>{props.row.original.machine?.name}</span>
      <CaretRight size={24} weight="duotone" tw="text-gray-6" onClick={() => openUserDetailModal(props.row)} />
    </div>
  </div>
);
