import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { ReactComponent as MachineUserSVG } from "assets/icons/MachineUser.svg";
import { NamesListTooltip } from "base/components";
import { Table, TableTypes } from "common/guideline";
import { FindAllMachineUserGroupsQuery, useFindAllMachineUserGroupsQuery } from "generated";

import { UpdateName } from "./MachineUserGroupFrom";

type MachineUserGroupRowData = NonNullable<NonNullable<FindAllMachineUserGroupsQuery["findAllMachineUserGroups"]>[0]>;

const CellUpdateAction = ({ value, children }) => (
  <div className="group" tw="flex space-x-1">
    <span>{value}</span>
    <div tw="flex-shrink-[0] sm:invisible group-hover:visible">{children}</div>
  </div>
);

const getColumns: TableTypes.TranslatedColumns<MachineUserGroupRowData> = (t) => [
  {
    header: t("machineUserGroup.name"),
    id: "name",
    accessorKey: "name",
    cell({ getValue, row }) {
      const value = getValue<string>();
      return (
        <CellUpdateAction value={value}>
          <UpdateName name={value} nodeId={row.original.nodeId} />
        </CellUpdateAction>
      );
    },
  },
  {
    header: t("mu.title_other"),
    id: "machineUsers",
    accessorKey: "machineUsers",
    cell({ getValue }) {
      const value = getValue<MachineUserGroupRowData["machineUsers"]>() ?? [];
      const machineUsers = (
        <NamesListTooltip
          count={value.length}
          names={value.map((v) => v?.name as string)}
          Icon={MachineUserSVG}
          data-test="machineUsersAll"
        >
          -
        </NamesListTooltip>
      );

      return (
        <CellUpdateAction value={machineUsers}>
          {/* TODO wait for an API to allow add and remove in one mutation */}
          {/* <UpdateMachineUsers machineUserNodeIds={value?.map((v) => v?.nodeId)} /> */}
        </CellUpdateAction>
      );
    },
  },
  {
    header: t("machineUserGroup.authorizedMachines"),
    id: "machines",
    accessorKey: "machines",
    cell({ getValue }) {
      const value = getValue<MachineUserGroupRowData["machines"]>() ?? [];
      const machines = (
        <NamesListTooltip
          count={value.length}
          names={value.map((v) => v?.name as string)}
          Icon={ComputerTowerSVG}
          data-test="machinesAll"
        >
          -
        </NamesListTooltip>
      );

      return (
        <CellUpdateAction value={machines}>
          {/* TODO wait for an API to allow add and remove in one mutation */}
          {/* <UpdateMachines machineNodeIds={value?.map((v) => v?.nodeId)} /> */}
        </CellUpdateAction>
      );
    },
  },
];

export const MachineUserGroupsTable = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const { previousData, data = previousData, loading, error } = useFindAllMachineUserGroupsQuery();

  return (
    <Table
      tableName="machineUserGroups"
      columns={columns}
      data={(data?.findAllMachineUserGroups || []) as MachineUserGroupRowData[]}
      loading={loading}
      error={error}
      initialLoading={previousData === undefined}
    />
  );
};
