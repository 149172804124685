import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as RollingBalanceSVG } from "assets/icons/RollingBalance.svg";
import { PageLayout } from "base/components";
import { Badge, Table, TableDownload, TableTypes, accessors, localFilters, useDateFilter } from "common/guideline";
import { isoDateWithoutTime, withDefault } from "common/helpers";
import { FindUserBalancesQuery, useFindUserBalancesQuery } from "generated";
import { FilterBox } from "report/components";

import { breadcrumbs } from "./breadcrumbs";

export type RollingBalanceType = NonNullable<NonNullable<FindUserBalancesQuery["findUserBalances"]>[0]>;

const getColumns: TableTypes.TranslatedColumns<RollingBalanceType> = (t) => [
  {
    header: t("transport.rBalance.machineUser"),
    accessorFn: (v) => withDefault(v.machineUser?.name),
    ...localFilters.getSelectBaseFilter(true),
    enableHiding: false,
  },
  {
    header: t("transport.rBalance.date"),
    accessorFn: (v) => accessors.date(v.date, t),
    sortingFn: "dateString",
    id: "date",
    ...localFilters.getDateRangeFilter,
  },
  {
    header: t("transport.rBalance.balance"),
    id: "balance",
    sortingFn: "alphanumeric",
    ...localFilters.getNumberFilter,
    accessorFn: (d) => accessors.number(d.balance ?? 0, t),
  },
  {
    header: t("transport.rBalance.currencyCode"),
    accessorKey: "currencyCode",
    ...localFilters.getSelectBaseFilter(true),
    cell({ getValue }) {
      return <Badge variant="info">{getValue<string>()}</Badge>;
    },
  },
];

export const RollingBalance = () => {
  const { t, i18n } = useTranslation();
  const [date, DateFilter] = useDateFilter({ label: "common.rPeriod", dateFormat: "PPpp" });
  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useFindUserBalancesQuery({ variables: { date: isoDateWithoutTime(date) } });

  const rows = data?.findUserBalances as RollingBalanceType[] | undefined;
  const columns = useMemo(() => (!rows ? [] : getColumns(t, i18n.language)), [t, i18n.language, rows]);
  const totalCount = data?.findUserBalances?.length || 0;

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="transport.rBalance.title"
      subtitle="transport.rBalance.desc"
      Icon={RollingBalanceSVG}
    >
      <FilterBox>{DateFilter}</FilterBox>
      <Table
        tableName="rollingBalance"
        columns={columns}
        data={rows || []}
        totalCount={totalCount}
        error={error}
        loading={loading}
        initialLoading={previousData === undefined}
        actions={<TableDownload title="transport.rBalance.title" disabled={!totalCount} getCsv />}
      />
    </PageLayout>
  );
};
