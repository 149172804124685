import { Circle } from "phosphor-react";
import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { VariantsByKeys, WithVariants, applyVariant } from "common/guideline/components/helpers";

import { useFieldData } from "../field";
import { FieldCommonsData, RawFieldData, fieldFactory } from "../schema";

import { TextLabel } from "./Common";

type CheckboxVariants = "sm";

export type RadioProps = FieldCommonsData &
  WithVariants<typeof variants> & {
    type: "radio";
    disabled?: boolean;
    label?: string;
    values: { value: string; label: string }[];
    horizontal?: boolean;
  };

type RadioInputProps = Omit<RadioProps, "values">;

const variants: VariantsByKeys<CheckboxVariants> = {
  sm: css`
    & > div {
      ${tw`w-4 h-4 bg-gray-4`}
    }

    svg {
      ${tw`w-3`}
    }
  `,
};

const StyledCheckbox = styled.label`
  & > div {
    ${tw`bg-gray-2 border rounded-full w-6 h-6 relative cursor-pointer`}
    ${({ checked }: any) => (checked ? tw`border-primary-default` : tw`border-gray-3`)}
  }

  &:focus-within {
    ${tw`text-primary-default`}
  }

  svg {
    ${tw`text-primary-default absolute w-5 h-5 top-1/2 left-1/2`}
    transform: translate(-50%, -50%);
  }

  input[type="radio"] {
    appearance: none;

    &:focus {
      ${tw`border-primary-default outline-none`}
    }
  }

  ${applyVariant(variants)}
`;

const RadioRaw: React.FC<RawFieldData<RadioInputProps>> = ({
  label,
  name,
  type = "radio",
  onChange,
  onBlur,
  onFocus,
  value,
  ...rest
}) => (
  <StyledCheckbox tw="flex items-center" data-test={value} {...rest}>
    <div>
      <input
        checked={rest.checked}
        name={name}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
      />
      {rest.checked && <Circle weight="duotone" />}
    </div>

    {label && <TextLabel tw="p-0 pl-2 [text-transform:none] cursor-pointer">{label}</TextLabel>}
  </StyledCheckbox>
);

const RadioFieldInput = fieldFactory(RadioRaw);

export const RadioField: React.FC<RadioProps> = ({
  values,
  label,
  horizontal,
  //  dont need to pass it to radio input
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorSub,
  ...props
}) => {
  const error = useFieldData(props.name, "errors", Boolean);

  return (
    <div tw="text-gray-6">
      <TextLabel error={error}>{label}</TextLabel>

      <div
        css={css`
          ${tw`flex pt-2`} ${horizontal ? tw`space-x-5` : tw`flex-col space-y-2`}
        `}
      >
        {values.map((value) => (
          <RadioFieldInput key={value.value} {...value} {...props} />
        ))}
      </div>
    </div>
  );
};

export const RadioFieldRaw: React.FC<RawFieldData<RadioProps>> = ({
  values,
  label,
  horizontal,
  error,
  value: checked,
  ...props
}) => (
  <div tw="text-gray-6">
    <TextLabel error={error}>{label}</TextLabel>

    <div
      css={css`
        ${tw`flex pt-2`} ${horizontal ? tw`space-x-5` : tw`flex-col space-y-2`}
      `}
    >
      {values.map((value) => (
        <RadioRaw key={value.value} {...value} {...props} checked={checked === value.value} />
      ))}
    </div>
  </div>
);
