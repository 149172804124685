import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as MachineDetailsSVG } from "assets/icons/MachineDetails.svg";
import { PageLayout, useMappedSiteGroupContext } from "base/components";
import { client } from "client";
import {
  Badge,
  CommonCells,
  CustomGetCsvFn,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  usePagination,
} from "common/guideline";
import {
  GenerateMachineDetailsReportDocument,
  GenerateMachineDetailsReportQuery,
  GenerateMachineDetailsReportQueryVariables,
  useGenerateMachineDetailsReportQuery,
} from "generated";
import { useTranslation } from "i18n";

import { breadcrumbs } from "./breadcrumbs";
import { MachineDetailsSubRow } from "./MachineDetailsSubRow";

export type MachineRows = NonNullable<
  NonNullable<NonNullable<GenerateMachineDetailsReportQuery["generateMachineDetailsReport"]>["rows"]>[0]
>;

const getColumns: TableTypes.TranslatedColumns<MachineRows> = (t) => [
  CommonCells.expander,
  {
    header: t("machine.uuid"),
    accessorKey: "machineUuid",
    enableSorting: false,
  },
  {
    header: t("location.location_one"),
    id: "location",
    accessorKey: "location",
    cell({ getValue }) {
      return <Badge variant="info">{getValue<string>()}</Badge>;
    },
    enableSorting: false,
  },
  {
    header: t("machine.machineAlias"),
    accessorKey: "machineAlias",
    enableSorting: false,
  },
  {
    header: t("machine.machineType_one"),
    id: "machineType",
    accessorKey: "machineType",
    cell({ getValue }) {
      return getValue() ? <Badge variant="neutral">{getValue<string>()}</Badge> : <span />;
    },
    enableSorting: false,
  },
  {
    header: t("report.md.codVersion"),
    accessorKey: "codVersion",
    enableSorting: false,
  },
  {
    header: t("report.md.machineDate"),
    accessorFn: (d) => accessors.date(d.machineDate, t),
    enableSorting: false,
  },
  {
    header: t("report.md.accountingDate"),
    accessorFn: (d) => accessors.date(d.accountingDate, t),
    enableSorting: false,
  },
  {
    header: t("report.md.lastErrorDate"),
    accessorFn: (d) => accessors.date(d.lastErrorDate, t),
    enableSorting: false,
  },
  {
    header: t("report.md.lastError"),
    accessorKey: "lastError",
    enableSorting: false,
  },
];

export const MachineDetails = () => {
  const { t, i18n } = useTranslation();
  const [{ pageSize, pageIndex }, setPagination] = usePagination();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ location, siteGroup }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateMachineDetailsReportQuery({
    variables: {
      criteriaDto: {
        skip: pageSize * pageIndex,
        limit: pageSize,
        locationNodeIds: location ? location : siteGroup,
      },
    },
  });

  const rows = data?.generateMachineDetailsReport?.rows as MachineRows[] | undefined;
  const fullSize = data?.generateMachineDetailsReport?.fullSize || 0;

  const getCsv: CustomGetCsvFn = async (getOptions) => {
    if (!fullSize) return "";

    const { data: { generateMachineDetailsReport } = {} } = await client.query<
      GenerateMachineDetailsReportQuery,
      GenerateMachineDetailsReportQueryVariables
    >({
      query: GenerateMachineDetailsReportDocument,
      variables: {
        criteriaDto: {
          skip: 0,
          limit: fullSize || 0,
          locationNodeIds: location,
          siteGroupNodeIds: siteGroup,
        },
      },
    });

    return getOptions({ data: (generateMachineDetailsReport?.rows || []) as MachineRows[] });
  };

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="report.md.title"
      subtitle="report.md.desc"
      Icon={MachineDetailsSVG}
      withPicker
    >
      <Table<MachineRows>
        tableName="machineDetails"
        data={rows || []}
        columns={columns}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagination={setPagination}
        totalCount={fullSize}
        SubRows={MachineDetailsSubRow}
        actions={<TableDownload title="report.md.title" disabled={!fullSize} getCsv={getCsv} />}
      />
    </PageLayout>
  );
};
