import { CellContext } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { MinusCircle, PlusCircle } from "phosphor-react";
import { Fragment } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Popover, Spinner, TableTypes, Text } from "common/guideline";
import { floatToComa } from "common/helpers";
import { AmountDto, NetCashReportRowDto, TotalAmountsDto, useFindBoxTotalsByCurrencyQuery } from "generated";

type LoadBoxContentProps = {
  currency: string | undefined | null;
  transactionsNodeIds: TotalAmountsDto["boxContentNodeIds"];
};

const LoadBoxContent: React.FC<LoadBoxContentProps> = ({ currency, transactionsNodeIds }) => {
  const { data, loading } = useFindBoxTotalsByCurrencyQuery({
    variables: { transactionsNodeIds, currency },
    skip: !currency || !transactionsNodeIds?.length,
  });

  const values = data?.findBoxTotalsByCurrency;

  return loading ? (
    <Spinner />
  ) : (
    <div tw="grid grid-cols-[max-content 1fr] gap-2 text-xs text-gray-8">
      {values?.length ? (
        values.map((v, i) => (
          <Fragment key={v?.key || i}>
            <span>{v?.key}:</span>
            <Text tKey="numberFormat" tValue={{ value: v?.value || 0 }} tw="justify-self-end" />
          </Fragment>
        ))
      ) : (
        <Text tKey="report.netCash.noAmountsData" />
      )}
    </div>
  );
};

type DataWithTotalAmounts = Pick<NetCashReportRowDto, "openingTotalAmounts" | "closingTotalAmounts">;
type AmountDtoWithBoxNodeIds = AmountDto & Pick<TotalAmountsDto, "boxContentNodeIds">;
type AmountDtoWithBoxNodeIdsRecord = Record<string, AmountDtoWithBoxNodeIds>;
type RowsDataResult = {
  currencies: string[];
  opening: AmountDtoWithBoxNodeIdsRecord[];
  closing: AmountDtoWithBoxNodeIdsRecord[];
};

const getRowsData = (rows: DataWithTotalAmounts[]): RowsDataResult => {
  const currencies = new Set<string>();

  const mapRow = (type: "openingTotalAmounts" | "closingTotalAmounts") => (row: DataWithTotalAmounts) =>
    row[type]?.reduce((acc, curr) => {
      if (!curr) return acc;

      curr.totalAmounts?.forEach((amount) => {
        if (amount?.currency) {
          currencies.add(amount.currency);

          acc[amount.currency] = {
            ...amount,
            boxContentNodeIds: curr.boxContentNodeIds,
          };
        }
      });

      return acc;
    }, {} as AmountDtoWithBoxNodeIdsRecord) || {};

  const opening = rows.map(mapRow("openingTotalAmounts"));
  const closing = rows.map(mapRow("closingTotalAmounts"));

  return { currencies: Array.from(currencies), opening, closing };
};

const getAmountCell = (currency: string, data: AmountDtoWithBoxNodeIdsRecord[]) =>
  function Cell({ getValue, row }: CellContext<any, unknown>) {
    const value = getValue<string>();
    const totals = data[row.index][currency];

    return (
      <div tw="flex space-x-1 items-center">
        <Popover
          overflowContainer
          auto
          possiblePlacements={["bottom-center", "bottom-end", "bottom-start", "top-center", "top-end", "top-start"]}
          content={() => <LoadBoxContent currency={totals?.currency} transactionsNodeIds={totals?.boxContentNodeIds} />}
        >
          {(isOpen) =>
            isOpen ? (
              <MinusCircle size={16} weight="duotone" tw="text-gray-6" />
            ) : (
              <PlusCircle size={16} weight="duotone" tw="text-success-default" />
            )
          }
        </Popover>

        <Text tKey="numberFormat" tValue={{ value }} />
      </div>
    );
  };

export const getTotalAmountsNetCashRows = <T extends DataWithTotalAmounts>(rows: T[], t: TFunction) => {
  const { currencies, opening, closing } = getRowsData(rows);

  return Object.values(
    currencies.reduce((acc, currency) => {
      acc[`${currency}_opening`] = {
        id: `${currency}_opening`,
        header: t("report.openingTotalAmount", { currency }),
        enableColumnFilter: false,
        enableHiding: false,
        accessorFn: (_, i) => opening[i][currency]?.amount || 0,
        cell: getAmountCell(currency, opening),
        meta: {
          csv: {
            accessorFn: (_, i) => floatToComa(opening[i][currency]?.amount || ""),
          },
        },
      };

      acc[currency] = {
        id: `${currency}_closing`,
        header: t("report.closingTotalAmount", { currency }),
        enableColumnFilter: false,
        enableHiding: false,
        accessorFn: (_, i) => closing[i][currency]?.amount || 0,
        cell: getAmountCell(currency, closing),
        meta: {
          csv: {
            accessorFn: (_, i) => floatToComa(closing[i][currency]?.amount || ""),
          },
        },
      };

      return acc;
    }, {} as Record<string, TableTypes.ColumnWithSubAccessor<T>>),
  );
};
