import { format, isValid, parse } from "date-fns";

import { UseFieldOptions } from "common/form";

import { isBetween } from "./number";

export const TIME_FORMAT = "kk:mm";
export const DURATION_FORMAT = "kk:mm:ss";

export const getDateFieldFormatHelper: (dateFormat: string) => Pick<UseFieldOptions<Date>, "format" | "parse"> = (
  dateFormat,
) => ({
  parse: (val) => (isValid(val) ? format(val, dateFormat) : val),
  format: (val) => parse(val, dateFormat, new Date()),
});

export const getDate = <T extends Date | undefined>(dateParsed: string | number | undefined, defaultDate?: T): T => {
  const date = dateParsed ? new Date(dateParsed) : dateParsed;
  return (isValid(date) ? date : defaultDate) as T;
};

export const getMinMaxDates = (times: number[]): [] | [Date] | [Date, Date] =>
  times.length === 0
    ? []
    : times.length === 1
    ? [new Date(times[0])]
    : [new Date(Math.min(...times)), new Date(Math.max(...times))];

// round minutes to whole hour, if without direction `Math.round` is used, if direction - hour is always rounded to ceil or floor
export const roundHours = (date: Date | string | number, direction?: "ceil" | "floor"): Date => {
  const _date = new Date(date);

  if (direction !== "floor") {
    _date.setHours(_date.getHours() + (direction === "ceil" ? 1 : Math.round(_date.getMinutes() / 60)));
  }

  _date.setMinutes(0, 0, 0);
  return _date;
};

export const isoDateWithoutTime = (date: Date): string => date.toISOString().split("T")[0];

// local month and day
const PPnotYear = (date: Date, locale: Locale, options?: Intl.DateTimeFormatOptions) =>
  date.toLocaleDateString(locale.code, {
    month: "short",
    day: "numeric",
    ...options,
  });

export const customFormatters = {
  // local month and day period - if dates same year - then first is shown without a year
  PPperiod: (dates: Date[], locale: Locale) => {
    const options = locale ? { locale } : undefined;

    return dates.length === 0
      ? "-"
      : dates.length === 1 || !dates[1] || !dates[0]
      ? format(!dates[0] ? dates[1] : dates[0], "PP", options)
      : `${
          dates[0].getFullYear() === dates[1].getFullYear()
            ? PPnotYear(dates[0], locale)
            : format(dates[0], "PP", options)
        } - ${format(dates[1], "PP", options)}`;
  },
  // same as PPperiod, but with time
  PPp_period: (dates: Date[], locale: Locale) => {
    const options = locale ? { locale } : undefined;

    return dates.length === 0
      ? "-"
      : dates.length === 1 || !dates[1] || !dates[0]
      ? format(!dates[0] ? dates[1] : dates[0], "PPp", options)
      : `${
          dates[0].getFullYear() === dates[1].getFullYear()
            ? PPnotYear(dates[0], locale, { hour: "2-digit", minute: "2-digit" })
            : format(dates[0], "PPp", options)
        } - ${format(dates[1], "PPp", options)}`;
  },
};

const timeZoneUtcOffsetRegExp = /^(-|\+)?(\d{1,2})(\.)?/;

export const getTimeZoneUtcOffset = (val: string | undefined | Date = "") => {
  const v = val instanceof Date ? (val.getTimezoneOffset() / 60).toString() : val;
  const [, sign = "+", _offset] = v.match(timeZoneUtcOffsetRegExp) || [];

  if (!_offset || !isBetween(-23, 23, Number(_offset))) return "+00.00";

  const offset = _offset.length === 2 ? _offset : `0${_offset}`;

  return `${sign}${offset}.00`;
};

export const TIME_ZONE_UTC_OFFSET_REG_EXP = /^(-|\+)?(\d{2})(\.00)/;
