import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CashSummarySVG } from "assets/icons/CashSummary.svg";
import { PageLayout, ValueWidget, useMappedSiteGroupContext } from "base/components";
import {
  Breadcrumb,
  CommonCells,
  CustomGetCsvFn,
  Table,
  TableDownload,
  TableTypes,
  Text,
  accessors,
  useDateFilter,
} from "common/guideline";
import { floatToComa } from "common/helpers";
import { GenerateCashSummaryReportQuery, useGenerateCashSummaryReportQuery } from "generated";
import { FilterBox } from "report/components";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "cashMgmt.title",
    route: "CASH_MANAGEMENT",
  },
  {
    text: "report.cashSummary.title",
    route: "CASH_SUMMARY",
  },
];

type Row = NonNullable<
  NonNullable<NonNullable<GenerateCashSummaryReportQuery["generateCashSummaryReport"]>["rows"]>[0]
>;

type SubRow = NonNullable<NonNullable<Row["subRows"]>[0]>;

type CashSummaryRow = Row & SubRow;

const getColumns: TableTypes.TranslatedColumns<CashSummaryRow> = (t) => [
  CommonCells.expander,
  {
    header: t("machine.machine_one"),
    accessorKey: "machineName",
  },
  {
    header: t("location.location_one"),
    accessorKey: "locationName",
  },
  {
    header: t("report.currency"),
    accessorKey: "currency",
  },
  {
    header: t("report.denomination"),
    accessorKey: "denomination",
    meta: {
      csv: {
        accessorFn: (v) => (v.denomination ? floatToComa(v.denomination) : ""),
      },
    },
  },
  {
    header: t("report.type"),
    accessorKey: "type",
  },
  {
    header: t("report.cashSummary.dispensableCount"),
    accessorKey: "dispensableCount",
    sortingFn: "alphanumeric",
  },
  {
    header: t("report.cashSummary.dispensableAmount"),
    accessorFn: (v) => accessors.number(v.dispensableAmount || 0, t),
    sortingFn: "alphanumeric",
    meta: {
      csv: {
        accessorFn: (v) => (v.dispensableAmount ? floatToComa(v.dispensableAmount) : ""),
      },
    },
  },
  {
    header: t("report.cashSummary.bankableCount"),
    accessorKey: "bankableCount",
  },
  {
    header: t("report.cashSummary.bankableAmount"),
    accessorFn: (v) => accessors.number(v.bankableAmount || 0, t),
    sortingFn: "alphanumeric",
    meta: {
      csv: {
        accessorFn: (v) => (v.bankableAmount ? floatToComa(v.bankableAmount) : ""),
      },
    },
  },
  {
    header: t("report.total"),
    accessorFn: (v) => accessors.number(v.total || 0, t),
    sortingFn: "alphanumeric",
    meta: {
      csv: {
        accessorFn: (v) => (v.total ? floatToComa(v.total) : ""),
      },
    },
  },
];

const toDate = new Date();

export const CashSummary = () => {
  const { t, i18n } = useTranslation();
  const [referenceDate, DateFilter] = useDateFilter({ label: "common.rDate", dateFormat: "PPp", toDate });
  const [{ location, siteGroup }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateCashSummaryReportQuery({
    variables: {
      input: { referenceDate, locationNodeIds: location ? location : siteGroup },
    },
  });

  const rows = data?.generateCashSummaryReport?.rows as CashSummaryRow[] | undefined;
  const totalAmounts = data?.generateCashSummaryReport?.totalAmounts;

  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const getCsv: CustomGetCsvFn<CashSummaryRow> = (getOptions) =>
    getOptions({
      withSubRows: true,
      mapRow: (a, b) => ({
        ...a,
        ...b,
        machineName: `${a?.machineName} [${a?.currency}]`,
      }),
      additionalData: (totalAmounts || []).flatMap((r) => [
        {
          machineName: `${t("report.cashSummary.machineTotal")} [${r?.currency}]`,
          total: floatToComa(r?.machineTotal || ""),
        },
        {
          machineName: `${t("report.cashSummary.dropSafe")} [${r?.currency}]`,
          total: floatToComa(r?.claimedValueTotal || ""),
        },
        {
          machineName: `${t("report.openShifts.title")} [${r?.currency}]`,
          total: floatToComa(r?.openShiftTotal || ""),
        },
        { machineName: `${t("report.total")} [${r?.currency}]`, total: floatToComa(r?.grandTotal || "") },
      ]) as CashSummaryRow[],
    });

  return (
    <PageLayout
      title="report.cashSummary.title"
      subtitle="report.cashSummary.desc"
      breadcrumbs={breadcrumbs}
      Icon={CashSummarySVG}
      withPicker
    >
      <FilterBox>{DateFilter}</FilterBox>
      <Table
        tableName="cashSummary"
        data={rows || []}
        columns={columns}
        loading={loading}
        error={error}
        initialLoading={previousData === undefined}
        actions={<TableDownload title="report.cashSummary.title" disabled={!rows?.length} getCsv={getCsv} />}
      />
      {totalAmounts?.length ? (
        <div tw="text-xs pt-4">
          <Text tKey="report.total" variant="heading" tValue={{ colon: true }} />
          <div tw="grid [grid-template-columns:repeat(auto-fill,_minmax(230px,_1fr))] gap-4 pt-4">
            {totalAmounts?.map((t, i) => (
              <ValueWidget
                key={i}
                title="report.totalAmount"
                tValue={{ currency: t?.currency }}
                value={t?.grandTotal ? Number(t?.grandTotal) : 0}
              >
                <div tw="pt-2 grid grid-cols-2 gap-2 [grid-template-columns:max-content_1fr]">
                  <Text tKey="report.cashSummary.machineTotal" />
                  <Text tKey="numberFormat" tValue={{ value: t?.machineTotal }} />
                  <Text tKey="report.cashSummary.dropSafe" />
                  <Text tKey="numberFormat" tValue={{ value: t?.claimedValueTotal }} />
                  <Text tKey="report.openShifts.title" />
                  <Text tKey="numberFormat" tValue={{ value: t?.openShiftTotal }} />
                </div>
              </ValueWidget>
            ))}
          </div>
        </div>
      ) : null}
    </PageLayout>
  );
};
