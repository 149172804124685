import { TKeys } from "i18next";
import { useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getActiveOrInActiveStatusField, getFormTitle } from "base/fields";
import { OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { LocationDtoIn } from "generated";
import { getCoordinatesField, getSelectCountryField } from "location/components";

export type LocationFormData = Pick<
  LocationDtoIn,
  | "name"
  | "longitude"
  | "latitude"
  | "machineNodeIds"
  | "nodeStatus"
  | "city"
  | "country"
  | "description"
  | "customerReference"
>;

const TwoColWrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const OneColWrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-1 mt-4`}
`;

const getFields: (updateForm: boolean | undefined) => CustomRenderFields[] = () => [
  {
    type: "container",
    Component: getFormTitle("location.siteDetails"),
  },
  {
    type: "container",
    Component: TwoColWrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "location.name",
        validate: { type: "string", required: true },
      },
      getActiveOrInActiveStatusField({ name: "nodeStatus", label: "location.nodeStatus", defaultValue: "ACTIVE" }),
      {
        type: "text",
        name: "description",
        label: "location.description",
      },
      {
        type: "text",
        name: "customerReference",
        label: "location.customerReference",
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("location.geographicalLocation"),
  },
  {
    type: "container",
    Component: TwoColWrapper,
    fields: [
      getSelectCountryField({ name: "country", validate: { type: "string", required: true } }),
      {
        type: "text",
        name: "city",
        label: "location.city",
        validate: { type: "string", required: true },
      },
    ],
  },
  {
    type: "container",
    Component: OneColWrapper,
    fields: [getCoordinatesField(TwoColWrapper)],
  },
];

type Props = {
  onSubmit: OnSubmit<LocationFormData>;
  submitLabel: TKeys;
  initial?: LocationFormData;
  updateForm?: boolean;
};

export const LocationForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm }) => {
  const [fields] = useState(() => getFields(updateForm));

  return (
    <SchemaForm<LocationFormData>
      fields={fields}
      initial={initial}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-between">
          <Button
            tw="mt-6"
            variant="side"
            onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "LOCATIONS" })}
            data-test="goBack"
          >
            <Text tKey="location.goBack" />
          </Button>
          <Button type="submit" tw="mt-6" data-test="submitForm">
            <Text tKey={submitLabel} />
          </Button>
        </div>
      )}
    />
  );
};
