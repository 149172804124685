import { startOfDay } from "date-fns";
import { useState } from "react";

import { DateInputProps, DateInputRaw } from "common/form/renderFields";

export const useDateFilter = (props?: Partial<DateInputProps & { initialDate: Date }>): [Date, JSX.Element] => {
  const [date, setDate] = useState(() => props?.initialDate || startOfDay(new Date()));

  return [
    date,
    <DateInputRaw key="dateFilter" name="date" type="date" value={date} onChange={(v) => v && setDate(v)} {...props} />,
  ];
};
