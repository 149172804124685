import { useState } from "react";

import { SelectProps, SelectRaw } from "common/form/renderFields";

export const useSelectFilter = <T extends string>(
  props?: Partial<SelectProps & { initial: T }>,
): [T | undefined, JSX.Element] => {
  const [value, setValue] = useState(props?.initial);

  return [
    value,
    <SelectRaw key={`selectFilter_${props?.name || ""}`} name="select" value={value} onChange={setValue} {...props} />,
  ];
};
