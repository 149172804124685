import { useMemo } from "react";

import { Badge, CommonCells, Table, TableTypes } from "common/guideline";
import { withDefault } from "common/helpers";
import { useFindAllMachineUsersQuery } from "generated";
import { useTranslation } from "i18n";

import { MachineUserVerticalHeader } from "./MachineUserVerticalHeader";
import { RowActions } from "./RowActions";
import { MachineUserData } from "./types";

const columns: TableTypes.TranslatedColumns<MachineUserData> = (t) => [
  {
    header: t("mu.userId"),
    accessorKey: "userId",
  },
  {
    header: t("mu.cardLogin"),
    accessorKey: "cardIdentifier",
  },
  {
    header: t("mu.name"),
    accessorKey: "name",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("mu.roleName"),
    accessorKey: "roleName",
    cell({ getValue }) {
      return withDefault(getValue() ? <Badge>{getValue<string>()}</Badge> : undefined);
    },
  },
  {
    header: t("mu.deactivated"),
    accessorKey: "deactivated",
  },
  {
    header: t("mu.validFrom"),
    accessorKey: "validFrom",
  },
  {
    header: t("mu.validUntil"),
    accessorKey: "validUntil",
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

export const MachineUserTable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const { data, loading, error, previousData } = useFindAllMachineUsersQuery();

  return (
    <Table
      tableName="machineUsers"
      columns={tColumns}
      data={(data?.findAllMachineUsers || []) as MachineUserData[]}
      VerticalHeader={MachineUserVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};
