import {
  ApiPermissions,
  ApiRoleNames,
  FormPermissions,
  FormRoleNames,
  UserCreateFormData,
  UserUpdateFormData,
} from "../types";

export const getAccessTranslationString = (word: string | undefined | null) =>
  `user.access.${word?.replace("/", "_").replace(":", "_")}`;

const modifyPermissions = <T extends FormPermissions>({
  userPermissions,
  ...payload
}: T): Omit<T, "userPermissions"> & ApiPermissions => ({
  ...payload,
  tenantIdsPermission: userPermissions?.filter((p) => p.includes("tenant:ALL")) || [],
  locationGroupNodeIdsPermission: userPermissions?.filter((p) => p.includes("siteGroup:ALL")) || [],
  locationNodeIdsPermission: userPermissions?.filter((p) => p.includes("site:ALL")) || [],
  machineNodeIdsPermission: userPermissions?.filter((p) => p.includes("machine:AL")) || [],
});

const modifyUserRoleNames = <T extends FormRoleNames>(payload: T): Omit<T, "userRoleNames"> & ApiRoleNames => ({
  ...payload,
  userRoleNames: payload.userRoleNames ? [payload.userRoleNames] : undefined,
});

export const modifyCreatePayload = (payload: UserCreateFormData) => modifyPermissions(modifyUserRoleNames(payload));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const modifyUpdatePayload = ({ updatePassword, password, ...payload }: UserUpdateFormData) =>
  modifyPermissions(modifyUserRoleNames(payload));

export const getUpdatePasswordPayload = (payload: UserUpdateFormData) => {
  return payload.updatePassword !== true || !payload?.password
    ? null
    : {
        domainName: payload.domainName,
        userName: payload.userName,
        password: payload.password,
      };
};
