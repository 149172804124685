// plural keys companion - https://jsfiddle.net/sm9wgLze

import { FormatFunction, LanguageDetectorAsyncModule, Languages, TFunction, use } from "i18next";
import { Trans, initReactI18next, useTranslation } from "react-i18next";

import { dateFormat, dateI18n, useTranslationWithLocale } from "./date";
import { numberFormat } from "./numberFormat";
import { postProcessor } from "./postProcessor";
import { translations } from "./translations";

export const defaultNS = "translation";
export const resources = translations;

const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: (cb) => cb("en-US" as Languages),
};

const customFormat: FormatFunction = (value, formatter, lang, options) =>
  numberFormat(dateFormat(value, formatter, lang, options), formatter, lang, options);

use(languageDetector)
  .use(initReactI18next)
  .use(postProcessor)
  .init({
    defaultNS,
    postProcess: postProcessor.name,
    fallbackLng: "en-US" as Languages,
    debug: false,
    resources: translations,
    interpolation: {
      escapeValue: false, // react already safe from xss
      format: customFormat,
      skipOnVariables: false,
      defaultVariables: {
        to: "",
        from: "",
      },
    },
  });

export { useTranslationWithLocale, Trans, useTranslation, dateI18n };
export type { TFunction };
