import { Spinner } from "phosphor-react";
import { Fragment } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { NamesByIds } from "base/components";
import { Line, Text } from "common/guideline";

type Props = { nodeIds?: string[] };

export const MachinesList: React.FC<Props> = ({ nodeIds }) =>
  nodeIds?.length ? (
    <NamesByIds entity="machines" ids={nodeIds}>
      {(names, loading) =>
        loading ? (
          <Spinner tw="animate-spin" />
        ) : (
          <div tw="grid [grid-template-columns:max-content_1fr] [grid-gap:12px_10px]">
            {names.map((name, i) => (
              <Fragment key={name}>
                {i !== 0 && <Line tw="col-start-1 col-end-[-1] bg-gray-5 opacity-50" />}
                <span>{name}</span>
              </Fragment>
            ))}
          </div>
        )
      }
    </NamesByIds>
  ) : (
    <Text tKey="machine.noData" />
  );
