import { ColumnDef, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getTableConfig } from "../helpers";
import { Pagination } from "../Pagination";
import { TableProvider } from "../TableContext";
import { TableMeta } from "../TableMeta";
import { GenericRecord, TableProps } from "../types";

import { TableBody } from "./TableBody";
import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";

type StyledTableProps = Pick<TableProps<any>, "showPagination"> & {
  isLoading?: boolean;
};

const StyledTable = styled.div<StyledTableProps>`
  ${tw`flex overflow-hidden overflow-x-auto flex-1 relative`}
  ${({ showPagination }) => !showPagination && tw`rounded-lg border border-gray-3`}

  .table {
    ${tw`flex flex-col flex-1`}
  }

  .tr {
    ${tw`flex`}

    &.expanded {
      ${tw`bg-primary-accent!`}
    }
  }

  .tHead {
    ${tw`bg-gray-1 border-b border-b-gray-3`}

    .tr:first-of-type {
      ${tw`text-left text-xs pb-0 font-semibold tracking-wider`}
    }

    .tr:last-of-type > .th > * {
      ${tw`flex-1`}
    }

    .th {
      ${tw`flex`}
    }
  }

  .tBody {
    ${tw`h-full text-gray-8 text-sm relative overflow-x-hidden overflow-y-auto`}
    ${({ isLoading }) => isLoading && tw`animate-pulse [animation-duration:1s]`}

    .even {
      ${tw`bg-gray-1`}
    }

    .odd {
      ${tw`bg-gray-2`}
    }
  }

  .th,
  .td {
    ${tw`px-4 py-2 text-xs align-top flex-shrink-[0]`}
  }

  .td {
    ${tw`overflow-ellipsis overflow-hidden `}
  }

  .th > span:first-of-type {
    ${tw`overflow-ellipsis overflow-hidden`}
  }

  .tfoot {
    ${tw`font-semibold border-t border-t-gray-3`}
  }
`;

const defaultColumn: Partial<ColumnDef<any, unknown>> = {
  size: 150,
  minSize: 30,
  maxSize: 400,
};

export function TableHorizontal<T extends GenericRecord>({
  data,
  columns,
  showPagination = true,
  totalCount = data.length || 0,
  error,
  loading,
  SubRows,
  actions,
  onSorting,
  onPagination,
  onFilter,
  sorting,
  columnFilters,
  pageSize,
  pageIndex,
  tableName,
}: Omit<TableProps<T>, "VerticalHeader">): React.ReactElement {
  const hasSubRows = Boolean(SubRows);
  const config = useMemo(
    () =>
      getTableConfig({
        onSorting,
        onPagination,
        onFilter,
        sorting,
        columnFilters,
        pageSize,
        pageIndex,
        totalCount,
        hasSubRows,
        defaultColumn,
      }),
    [onSorting, onPagination, onFilter, sorting, columnFilters, pageSize, pageIndex, totalCount, hasSubRows],
  );

  const {
    getHeaderGroups,
    getRowModel,
    getPageCount,
    setPageIndex,
    getCanNextPage,
    getCanPreviousPage,
    nextPage,
    previousPage,
    getState,
    setState,
    setPageSize,
    setColumnOrder,
    getFooterGroups,
    getAllLeafColumns,
    getPrePaginationRowModel,
  } = useReactTable({
    data,
    columns,
    ...config,
  });

  const { rows } = getRowModel();

  const table = (
    <StyledTable showPagination={showPagination} isLoading={loading}>
      <TableMeta error={error} loading={loading} dataLength={rows.length} />
      <div className="table">
        <TableHeader getHeaders={getHeaderGroups} />
        <TableBody rows={rows} SubRows={SubRows} />
        <TableFooter columns={columns} getFooterGroups={getFooterGroups} />
      </div>
    </StyledTable>
  );

  return (
    <TableProvider
      getPrePaginationRowModel={getPrePaginationRowModel}
      getState={getState}
      setState={setState}
      tableName={tableName}
      getAllLeafColumns={getAllLeafColumns}
    >
      <div tw="flex flex-col flex-[1] min-h-[500px]">
        {showPagination ? (
          <Pagination
            getPageCount={getPageCount}
            setPageIndex={setPageIndex}
            getState={getState}
            totalCount={totalCount}
            setPageSize={setPageSize}
            previousPage={previousPage}
            getCanPreviousPage={getCanPreviousPage}
            nextPage={nextPage}
            getCanNextPage={getCanNextPage}
            getAllLeafColumns={getAllLeafColumns}
            setColumnOrder={setColumnOrder}
            actions={actions}
          >
            {table}
          </Pagination>
        ) : (
          <>
            {actions && <div tw="pb-4 [align-self:flex-end]">{actions}</div>}
            {table}
          </>
        )}
      </div>
    </TableProvider>
  );
}
