import { BuiltInFilterFn, FilterFn, HeaderContext } from "@tanstack/react-table";
import { isAfter, isBefore, isEqual, parseISO } from "date-fns";
import { useEffect, useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { InputRaw, SelectRaw } from "common/form/renderFields";

import { GenericRecord } from "../types";

import { useDateRangeFilter } from "./DateRangeFilter";
import { useNumberFilter } from "./NumberFilter";

type FilterComponent<T extends GenericRecord = GenericRecord> = (props: HeaderContext<T, any>) => JSX.Element;

const getSelectBaseFilter: (isMulti?: boolean) => FilterComponent = (isMulti) =>
  function SelectBaseFilter({ column: { getFilterValue, setFilterValue, getFacetedUniqueValues } }) {
    const values = getFacetedUniqueValues();
    const options = useMemo(
      () =>
        values.size > 0
          ? Array.from(values.keys())
              .filter((v) => v !== undefined)
              .sort()
              .map((value) => ({ value, label: value }))
          : [],
      [values],
    );

    return (
      <SelectRaw
        tw="w-full"
        variant="sm"
        options={options}
        value={getFilterValue() as any}
        onChange={(v) => setFilterValue(v || undefined)}
        name="select"
        isClearable
        maxMenuHeight={200}
        closeMenuOnSelect
        placeholder="common.table.filter.filter"
        isMulti={isMulti}
      />
    );
  };

const TextFilter: FilterComponent = ({ column: { getFilterValue, setFilterValue } }) => (
  <InputRaw
    tw="w-full"
    type="text"
    variant="sm"
    onChange={(v) => setFilterValue(v.target.value)}
    name="select"
    value={getFilterValue() as any}
    placeholder="common.table.filter.filter"
  />
);

const SelectBooleanFilter: FilterComponent = ({ column: { getFilterValue, setFilterValue } }) => (
  <SelectRaw
    variant="sm"
    options={[
      { value: "true", label: "common.yes" },
      { value: "false", label: "common.no" },
    ]}
    onChange={(v) => setFilterValue(v?.toString())}
    name="select"
    value={getFilterValue() as any}
    isClearable
    maxMenuHeight={200}
    closeMenuOnSelect
    placeholder="common.table.filter.filter"
  />
);

const DateRangeFilter: FilterComponent = ({ column: { setFilterValue } }) => {
  const [{ from, to }, DateRange] = useDateRangeFilter("empty", {
    isClearable: true,
    allowSingleDate: true,
    placeholder: "common.table.filter.filter",
    variant: "sm",
  });

  useEffect(() => {
    setFilterValue({ from, to });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  return DateRange;
};

const NumberFilter: FilterComponent = ({ column: { setFilterValue } }) => {
  const [data, DateRange] = useNumberFilter("empty", {
    isClearable: true,
    placeholder: "common.table.filter.filter",
    variant: "sm",
  });

  useEffect(() => {
    setFilterValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return DateRange;
};

export const localFilters = {
  selectBooleanFilter: {
    meta: { filter: SelectBooleanFilter },
    filterFn: (row, columnId, filterValue) =>
      !filterValue ? true : (row.original[columnId] as any)?.toString() === filterValue,
  } as { meta: { filter: FilterComponent }; filterFn?: FilterFn<GenericRecord> },
  getSelectBaseFilter: ((isMulti) => ({
    meta: { filter: getSelectBaseFilter(isMulti) },
    filterFn: "arrIncludesSome",
  })) as (isMulti?: boolean) => { meta: { filter: FilterComponent }; filterFn?: BuiltInFilterFn },
  getTextBaseFilter: {
    meta: { filter: TextFilter },
  } as { meta: { filter: FilterComponent } },
  getDateRangeFilter: {
    meta: { filter: DateRangeFilter },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const { from, to } = filterValue;

      if (!from && !to) return true;

      const _from = new Date(from);
      const _to = new Date(to);

      const rowValue = parseISO(row.original[columnId] as any);

      return (
        (from ? isAfter(rowValue, _from) || isEqual(rowValue, _from) : true) &&
        (to ? isBefore(rowValue, _to) || isEqual(rowValue, _to) : true)
      );
    },
  } as { meta: { filter: FilterComponent }; filterFn?: FilterFn<GenericRecord> },
  getNumberFilter: {
    meta: { filter: NumberFilter },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const { type, from, to } = filterValue;

      if (!from && !to) return true;

      const _from = from || -Infinity;
      const _to = to || Infinity;

      const rowValue = row.original[columnId] as any;

      return type === "equal" ? from === Number(rowValue) : rowValue >= _from && rowValue <= _to;
    },
  } as { meta: { filter: FilterComponent }; filterFn?: FilterFn<GenericRecord> },
};
