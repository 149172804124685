import { Flag } from "phosphor-react";
import { useMemo } from "react";

import { ReactComponent as HouseSVG } from "assets/icons/House.svg";
import { ReactComponent as MapPinSVG } from "assets/icons/MapPin.svg";
import { NamesListTooltip } from "base/components";
import { CommonCells, Table, TableTypes } from "common/guideline";
import { useFindAllSmartLocationGroupsQuery } from "generated";
import { useTranslation } from "i18n";

import { RowActions } from "./RowActions";
import { LocationGroupData } from "./types";

const columns: TableTypes.TranslatedColumns<LocationGroupData> = (t) => [
  {
    header: t("administration.lg.name"),
    accessorKey: "name",
    aggregate: "uniqueCount",
    Aggregated: (d) => t("administration.lg.count", { count: d.value }),
  },
  {
    header: t("administration.lg.authorizedUsers"),
    accessorKey: "authorizedUsers",
    aggregate: "count",
    Aggregated: (d) => t("administration.lg.count", { count: d.value }),
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("administration.countries"),
    accessorKey: "countries",
    aggregate: (l) => l.flat(),
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      const value = getValue<LocationGroupData["countries"]>() ?? [];

      return (
        <NamesListTooltip count={value.length} names={value as string[]} Icon={Flag} data-test="countries">
          -
        </NamesListTooltip>
      );
    },
  },
  {
    header: t("administration.cities"),
    accessorKey: "cities",
    aggregate: (l) => l.flat(),
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      const value = getValue<LocationGroupData["cities"]>() ?? [];

      return (
        <NamesListTooltip count={value.length} names={value as string[]} Icon={HouseSVG} data-test="cities">
          -
        </NamesListTooltip>
      );
    },
  },
  {
    header: t("administration.lg.includedSites"),
    accessorKey: "locations",
    aggregate: (l) => l.flat(),
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      const value = getValue<LocationGroupData["locations"]>() ?? [];

      return (
        <NamesListTooltip
          count={value.length}
          names={value.map((v) => v?.name as string)}
          Icon={MapPinSVG}
          data-test="locations"
        >
          -
        </NamesListTooltip>
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

export const LocationGroupTable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const { previousData, data = previousData, loading, error } = useFindAllSmartLocationGroupsQuery();

  return (
    <Table
      tableName="siteGroups"
      columns={tColumns}
      data={(data?.findAllSmartLocationGroups || []) as LocationGroupData[]}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};
