export const en = {
  common: {
    dark: "Dark theme",
    light: "Light theme",
    system: "System theme",
    submit: "Submit",
    next: "Next",
    previous: "Previous",
    dismiss: "Dismiss",
    pin: "Pin this widget",
    unpin: "Unpin this widget",
    nodeId: "Node ID",
    status: "Status",
    default: "Default",
    home: "Home",
    btnHome: "Take me home",
    emptyTitle: "No results found",
    emptyDescription: "Try adjusting your search or filter to find what you're looking for",
    pageError: "Ooops",
    returnHome: "How you got here is a mystery. But you can click the button below to go back to the homepage.",
    all: "All",
    starting: "Starting",
    currency: "Currency",
    site: "Site",
    siteGroup: "Site group",
    searchResults: "Search results",
    personalized: "Personalized",
    insights: "Insights",
    machines: "Machines",
    edit: "Edit",
    viewDetails: "View details",
    title: "Title",
    department: "Department",
    role: "Role",
    myProfile: "My profile",
    alerts: "Alerts",
    language: "Language",
    signOut: "Sign out",
    helloPopover: "Hello <0>{{name}}</0>",
    helloProfile: "Hello, {{name}}",
    actionMessage: "What would you like to do?",
    profileDesc: "Here you can edit your settings",
    verified: "Verified",
    terms: "Terms and conditions",
    termsDesc: "These are the Terms and Conditions for using PayComplete™ products.",
    releaseNotes: "Release notes",
    releaseNotesDesc: "PayComplete™ Connect features, enhancements, fixes and other updates.",
    help: "Help",
    to: "To:",
    from: "From:",
    yes: "Yes",
    no: "No",
    confirm: "Confirm",
    select: "Select",
    rPeriod: "Reporting period",
    rDate: "Reporting date",
    notGrouped: "Not grouped",
    overview: "Overview",
    "404": "404",
    clipboard: "Text copied to clipboard",
    moreTypes: "{{count}} more",
    close: "Close",
    notAvailable: "N/A",
    table: {
      pagination: {
        previous: "Previous",
        next: "Next",
        results_zero: "No results found",
        results: "{{from}} - {{to}} of {{count}} results",
        perPage: "Results per page:",
        outOf: "out of",
        toggleAll: "Toggle all",
      },
      filter: {
        filter: "Filter",
        filters: "Filters",
        reset: "Reset",
        pastHour: "Past hour",
        pastDay: "Past day",
        past7Days: "Past 7 days",
        past30Days: "Past 30 days",
        customRange: "Custom range",
        equal: "Equal",
        between: "Between",
        equalValue: "Equal <0>{{value}}</0>",
        fromValue: "From <0>{{from}}</0>",
        toValue: "To <0>{{to}}</0>",
        betweenValue: "Between <0>{{from}}</0> and <0>{{to}}</0>",
        lowerTo: "Must be lower than to",
        higherFrom: "Must be higher than from",
        cannotBeEmpty: "Cannot be empty",
      },
      sort: {
        sort: "Sort",
        sortBy: "Sort by",
        done: "Done",
        reset: "Reset",
      },
      aggr: {
        sum: "{{value}} (total)",
        avg: "{{value}} (avg)",
      },

      noData: "No data",
      nothingShow: "Nothing to show",
      error: "Error",
      failedToFetch: "Failed to fetch data",
      groupBy: "Group by",
      downloadCsv: "Download as CSV",
      printReport: "Print report",
      export: "Export",
    },
    form: {
      noOptions: "No data",
      allSelected: "All {{count}} selected",
      selectedItems: "{{count}} selected",
      selectAll: "Select all",
      deselectAll: "Deselect all",
      err: {
        required: "Field required",
        invalidDate: "Invalid date",
      },
      upload: {
        image_one: "Upload image",
        image_other: "Upload images",
        dropHere: "Drop your files here or",
        browse: "Browse",
        removeAll: "Remove all",
        maxNumber: "You can't add more files",
        maxFileSize: "Selected file size exceed max file size",
        acceptType: "Selected file type is not allowed",
        resolution: "Selected file is not match desired resolution",
      },
    },
    remoteMgmt: {
      title: "Remote management (optional)",
      selectOperation: "Select operation",
      skip: "Skip this step",
      applyConfiguration: "Apply configuration",
      upgradeSoftware: "Upgrade software",
      now: "Now",
      specifyDateAndHour: "Specify date and hour",
      selectWhen: "Select when",
      dateAndTime: "Date and time",
    },
    paymentIntervals: {
      monthly: "Monthly",
      quarterly: "Quarterly",
      annually: "Annually",
      selectPaymenInterval: "Select payment interval",
    },
  },
};
