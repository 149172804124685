import { Confirm } from "base/components/Confirm";
import { useToast } from "common/guideline";
import { useActivateTenantOnTenantDbMutation, useDeactivateTenantOnTenantDbMutation } from "generated";
import { Trans } from "i18n";

type Props = {
  tenantId: string;
  name: string;
  status: any;
  onClose?: () => void;
};

export const ChangeTenantStatus: React.FC<Props> = ({ tenantId, name, status, onClose }) => {
  const [activateTenant, { loading: loadingActivate }] = useActivateTenantOnTenantDbMutation();
  const [deactivateTenant, { loading: loadingDeactivate }] = useDeactivateTenantOnTenantDbMutation();
  const loading = loadingActivate || loadingDeactivate;
  const isCurrentlyActive = status === "ACTIVE";
  const translationText = isCurrentlyActive ? "tenant.deactivate" : "tenant.activate";

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? `${translationText}.error` : `${translationText}.success`, {
        variant: error ? "error" : "success",
      });

    try {
      const mutation = isCurrentlyActive ? deactivateTenant : activateTenant;
      const result = await mutation({
        variables: { tenantId },
        ignoreResults: true,
      });
      const data = result.data ?? {};
      const dataResult =
        "deactivateTenantOnTenantDb" in data
          ? data.deactivateTenantOnTenantDb
          : "activateTenantOnTenantDb" in data
          ? data.activateTenantOnTenantDb
          : null;

      if (dataResult) onClose?.();
      showToast(!dataResult?.nodeId);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <Confirm
      desc={`${translationText}.title`}
      rejectText={`${translationText}.close`}
      confirmText={`${translationText}.submit`}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
      type={isCurrentlyActive ? "warning" : "info"}
    >
      <Trans i18nKey={`${translationText}.description`} values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </Confirm>
  );
};
