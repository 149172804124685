import { TKeys } from "i18next";
import { useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { SchemaForm } from "common/form";
import {
  CustomRenderFields,
  FieldBoxVariantsProp,
  TextLabel,
  TextPlaceholder,
  customRender,
  fieldBoxStyle,
  fieldBoxVariants,
  fieldWrapperStyle,
} from "common/form/renderFields";
import { Button } from "common/guideline/components/Button";
import { Popover } from "common/guideline/components/Popover";
import { Text } from "common/guideline/components/Text";
import { Trans } from "i18n";

type RangeType = "equal" | "between";

type NumberRange = {
  from: string;
  to: string;
  type: RangeType;
};

const typeOptions: Array<{ value: RangeType; label: string }> = [
  { value: "equal", label: "common.table.filter.equal" },
  { value: "between", label: "common.table.filter.between" },
];

const Wrapper = styled.div`
  ${tw`space-y-2 py-2`}
`;

const Box = styled.div<FieldBoxVariantsProp>`
  ${fieldBoxStyle}
  ${fieldBoxVariants}
  ${tw`flex justify-between items-center`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "radio",
    name: "type",
    values: typeOptions,
    variant: "sm",
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "condition",
        when: "type",
        is: (value) => value === "equal",
        fields: [
          {
            type: "number",
            name: "from",
            parse: (v) => (v ? Number(v) : v),
            validate: { type: "number", required: true },
          },
        ],
      },
      {
        type: "condition",
        when: "type",
        is: (value) => value === "between",
        fields: [
          {
            type: "number",
            name: "from",
            label: "From",
            parse: (v) => (v ? Number(v) : v),
            validate: (value, formData) =>
              !formData.values.to
                ? { type: "number", required: true }
                : value && formData.values.to <= value
                ? "common.table.filter.lowerTo"
                : null,
          },
          {
            type: "number",
            name: "to",
            label: "To",
            parse: (v) => (v ? Number(v) : v),
            validate: (value, formData) =>
              !formData.values.from
                ? { type: "number", required: true }
                : value && formData.values.from >= value
                ? "common.table.filter.higherFrom"
                : null,
          },
        ],
      },
    ],
  },
];

type Props = FieldBoxVariantsProp & {
  onSubmit: (range: NumberRange) => void;
  data: NumberRange;
  label?: string;
  placeholder?: TKeys;
  isClearable?: boolean;
};

const NumberFilter: React.FC<Props> = ({ onSubmit, data, label, placeholder, isClearable, variant }) => (
  <Popover
    auto
    overflowContainer
    tw="max-w-full"
    content={(close) => (
      <div tw="min-w-[200px]">
        <SchemaForm<NumberRange>
          customRender={customRender}
          fields={fields}
          initial={data}
          onSubmit={(d) => {
            onSubmit(d);
            close();
          }}
          SubmitComponent={() => (
            <div tw="pt-3 flex justify-end">
              <Button type="submit" variant={["primary", "sm"]} data-test="submitNumberFilter">
                <Text tKey="common.confirm" />
              </Button>
            </div>
          )}
        />
      </div>
    )}
  >
    <div css={fieldWrapperStyle}>
      {label && <TextLabel>{label}</TextLabel>}
      <Box variant={variant}>
        <div>
          {data.type === "equal" ? (
            data.from ? (
              <Trans i18nKey="common.table.filter.equalValue" values={{ value: data.from }}>
                {[<b key="1" />]}
              </Trans>
            ) : (
              <TextPlaceholder tKey={placeholder} />
            )
          ) : (
            <Trans
              i18nKey={`common.table.filter.${
                data.from && data.to ? "betweenValue" : data.from ? "fromValue" : "toValue"
              }`}
              values={data}
            >
              {[<b key="1" />]}
            </Trans>
          )}
        </div>

        {isClearable && (data.from || data.to) ? (
          <span
            onClick={(e) => {
              e.stopPropagation();
              onSubmit({ type: "equal", from: "", to: "" });
            }}
          >
            &times;
          </span>
        ) : null}
      </Box>
    </div>
  </Popover>
);

export const useNumberFilter = (
  type: RangeType | "empty" = "equal",
  options?: Omit<Props, "onSubmit" | "data">,
): [NumberRange, JSX.Element] => {
  const [state, setState] = useState<NumberRange>(() => ({
    type: type === "empty" ? "equal" : type,
    from: "",
    to: "",
  }));

  return [state, <NumberFilter key="numberRange" onSubmit={setState} data={state} {...options} />];
};
