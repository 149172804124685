export const uniqueID = () => Math.random().toString(16).slice(-4);

export const getInitials = (fullName: string): string =>
  fullName
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();

export const withDefault = (value: any, defaultValue: string | number = "-") => value ?? defaultValue;

export const strictString = (v: any, defaultStr = ""): string => (typeof v === "string" ? v : defaultStr);

export const stringToPossibleFloat = (v: string | undefined) => {
  const withDots = v?.replace(/,/g, ".");
  return !withDots || isNaN(withDots as any) ? withDots : Number(parseFloat(withDots).toFixed(2));
};

export const stringToPossibleInt = (v: string | undefined) => {
  const int = v?.split(/,|\./g)?.[0];
  return !int || isNaN(int as any) ? int : Number(int);
};

export const makeSorter = (name: string) => (a: any, b: any) =>
  String(a[name] ?? "").localeCompare(String(b[name] ?? ""));
