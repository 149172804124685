import { TKeys } from "i18next";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { OnSubmit, SchemaForm, TForm } from "common/form";
import { BaseOption, CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { omitByKey, omitTypenames } from "common/helpers";
import { MessageType, RulesDto } from "generated";
import { getStorageTenantId } from "tenant/context/getStorageTenantId";

import { filtersField } from "./FiltersField";
import { getFiltersToSend, getInitialFormFilters } from "./helpers";
import { recipientsField } from "./RecipientsField";

export type AlertFormData = RulesDto;
type AlertFormInternal = Pick<AlertFormData, "nodeId" | "name" | "recipients"> & {
  messageType?: MessageType;
  machines?: string[];
  locations?: string[];
  filters?: any;
};

const Wrapper = styled.div`
  ${tw`grid gap-6 sm:grid-cols-2`}
`;

const messagesTypes: BaseOption[] = [
  {
    label: "alerts.messageTypes.machines",
    value: "",
    options: [
      { value: "ERROR", label: "alerts.messageTypes.ERROR" },
      { value: "MACHINE_CONTENT_CHANGE", label: "alerts.messageTypes.MACHINE_CONTENT_CHANGE" },
      { value: "MACHINE_ONLINE_STATUS_CHANGE", label: "alerts.messageTypes.MACHINE_ONLINE_STATUS_CHANGE" },
      { value: "SYSTEM_STATUS", label: "alerts.messageTypes.SYSTEM_STATUS" },
    ],
  },
  {
    label: "alerts.messageTypes.TRANSACTION",
    value: "",
    options: [{ value: "TRANSACTION", label: "alerts.messageTypes.TRANSACTION" }],
  },
];

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "alerts.name",
        validate: { type: "string", required: true },
      },
      {
        type: "select",
        name: "messageType",
        label: "alerts.messageType",
        options: messagesTypes,
        validate: { type: "string", required: true },
      },

      filtersField,
      recipientsField,
    ],
  },
];

type Props = {
  onSubmit: (values: AlertFormData, a: TForm<AlertFormInternal>) => any;
  submitLabel: TKeys;
  initial?: AlertFormData;
};

const beforeSend =
  (onSubmit: Props["onSubmit"]): OnSubmit<AlertFormInternal> =>
  ({ name, recipients, messageType, filters, nodeId, machines = [], locations = [] }, form) =>
    onSubmit(
      {
        ...(nodeId && { nodeId }),
        tenantId: getStorageTenantId(),
        name,
        recipients,
        filters: {
          messageType,
          filterType: "MESSAGE_TYPE",
          operationType: "MESSAGE_TYPE_FILTER",
          child: getFiltersToSend(filters || {}, machines, locations, form.data || {}),
        },
      },
      form,
    );

const getInitial = (d: AlertFormData | undefined): AlertFormInternal | undefined =>
  d
    ? {
        nodeId: d.nodeId,
        name: d.name,
        recipients: omitByKey("nodeId")(omitTypenames(d.recipients)),
        messageType: d.filters?.messageType as any,
        ...getInitialFormFilters(d?.filters?.child || null),
      }
    : undefined;

export const AlertForm: React.FC<Props> = ({ onSubmit, submitLabel, initial }) => (
  <SchemaForm<AlertFormInternal>
    fields={fields}
    onSubmit={beforeSend(onSubmit)}
    initial={getInitial(initial)}
    customRender={customRender}
    SubmitComponent={() => (
      <div tw="flex justify-between">
        <Button
          tw="mt-6"
          variant="side"
          onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "ALERTS" })}
          data-test="goBack"
        >
          <Text tKey="alerts.goBack" />
        </Button>
        <Button type="submit" tw="mt-6" data-test="submitForm">
          <Text tKey={submitLabel} />
        </Button>
      </div>
    )}
  />
);
