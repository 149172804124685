import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { Breadcrumb, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import {
  FindAllMachineUsersDocument,
  FindMachineUserByUserIdQuery,
  useFindMachineUserByUserIdQuery,
  useUpdateMachineUserMutation,
} from "generated";

import { MachineUserForm, MachineUserFormData } from "./MachineUserCreate/MachineUserForm";

export type MachineUserUpdateParams = { userId: string };

const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "MACHINE_USERS",
    text: "mu.title_other",
  },
  {
    route: "MACHINE_USER_UPDATE",
    text: "mu.update",
  },
];

type InitialValues = MachineUserFormData | undefined;

const getInitialValues = ({
  findMachineUserByUserId: MachineUser,
}: FindMachineUserByUserIdQuery | undefined = {}): InitialValues => {
  return MachineUser
    ? {
        name: MachineUser.name as string,
        userId: MachineUser.userId as string,
        roleName: MachineUser.roleName as string,
        nodeId: MachineUser.nodeId as string,
        validFrom: MachineUser.validFrom as string,
        validUntil: MachineUser.validUntil as string,
        cardIdentifier: MachineUser.cardIdentifier as string,
        nbrOfLogins: MachineUser.nbrOfLogins as number,
      }
    : undefined;
};

export const UpdateMachineUserScreen = () => {
  const { userId = "" } = useParams<MachineUserUpdateParams>();
  const [updateMachineUser] = useUpdateMachineUserMutation();
  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues,
    useFindMachineUserByUserIdQuery({
      variables: { userId },
      skip: !userId,
      returnPartialData: true,
    }),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="mu.update" subtitle="mu.updateDescription">
      {loading ? null : (
        <MachineUserForm
          updateForm
          submitLabel="mu.update"
          initial={mappedData}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSubmit={({ confirmPin, unlimitedNoLogins, nodeId, ...input }) => {
            updateMachineUser({
              variables: { nodeId, input },
              ignoreResults: true,
              update: (cache) => evictQuery(FindAllMachineUsersDocument, cache),
            })
              .then(() => {
                useToast.actions.show("mu.updateSuccess", { variant: "success" });
                navigateTo({ route: "MACHINE_USERS" });
              })
              .catch(() => useToast.actions.show("mu.updateApiError", { variant: "error" }));
          }}
        />
      )}
    </PageLayout>
  );
};
