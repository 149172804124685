import tw from "twin.macro";
import "styled-components/macro";

import { Button, Container, FullLine, Line, Text } from "common/guideline";

type Props = {
  data: any;
  onClick?: HTMLElement["click"] | undefined;
};

export const SoftwarePackageParts: React.FC<Props> = ({ data, onClick }) => (
  <>
    <FullLine />
    <Container variant="card" tw="text-sm">
      <Text variant="heading" tw="block mb-2" tKey="administration.sp.info" />
      <div tw="grid grid-cols-[max-content 1fr] [grid-gap:12px] ">
        <Line tw="col-span-full" />
        <Text tKey="administration.sp.label" />
        <span>{data?.label}</span>
        <Line tw="col-span-full" />
        <Text tKey="administration.sp.version" />
        <span>{data?.version}</span>
        <Line tw="col-span-full" />
        <Text tKey="administration.sp.revision" />
        <span>{data?.revision}</span>
        <Line tw="col-span-full" />
        <Text tKey="administration.sp.file" />
        <div tw="flex justify-between truncate">
          <span tw="truncate">{data?.fileName}</span>
          {onClick && (
            <Button onClick={onClick} tw="ml-2" variant={["sm", "side"]} data-test="changeFile">
              <Text tKey="administration.sp.changeFile" />
            </Button>
          )}
        </div>
      </div>
    </Container>
  </>
);
