import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ActionButton } from "base/components";
import { useModalComponent } from "common/guideline";

import { DeleteMachine } from "../../components";

import { RowMachineViewDataProps } from "./types";

export const RowActions: React.FC<RowMachineViewDataProps> = ({ row }) => {
  const [show] = useModalComponent({
    Component: (
      <DeleteMachine nodeId={row.original.machine?.nodeId as string} name={row.original.machine?.name as string} />
    ),
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
      <Link route="MACHINE_EDIT" params={{ nodeId: row.original.machine?.nodeId as string }}>
        <ActionButton.Edit />
      </Link>
    </div>
  );
};
