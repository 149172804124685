import tw from "twin.macro";
import "styled-components/macro";

import { performRemoteMgmtOperation } from "administration/helpers";
import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { Breadcrumb, Container, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import {
  FindAllMachineGroupsDocument,
  FindMachineGroupByNodeIdQuery,
  FindMachinesEligibleToAttachToMgDocument,
  useFindMachineGroupByNodeIdQuery,
  useUpdateMachineGroupMutation,
} from "generated";

import { MachineGroupForm, MachineGroupFormData } from "./components/MachineGroupForm";

export type MachineGroupUpdateParams = { nodeId: string };

const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "MACHINE_GROUPS",
    text: "administration.mg.title",
  },
  {
    route: "MACHINE_GROUP_UPDATE",
    text: "administration.mg.update",
  },
];

type InitialValues = MachineGroupFormData | undefined;

const getInitialValues = ({
  findMachineGroupByNodeId: MachineGroup,
}: FindMachineGroupByNodeIdQuery | undefined = {}): InitialValues => {
  return MachineGroup
    ? {
        name: MachineGroup.name as string,
        machineNodeIds: MachineGroup.machineNodeIds as string[],
        nodeStatus: MachineGroup.nodeStatus,
      }
    : undefined;
};

export const UpdateMachineGroupScreen = () => {
  const { nodeId = "" } = useParams<MachineGroupUpdateParams>();
  const [updateMachineGroup] = useUpdateMachineGroupMutation();
  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues,
    useFindMachineGroupByNodeIdQuery({
      variables: { nodeId },
      skip: !nodeId,
      returnPartialData: true,
    }),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="administration.mg.update"
      subtitle="administration.mg.updateDescription"
    >
      <Container variant="pageWrapper">
        {loading ? null : (
          <MachineGroupForm
            submitLabel="administration.mg.update"
            initial={mappedData}
            onSubmit={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              timeOption,
              remoteMgmtOption,
              templateNodeId,
              softwarePackageNodeId,
              scheduleInfo,
              ...input
            }) => {
              updateMachineGroup({
                variables: { nodeId, input },
                ignoreResults: true,
                update: (cache) => {
                  evictQuery(FindAllMachineGroupsDocument, cache);
                  evictQuery(FindMachinesEligibleToAttachToMgDocument, cache);
                },
              })
                .then((v) => {
                  if (!v.data?.updateMachineGroup?.nodeId) throw new Error();

                  useToast.actions.show("administration.mg.updateSuccess", { variant: "success" });

                  performRemoteMgmtOperation(
                    remoteMgmtOption,
                    v.data?.updateMachineGroup?.machineNodeIds,
                    templateNodeId,
                    softwarePackageNodeId,
                    scheduleInfo,
                  );

                  navigateTo({ route: "MACHINE_GROUPS" });
                })
                .catch(() => useToast.actions.show("administration.mg.updateApiError", { variant: "error" }));
            }}
          />
        )}
      </Container>
    </PageLayout>
  );
};
