import { Row, RowModel } from "@tanstack/react-table";
import { TKeys } from "i18next";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as FileCsvSVG } from "assets/icons/FileCsv.svg";
import { Button, Tooltip, accessors } from "common/guideline";
import { downloadFile, jsonToCsv, strictString } from "common/helpers";

import { CommonCells } from "./common";
import { useTableDataContext } from "./TableContext";
import { ColumnExtenedDef } from "./types";

type GetCSVFromColumnsArgs<T extends Record<string, any>> =
  | { data: T[]; mapColumn?: (column: ColumnExtenedDef<T>) => ColumnExtenedDef<T> }
  | {
      additionalData?: Partial<T>[];
      mapRow?: (row: T, subRow?: any) => any;
      withSubRows?: boolean;
      mapColumn?: (column: ColumnExtenedDef<T>) => ColumnExtenedDef<T>;
    };

export type CustomGetCsvFn<T extends Record<string, any> = any> = (
  getOptions: (options: GetCSVFromColumnsArgs<T>) => string,
) => string | Promise<string>;

type Props = {
  title: TKeys;
  disabled?: boolean;
  getCsv?: true | "withSubRows" | CustomGetCsvFn<any>;
};

type DataWithRow<T> = T & { __cellRow: Row<T> };

const withRow = <T extends Record<any, any>>(data: T, __cellRow: Row<T>): DataWithRow<T> => ({
  ...data,
  __cellRow,
});

const getCsvFromTableColumns =
  <T extends Record<string, any>>(getPrePaginationRowModel: () => RowModel<any>) =>
  (options: GetCSVFromColumnsArgs<T> = {}) => {
    const rows = getPrePaginationRowModel().rows;
    const columns = rows[0].getVisibleCells().map((c) => c.column.columnDef) as ColumnExtenedDef<T>[];

    const [headers, values] = columns.reduce(
      (acc, current) => {
        const curr = options.mapColumn?.(current) || current;

        if (curr.id !== CommonCells.expander.id) {
          acc[0].push(strictString(curr.meta?.csv?.header || curr.meta?.headerName || curr.header));
          acc[1].push(
            curr.meta?.csv?.accessorFn
              ? // eslint-disable-next-line
                // @ts-ignore
                (d) => curr.meta?.csv?.accessorFn(d, d.__cellRow?.index ?? 0)
              : curr.meta?.csv?.accessorKey ||
                  ("accessorFn" in curr
                    ? curr.accessorFn
                      ? (d) => curr.accessorFn(d, d.__cellRow?.index ?? 0)
                      : strictString(curr.id)
                    : "accessorKey" in curr
                    ? (curr.accessorKey as any) || strictString(curr.id)
                    : strictString(curr.id)),
          );
        }

        return acc;
      },
      [[], []] as [string[], (string | keyof T | ((d: DataWithRow<T>) => any))[]],
    );

    const data: DataWithRow<T>[] =
      "data" in options
        ? options.data
        : [
            ...(options.withSubRows
              ? rows.flatMap((r) =>
                  r.subRows?.map((s) =>
                    withRow(
                      options.mapRow ? options.mapRow(r.original, s.original) : { ...r.original, ...s.original },
                      r,
                    ),
                  ),
                )
              : rows.map((r) => withRow(options.mapRow ? options.mapRow(r.original) : r.original, r))),
            ...(options.additionalData || []),
          ];

    return jsonToCsv(data, values, headers);
  };

export const TableDownload: React.FC<Props> = ({ title, getCsv, disabled }) => {
  const { t } = useTranslation();
  const { getPrePaginationRowModel } = useTableDataContext();

  return (
    <div>
      {getCsv && (
        <Tooltip content={t("common.table.downloadCsv")} data-test="downloadAsCsvInfo">
          <Button
            disabled={disabled}
            variant={["mdSquare", "ghost"]}
            data-test="downloadAsCsv"
            onClick={async () =>
              downloadFile(
                `${t(title)} ${accessors.date(new Date().toISOString(), t, "P")}`,
                "text/csv",
                await (typeof getCsv === "function"
                  ? getCsv(getCsvFromTableColumns(getPrePaginationRowModel))
                  : getCsvFromTableColumns(getPrePaginationRowModel)({ withSubRows: getCsv === "withSubRows" })),
              )
            }
          >
            <FileCsvSVG width={36} height={36} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
