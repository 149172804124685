import { useEffect, useMemo, useState } from "react";

import { defaultNumber } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { GenerateCashOperationsReportQuery, useGenerateCashOperationsReportQuery } from "generated";

const defaultSummary = {
  availableForDispense: 0,
  availableToBank: 0, // will be displayed as "Not dispensable" in UI
  NOTE: 0,
  COIN: 0,
  CLAIMED_VALUE: 0,
  COUNTERFEIT_NOTES: 0,
  SUSPECT_NOTES: 0,
  CHECK: 0,
  VOUCHER: 0,
};

export type CashOperationsSummaryData = typeof defaultSummary;

export type CashOperationsContentData = NonNullable<
  GenerateCashOperationsReportQuery["generateCashOperationsReport"]
>[0];

const mapResult = ({ generateCashOperationsReport }: GenerateCashOperationsReportQuery | undefined = {}) => {
  const contentPerCurrency = (generateCashOperationsReport || []).reduce(
    (acc, curr) => Object.assign(acc || {}, { [curr?.currency || ""]: curr }),
    {} as { [currency: string]: CashOperationsContentData },
  );

  return { contentPerCurrency, currencies: Object.keys(contentPerCurrency).map((v) => ({ value: v, label: v })) };
};

export const useCashOperationsData = (nodeId: string, date?: string) => {
  const [currency, setCurrency] = useState<string>();
  const [mapped, queryResult] = useMappedQuery(
    mapResult,
    useGenerateCashOperationsReportQuery({
      skip: !nodeId,
      variables: { nodeId, date },
    }),
  );
  const content = currency ? mapped?.contentPerCurrency?.[currency] : undefined;
  const summary = useMemo<CashOperationsSummaryData>(
    () =>
      content?.totals?.reduce(
        (acc, curr) => {
          if (curr?.key) acc[curr.key] = defaultNumber(Number(curr.value || 0));
          return acc;
        },
        { ...defaultSummary },
      ) || defaultSummary,
    [content?.totals],
  );

  useEffect(() => {
    if (mapped?.currencies?.length && (!currency || !mapped.currencies.some((c) => c.value === currency))) {
      setCurrency(mapped.currencies[0].value);
    }
  }, [mapped?.currencies, currency]);

  return {
    content,
    summary,
    currencies: mapped?.currencies,
    currency,
    setCurrency,
    queryResult,
  };
};
