import { useEffect, useState } from "react";

export function useLocalStorage<T = any>(defaultValue: T, key: string): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState(() => {
    const currentValue = window.localStorage.getItem(key);
    return currentValue !== null ? JSON.parse(currentValue) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
