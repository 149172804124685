import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { ActionButton } from "base/components";
import { TableTypes, useModalComponent } from "common/guideline";

import { DeleteUser } from "../components/DeleteUser";
import { useUserUpdate } from "../UserUpdate/UserUpdate";

import { IsUserData } from "./types";

export const RowActions: React.FC<TableTypes.VerticalHeaderProps<IsUserData>> = ({ row }) => {
  const [showUpdate] = useUserUpdate(row.original as WithoutNullableKeys<IsUserData>);
  const [show] = useModalComponent({
    Component: <DeleteUser userName={row.original.userName as string} />,
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
      <ActionButton.Edit onClick={() => showUpdate()} />
    </div>
  );
};
