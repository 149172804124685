import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { ActionButton } from "base/components";
import { useModalComponent } from "common/guideline";

import { DeleteSoftwarePackage } from "./DeleteSoftwarePackage";
import { UpdateSoftwarePackage } from "./SoftwarePackagesForm";
import { RowSoftwarePackageDataProps, SoftwarePackageFetched } from "./types";

export const RowActions: React.FC<RowSoftwarePackageDataProps> = ({ row }) => {
  const [showUpdate] = UpdateSoftwarePackage(row.original as WithoutNullableKeys<SoftwarePackageFetched>);
  const [show] = useModalComponent({
    Component: <DeleteSoftwarePackage nodeId={row.original.nodeId as string} name={row.original.name as string} />,
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
      <ActionButton.Edit onClick={() => showUpdate()} />
    </div>
  );
};
