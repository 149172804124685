export const en = {
  user: {
    title_one: "User",
    title_other: "Users",
    desc: "User management - create, edit and delete admin users",
    userId: "User ID",
    name: "Name",
    lastLogin: "Last login",
    created: "Created",
    createdBy: "Created by",
    userType: "User type",
    groups: "Groups",
    roles: "Roles",
    noRoles: "No roles",
    permissions: "Permissions",
    createUser: "Create user",
    createUserDesc: "Create a new user - assign a username, name and roles.",
    deleteUser: "Delete user",
    deleteUserDesc: 'User will be deleted, click "Delete" to proceed.',
    createdDate: "Created",
    lastModified: "Last modified",
    firstName: "First name",
    lastName: "Last name",
    createSuccess: "User successfully saved",
    createError: "An error occurred when saving user",
    updateSuccess: "User successfully updated",
    updatedPasswordSuccess: "Successfully updated users password",
    updatedPasswordError: "An error occurred when updating users password",
    updateError: "An error occurred when updating user",
    deleteSuccess: "User successfully deleted",
    deleteError: "An error occurred when deleting user",
    update: "Update user",
    userName: "User name",
    password: "Password",
    updatePassword: "Update password",
    passwordRequired: "Password required",
    delete: "Delete",
    allMachines: "All machines",
    allSites: "All sites",
    allSiteGroups: "All site groups",
    allTenants: "All tenants",
    email: "Email",
    settings: {
      appearance: "Appearance",
    },
    identity: {
      title: "Identity",
    },
    access: {
      accessLevel: "Access level",
      admin: "Admin",
      domain: "Domain",
      permissions: "Permissions",
      Application_admin: "Admin",
      Application_nonadmin: "Non admin",
      UserRole_global_admin: "Global admin",
      UserRole_global_support: "Global support",
      UserRole_admin: "User admin",
      UserRole_user: "User",
      Permission_tenant_ALL: "All tenants",
      Permission_machine_ALL: "All machines",
      // Some misspelling on the backend or in the database, so we need both Permission_machine_ALL and Permission_machine_AL
      Permission_machine_AL: "All machines",
      Permission_site_ALL: "All sites",
      Permission_siteGroup_ALL: "All site groups",
      everyone: "Everyone",
    },
  },
};
