import { Navigate as NavigatePath } from "react-router-dom";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { Navigate, history, navigateTo } from "appRouting";
import { ReactComponent as LogoSVG } from "assets/img/Login.svg";
import { useAuth } from "auth/hooks";
import { SchemaForm } from "common/form";
import { CustomRenderFields, TextLabel, customRender } from "common/form/renderFields";
import { LoadingButton, Text } from "common/guideline";

const Wrapper = styled.div`
  ${tw`sm:px-6 lg:px-8 flex-col flex items-start justify-end min-h-screen py-12`}
  background-image: url("img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
`;

const FieldWrapper = styled.div`
  ${tw`pb-4 space-y-4`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: FieldWrapper,
    fields: [
      {
        type: "text",
        name: "username",
        label: "user.userName",
        validate: { type: "string", required: true },
      },
      {
        type: "password",
        name: "password",
        label: "user.password",
        validate: { type: "string", required: true },
      },
    ],
  },
];

type FormData = {
  username: string;
  password: string;
};

export const Login = () => {
  const [error, loading, isAuthenticated] = useAuth((s) => [s.error, s.loading, s.isAuthenticated]);
  const previousPath = history.getPreviousRoute()?.location?.pathname;

  return isAuthenticated ? (
    previousPath ? (
      <NavigatePath to={previousPath} replace />
    ) : (
      <Navigate route="HOME" replace />
    )
  ) : (
    <Wrapper>
      <div tw="sm:max-w-md w-full">
        <div tw="flex justify-start w-auto text-white mb-4">
          <LogoSVG />
        </div>
      </div>
      <div tw="sm:max-w-md w-full">
        <div tw="sm:rounded-lg sm:px-10 bg-gray-1 px-4 py-8 shadow">
          <SchemaForm<FormData>
            fields={fields}
            customRender={customRender}
            onSubmit={(data) => useAuth.actions.logIn(data).then((success) => success && navigateTo({ route: "HOME" }))}
            SubmitComponent={() => (
              <div tw="flex items-center justify-end gap-2">
                <TextLabel error>{error?.tError || ""}</TextLabel>
                {/* TODO: uncomment code below once we get valid reset password link from dev ops team */}
                {/* <a href={CONFIG.resetPassword} target="_blank" rel="noreferrer">
                  <Text variant={["label", "link"]} tKey="auth.resetPassword" />
                </a> */}
                <LoadingButton type="submit" disabled={loading} isLoading={loading}>
                  <Text tKey="auth.login" />
                </LoadingButton>
              </div>
            )}
          />
        </div>
      </div>
    </Wrapper>
  );
};
