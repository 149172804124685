import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { WithVariants, applyVariant } from "./helpers";

const variants = {
  vertical: tw`h-6 w-0.5`,
  horizontal: tw`h-[1px] w-full`,
};

export const Line = styled.div<WithVariants<typeof variants>>`
  ${tw`bg-gray-3`}
  ${applyVariant(variants, "horizontal")}
`;

export const FullLine = styled(Line)`
  ${tw`col-span-full`}
`;
