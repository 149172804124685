import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, TypePolicy } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { useAuth } from "auth/hooks";
import { CONFIG } from "common/config";
import { getStorageTenantId } from "tenant/context/getStorageTenantId";

const errorLink = onError(({ operation }) => {
  const { response } = operation.getContext();
  // handle invalid token or other auth errors
  if (response?.status === 401) {
    useAuth.actions.logOut();
  }
});

const operationsWithoutTenantHeader = [
  "findAllTenantDbsNames",
  "findAllTenants",
  "findTenantByTenantId",
  "findAllMachineTypePatterns",
  "findMyTenantsMetadata",
  "findTenant",
  "activateTenantOnTenantDb",
  "deactivateTenantOnTenantDb",
  "findTenantFeatures",
  "updateTenantFeatures",
];

const authLink = setContext(({ operationName = "" }, { headers }) => {
  const token = useAuth.actions.getToken();

  return {
    headers: {
      [CONFIG.tenantHeaderName]: operationsWithoutTenantHeader.includes(operationName)
        ? ""
        : getStorageTenantId() ?? "",
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = new HttpLink({ uri: CONFIG.gqlPath });
const onlineStatusLink = new HttpLink({ uri: CONFIG.gqlOnlineStatusPath });
const alertsLink = new HttpLink({ uri: CONFIG.gqlAlertsPath });

const onlineStatusOperations = [
  "getMachinesOnlineStatus",
  "getMachinesOnlineStatusHistory",
  "getLatestOnlineStatusForMachine",
];

const alertsOperations = [
  "getSupportedFilters",
  "getAllRules",
  "getRulesByNodeId",
  "findAlertsByCriteria",
  "createRules",
  "deleteRules",
  "updateRules",
];

const defaultTypePolicy: TypePolicy = {
  keyFields: ["nodeId"],
};

export const client = new ApolloClient({
  // use onlineStatusLink if query is for online status service
  link: errorLink.concat(
    authLink.split(
      (o) => onlineStatusOperations.includes(o.operationName),
      onlineStatusLink,
      ApolloLink.split((o) => alertsOperations.includes(o.operationName), alertsLink, link),
    ),
  ),
  cache: new InMemoryCache({
    typePolicies: {
      CoDLicenseDtoOut: defaultTypePolicy,
      CorrectionReasonDtoOut: defaultTypePolicy,
      ExtendedDeploymentDtoOut: defaultTypePolicy,
      ExtendedSoftwareInstallationDtoOut: defaultTypePolicy,
      LocationDtoOut: defaultTypePolicy,
      MachineDtoOut: defaultTypePolicy,
      MachineUserDtoOut: defaultTypePolicy,
      MachineUserGroupDtoOut: defaultTypePolicy,
      RecipientData: defaultTypePolicy,
      RmDeviceDto: defaultTypePolicy,
      Rule: defaultTypePolicy,
      SmartLocationGroupDtoOut: defaultTypePolicy,
      SoftwarePackageDtoOut: defaultTypePolicy,
      TemplateDtoOut: defaultTypePolicy,
      TenantDtoOut: { keyFields: ["tenantId"] },
    },
    possibleTypes: {
      AbstractFilter: [
        "BiNodeFilter",
        "CashAmountFilter",
        "CountOperationFilter",
        "DefaultFilter",
        "DurationFilter",
        "MessageFieldFiltering",
        "MessageTypeFilter",
        "NoParamFilter",
        "RatioFilter",
        "TimeFilter",
      ],
    },
  }),
});
