import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";
import { Table, TableTypes, accessors } from "common/guideline";
import { useGetDeploymentsForMachineNodeIdQuery } from "generated";
import { useTranslation } from "i18n";
import { DeploymentFetched } from "zts/types";

const getColumns: TableTypes.TranslatedColumns<DeploymentFetched> = (t) => [
  {
    header: t("zts.latestStatusChange"),
    accessorFn: (v) => accessors.date(v.statusChangedDate, t),
    minSize: 170,
  },
  {
    header: t("zts.name"),
    width: 100,
  },
  {
    header: t("zts.remoteConfiguration.description"),
  },
  {
    header: t("zts.remoteConfiguration.scheduledAt"),
    minSize: 170,
  },
  {
    header: t("zts.executedBy"),
    accessorKey: "createdBy",
    minSize: 170,
  },
  {
    header: t("zts.result"),
    accessorKey: "state",
    minSize: 170,
  },
];

const ConfigurationDeploymentsTable = ({ machine }) => {
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGetDeploymentsForMachineNodeIdQuery({
    variables: {
      machineNodeId: machine.nodeId,
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const rows = (data?.getDeploymentsForMachineNodeId || []) as DeploymentFetched[];

  return (
    <Table<DeploymentFetched>
      tableName="configurationDeployments"
      columns={columns}
      data={rows}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};

export const ConfigurationDeployments = ({ machine }) => (
  <PageLayout
    title="zts.remoteConfiguration.configurationHistory"
    subtitle="zts.remoteConfiguration.configurationHistoryDesc"
  >
    <ConfigurationDeploymentsTable machine={machine} />
  </PageLayout>
);
