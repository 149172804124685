import { endOfDay, isAfter, isBefore, parseISO, subDays, subHours } from "date-fns";
import { TKeys } from "i18next";
import { useMemo, useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { SchemaForm } from "common/form";
import {
  CustomRenderFields,
  FieldBoxVariantsProp,
  TextLabel,
  TextPlaceholder,
  customRender,
  fieldBoxStyle,
  fieldBoxVariants,
  fieldWrapperStyle,
} from "common/form/renderFields";
import { Button } from "common/guideline/components/Button";
import { Popover } from "common/guideline/components/Popover";
import { Text } from "common/guideline/components/Text";
import { getDate } from "common/helpers";

type Duration = "hour" | "day" | "7days" | "30days" | "custom";

type DateRangeFormatted = {
  from: string;
  to: string;
  duration: Duration;
};

type DateRange = { to: Date; from: Date };

const durationOptions: Array<{ value: Duration; label: TKeys }> = [
  { value: "hour", label: "common.table.filter.pastHour" },
  { value: "day", label: "common.table.filter.pastDay" },
  { value: "7days", label: "common.table.filter.past7Days" },
  { value: "30days", label: "common.table.filter.past30Days" },
  { value: "custom", label: "common.table.filter.customRange" },
];

const getDatesByDuration: Record<Exclude<Duration, "custom">, () => DateRange> = {
  hour: () => ({ from: subHours(new Date(), 1), to: new Date() }),
  day: () => ({ from: subDays(new Date(), 1), to: new Date() }),
  "7days": () => ({ from: subDays(new Date(), 7), to: new Date() }),
  "30days": () => ({ from: subDays(new Date(), 30), to: new Date() }),
};

const getDatesDurationByISO = ({ from, to }: DateRange) => ({
  from: from.toISOString(),
  to: to.toISOString(),
});

const defaultOptions: Duration[] = ["day", "7days", "30days", "custom"];

const Wrapper = styled.div`
  ${tw`space-y-2 py-2`}
`;

const Box = styled.div<FieldBoxVariantsProp>`
  ${fieldBoxStyle}
  ${fieldBoxVariants}
  ${tw`flex justify-between items-center`}
`;

const getFields: (
  allowSingleDate?: boolean,
  fromDate?: Date,
  toDate?: Date,
  availableOptions?: Duration[],
) => CustomRenderFields[] = (
  allowSingleDate,
  fromDate,
  toDate = endOfDay(new Date()),
  availableOptions = defaultOptions,
) => [
  {
    type: "radio",
    name: "duration",
    values: availableOptions ? durationOptions.filter((o) => availableOptions.includes(o.value)) : durationOptions,
    variant: "sm",
    calculation: {
      updates: (v: Duration) => (v === "custom" ? {} : getDatesByDuration[v]()),
    },
  },
  {
    type: "condition",
    when: "duration",
    is: (value) => value === "custom",
    fields: [
      {
        type: "container",
        Component: Wrapper,
        fields: [
          {
            type: "date",
            name: "from",
            label: "From",
            dateFormat: "PPp",
            fromDate,
            toDate,
            parse: (v) => (v ? v.toISOString() : v),
            format: (v) => (v ? new Date(v) : v),
            validate: (value, formData) =>
              !value
                ? allowSingleDate
                  ? null
                  : "common.table.filter.cannotBeEmpty"
                : formData.values.to && isAfter(parseISO(value), parseISO(formData.values.to))
                ? "common.table.filter.lowerTo"
                : null,
          },
          {
            type: "date",
            name: "to",
            label: "To",
            dateFormat: "PPp",
            fromDate,
            toDate,
            parse: (v) => (v ? v.toISOString() : v),
            format: (v) => (v ? new Date(v) : v),
            validate: (value, formData) =>
              !value
                ? allowSingleDate
                  ? null
                  : "common.table.filter.cannotBeEmpty"
                : formData.values.from && isBefore(parseISO(value), parseISO(formData.values.from))
                ? "common.table.filter.higherFrom"
                : null,
          },
        ],
      },
    ],
  },
];

type Props = FieldBoxVariantsProp & {
  onSubmit: (range: DateRangeFormatted) => void;
  value: DateRangeFormatted;
  label?: string;
  placeholder?: TKeys;
  isClearable?: boolean;
  allowSingleDate?: boolean;
  fromDate?: Date;
  toDate?: Date;
  availableOptions?: Duration[];
};

const DateFilter: React.FC<Props> = ({
  onSubmit,
  value,
  label,
  placeholder,
  isClearable,
  allowSingleDate,
  variant,
  fromDate,
  toDate,
  availableOptions,
}) => {
  const options = availableOptions?.join("_");
  const fields = useMemo(
    () => getFields(allowSingleDate, fromDate, toDate, options?.split("_") as Duration[]),
    [allowSingleDate, fromDate, toDate, options],
  );

  return (
    <Popover
      auto
      overflowContainer
      tw="max-w-full"
      content={(close) => (
        <div tw="min-w-[200px]">
          <SchemaForm<DateRangeFormatted>
            customRender={customRender}
            fields={fields}
            initial={value}
            onSubmit={(d) => {
              onSubmit(d);
              close();
            }}
            SubmitComponent={() => (
              <div tw="pt-3 flex justify-end">
                <Button type="submit" variant={["primary", "sm"]} data-test="submitDateRangeFilter">
                  <Text tKey="common.confirm" />
                </Button>
              </div>
            )}
          />
        </div>
      )}
    >
      <div css={fieldWrapperStyle}>
        {label && <TextLabel>{label}</TextLabel>}
        <Box variant={variant}>
          {value.duration === "custom" && (value.from || value.to) ? (
            <Text
              tKey="dateFormat"
              tw="truncate"
              tValue={{
                date: [getDate(value.from), getDate(value.to)],
                formatString: "PPp_period",
                from: !value.to ? "$t(common.from)" : "",
                to: !value.from ? "$t(common.to)" : "",
              }}
            />
          ) : placeholder && !value.from && !value.to ? (
            <TextPlaceholder tKey={placeholder} />
          ) : (
            <Text>{durationOptions.find((o) => o.value === value.duration)?.label || "-"}</Text>
          )}
          {isClearable && (value.from || value.to) ? (
            <span
              onClick={(e) => {
                e.stopPropagation();
                onSubmit({ duration: "custom", from: "", to: "" });
              }}
            >
              &times;
            </span>
          ) : null}
        </Box>
      </div>
    </Popover>
  );
};

export const useDateRangeFilter = (
  duration: Duration | "empty" = "day",
  options?: Omit<Props, "onSubmit" | "value">,
): [DateRangeFormatted, JSX.Element] => {
  const [state, setState] = useState<DateRangeFormatted>(() => ({
    duration: duration === "empty" ? "custom" : duration,
    ...(duration === "empty" ? { to: "", from: "" } : getDatesDurationByISO(getDatesByDuration[duration]())),
  }));

  return [state, <DateFilter key="dateRange" onSubmit={setState} value={state} {...options} />];
};
