import { useRef, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { FormStore, changeFormData, useFieldData, useFormContext } from "common/form";
import { Button, Container, Text } from "common/guideline";

import type { UploadUsersFormData } from "./UploadUsersForm";

type Data = { error: string } | Omit<UploadUsersFormData, "name">;

const readXmlFile = (
  evt: FileList | null,
  form: FormStore<any>,
  setData: React.Dispatch<React.SetStateAction<Data | null>>,
) => {
  if (evt) {
    const file = evt[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      changeFormData(form, { usersXml: null }, { revalidate: true });
      const data = (e as any).target.result;
      const xmlParser = await import("fast-xml-parser");
      if (xmlParser.XMLValidator.validate(data) === true) {
        changeFormData(form, { usersXml: data }, { revalidate: true });
        setData(data);
      } else {
        changeFormData(form, { usersXml: "mu.noFileSelected" }, { key: "errors" });
      }
    };
    reader.readAsText(file);
  }
};

export const UsersFileSelection: React.FC = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [data, setData] = useState<Data | null>(null);
  const form = useFormContext().useStore;
  const error = useFieldData("usersXml", "errors") as string;

  return (
    <div tw="pt-4 pb-1 overflow-x-auto overflow-y-auto">
      <input
        ref={ref}
        tw="h-0 w-0 opacity-0 fixed top-[-100px] left-[-100px]"
        type="file"
        onChange={(e) => readXmlFile(e.target.files, form, setData)}
        accept="application/xml"
      />

      <Button onClick={() => ref.current?.click()} variant={["sm", "side"]} data-test="uploadFile">
        <Text tKey="mu.selectFile" />
      </Button>
      <Text>{error || ""}</Text>
      {data && !error && (
        <>
          <Container variant="card" tw="text-sm">
            <pre tw="block mb-2">
              <span>{data as any}</span>
            </pre>
          </Container>
        </>
      )}
    </div>
  );
};
