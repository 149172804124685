import { Bell, Bus, FileXls, Gauge, Gear } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ListSVG } from "assets/icons/List.svg";
import { ReactComponent as XCircleSVG } from "assets/icons/XCircle.svg";
import { Text, useBreakpoints, useModalComponent } from "common/guideline";

import { SidebarAccordion } from "./SidebarAccordion";
import { SidebarLink } from "./SidebarLink";

export const SidebarComponent = () => (
  <nav tw="w-[80vw] bg-gray-8 flex flex-col-reverse h-full overflow-hidden sm:(w-72 flex-col border-t-2 border-gray-8) dark:(border-gray-2 bg-gray-1) print:hidden">
    <div tw="flex-1 overflow-y-auto flex-col flex p-2 space-y-1">
      <SidebarAccordion name="operations.title" icon={<Gauge size={14} weight="duotone" tw="mr-2" />}>
        <SidebarLink route="CASH_OPERATIONS">
          <Text tKey="operations.cashOps.title" />
        </SidebarLink>
      </SidebarAccordion>
      <SidebarAccordion name="report.title" icon={<FileXls size={14} weight="duotone" tw="mr-2" />}>
        <SidebarLink route="REPORTS">
          <Text tKey="common.overview" />
        </SidebarLink>
        <SidebarAccordion nested={1} name="machineManagement">
          <SidebarLink tw="ml-2" route="MACHINE_MANAGEMENT">
            <Text tKey="common.overview" />
          </SidebarLink>
          <SidebarLink tw="ml-2" route="MACHINE_ERRORS">
            <Text tKey="machineErrors" />
          </SidebarLink>
          <SidebarLink tw="ml-2" route="MACHINE_DETAILS">
            <Text tKey="report.md.title" />
          </SidebarLink>
          <SidebarLink tw="ml-2" route="ONLINE_STATUS">
            <Text tKey="mos.title" />
          </SidebarLink>
          <SidebarLink tw="ml-2" route="ONLINE_STATUS_CURRENT">
            <Text tKey="mosCurrent.title" />
          </SidebarLink>
        </SidebarAccordion>
        <SidebarAccordion nested={1} name="cashMgmt.title">
          <SidebarLink tw="ml-2" route="CASH_MANAGEMENT">
            <Text tKey="common.overview" />
          </SidebarLink>
          <SidebarAccordion nested={2} name="report.cashSummary.title">
            <SidebarLink tw="ml-2" route="CASH_SUMMARY">
              <Text tKey="report.cashSummary.title" />
            </SidebarLink>
            <SidebarLink tw="ml-2" route="OPEN_SHIFTS">
              <Text tKey="report.openShifts.title" />
            </SidebarLink>
            <SidebarLink tw="ml-2" route="CLAIMED_VALUES">
              <Text tKey="report.claimedValues.title" />
            </SidebarLink>
          </SidebarAccordion>
          <SidebarLink tw="ml-2" route="CASH_INVENTORY">
            <Text tKey="cashInv.title" />
          </SidebarLink>
          <SidebarLink tw="ml-2" route="MANUAL_DEPOSITS">
            <Text tKey="manualDeposits" />
          </SidebarLink>
          <SidebarLink tw="ml-2" route="NET_CASH">
            <Text tKey="report.netCash.title" />
          </SidebarLink>
        </SidebarAccordion>
        {/* TODO display accordion below, once cashier management reports will be done
        <SidebarAccordion nested={1} name="cashierManagement">
          <SidebarLink tw="ml-2" route="CASHIER_MANAGEMENT">
            <Text tKey="common.overview" />
          </SidebarLink>
        </SidebarAccordion> */}
        <SidebarLink route="TRANSACTIONS">
          <Text tKey="tranReport.title" />
        </SidebarLink>
        <SidebarLink route="ROLLING_BALANCE">
          <Text tKey="transport.rBalance.title" />
        </SidebarLink>
      </SidebarAccordion>
      <SidebarAccordion name="alerts.title" icon={<Bell size={14} weight="duotone" tw="mr-2" />}>
        <SidebarLink route="ALERTS">
          <Text tKey="alerts.title" />
        </SidebarLink>
        <SidebarLink route="ALERTS_HISTORY">
          <Text tKey="alerts.history" />
        </SidebarLink>
      </SidebarAccordion>
      <SidebarAccordion name="administration.title" icon={<Gear size={14} weight="duotone" tw="mr-2" />}>
        <SidebarLink route="ADMINISTRATION">
          <Text tKey="common.overview" />
        </SidebarLink>
        <SidebarLink route="TENANT_LIST">
          <Text tKey="tenant.list.title" />
        </SidebarLink>
        <SidebarLink route="LOCATIONS">
          <Text tKey="location.title" />
        </SidebarLink>
        <SidebarLink route="LOCATION_GROUPS">
          <Text tKey="administration.lg.title_other" />
        </SidebarLink>
        <SidebarLink route="MACHINE_LIST">
          <Text tKey="administration.machines" />
        </SidebarLink>
        <SidebarLink route="MACHINE_GROUPS">
          <Text tKey="administration.mg.title" />
        </SidebarLink>
        <SidebarLink route="MACHINE_USERS">
          <Text tKey="mu.title_other" />
        </SidebarLink>
        <SidebarLink route="MACHINE_USER_GROUP">
          <Text tKey="machineUserGroup.title_other" />
        </SidebarLink>
        <SidebarLink route="USERS">
          <Text tKey="user.title_other" />
        </SidebarLink>
        <SidebarLink route="CONFIG_TEMPLATES">
          <Text tKey="administration.rc.title" />
        </SidebarLink>
        <SidebarLink route="SOFTWARE_PACKAGES">
          <Text tKey="administration.sp.title" />
        </SidebarLink>
      </SidebarAccordion>
      <SidebarAccordion name="transport.title" icon={<Bus size={14} weight="duotone" tw="mr-2" />}>
        <SidebarLink route="TRANSPORT">
          <Text tKey="common.overview" />
        </SidebarLink>
        <SidebarLink route="CORRECTION_REASONS">
          <Text tKey="transport.corReasons.title_other" />
        </SidebarLink>
        <SidebarLink route="CORRECTION_TRANSACTIONS">
          <Text tKey="tranCorr.title" />
        </SidebarLink>
      </SidebarAccordion>
    </div>
  </nav>
);

const SidebarMobile = () => {
  const [toggleModal, isOpen] = useModalComponent({
    Component: SidebarComponent,
    modalProps: {
      variant: "drawerLeft",
      showCloseIcon: false,
    },
  });

  return (
    <div tw="text-primary-default mr-4 flex items-center fixed right-0 h-12 sm:h-20 z-20">
      {isOpen ? (
        <XCircleSVG width={20} height={20} tw="mr-2" />
      ) : (
        <ListSVG width={20} height={20} tw="mr-2" onClick={() => toggleModal()} />
      )}
    </div>
  );
};

export const Sidebar = () => (useBreakpoints().min.md ? <SidebarComponent /> : <SidebarMobile />);
