import { TKeys } from "i18next";

import { FullLine, Text } from "common/guideline";

type LicenseProps = {
  edition: string;
  active: boolean;
  licenseKey?: TKeys;
};

export const LicenseInfo: React.FC<LicenseProps> = (current: LicenseProps) => {
  return current.edition !== "BASIC" ? (
    <div>
      <span>
        <b>
          <Text tKey="machine.licensing.edition.enterpriseSubscription" />
        </b>
        {" - "}
        <Text
          tw="opacity-70"
          tKey={current.active === true ? "machine.licensing.currentlyActive" : "machine.licensing.currentlyInactive"}
        />
      </span>
      <FullLine />
      <span>
        <b>
          <Text tKey="machine.licensing.licenseKey" />
        </b>
        {" - "}
        <Text tKey={current.licenseKey} />
      </span>
    </div>
  ) : (
    <Text tKey="machine.licensing.edition.basic" />
  );
};
