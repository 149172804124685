import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as MachineErrorsSVG } from "assets/icons/MachineErrors.svg";
import { PageLayout, useMappedSiteGroupContext } from "base/components";
import { client } from "client";
import {
  CustomGetCsvFn,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  useDateRangeFilter,
  usePagination,
} from "common/guideline";
import {
  GenerateMachineErrorsReportDocument,
  GenerateMachineErrorsReportQuery,
  GenerateMachineErrorsReportQueryVariables,
  MachineErrorsReportOrderColumn,
  useGenerateMachineErrorsReportQuery,
} from "generated";
import { useTranslation } from "i18n";
import { RmSeverityBadge } from "machine/components";
import { FilterBox } from "report/components";
import { SortByColumnsData, useSorting } from "report/hooks";

import { breadcrumbs } from "./breadcrumbs";

type MachineError = NonNullable<
  NonNullable<NonNullable<GenerateMachineErrorsReportQuery["generateMachineErrorsReport"]>["rows"]>[0]
> & { __typename: "ErrorsReportRowDto" };

const getColumns: TableTypes.TranslatedColumns<MachineError, SortByColumnsData<MachineErrorsReportOrderColumn>> = (
  t,
) => [
  {
    header: t("machine.uuid"),
    id: "MACHINE",
    accessorKey: "uuid",
  },
  {
    header: t("machine.deviceId"),
    id: "DEVICE_ID",
    accessorKey: "deviceId",
  },
  {
    header: t("machine.dateTime"),
    id: "DATE",
    accessorFn: (d) => accessors.date(d.dateTime, t),
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.kind"),
    id: "KIND",
    accessorKey: "kind",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.severity"),
    id: "SEVERITY",
    accessorKey: "severity",
    cell: RmSeverityBadge,
  },
  {
    header: t("machine.information"),
    id: "INFORMATION",
    accessorKey: "information",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.extendedInfo"),
    id: "EXTENDED_INFORMATION",
    accessorKey: "extendedInfo",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.errorCode"),
    id: "ERROR_CODE",
    accessorKey: "errorCode",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.cleared"),
    id: "CLEARED",
    accessorKey: "cleared",
    meta: {
      hideVertical: true,
    },
  },
];

const VerticalHeader: React.FC<TableTypes.VerticalHeaderProps<MachineError>> = () => (
  <div tw="pb-4 flex justify-between"></div>
);

export const MachineErrors = () => {
  const { t, i18n } = useTranslation();
  const [{ pageSize, pageIndex }, setPagination] = usePagination();
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<MachineErrorsReportOrderColumn>();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ location, machine, siteGroup }] = useMappedSiteGroupContext(true);
  const [date, DateRange] = useDateRangeFilter("day", { label: "report.period" });
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateMachineErrorsReportQuery({
    variables: {
      input: {
        skip: pageSize * pageIndex,
        limit: pageSize,
        order,
        orderColumn,
        machineNodeIds: machine,
        locationNodeIds: location ? location : siteGroup,
        fromDate: date.from,
        toDate: date.to,
      },
    },
  });

  const rows = (data?.generateMachineErrorsReport?.rows || []) as MachineError[];
  const fullSize = data?.generateMachineErrorsReport?.fullSize || 0;

  const getCsv: CustomGetCsvFn = async (getOptions) => {
    if (!fullSize) return "";

    const { data: { generateMachineErrorsReport } = {} } = await client.query<
      GenerateMachineErrorsReportQuery,
      GenerateMachineErrorsReportQueryVariables
    >({
      query: GenerateMachineErrorsReportDocument,
      variables: {
        input: {
          skip: 0,
          limit: fullSize || 0,
          order,
          orderColumn,
          machineNodeIds: machine,
          locationNodeIds: location,
          fromDate: date.from,
          toDate: date.to,
        },
      },
    });

    return getOptions({ data: (generateMachineErrorsReport?.rows || []) as MachineError[] });
  };

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="machineErrors"
      subtitle="report.machineErrorsDescription"
      Icon={MachineErrorsSVG}
      withPicker
    >
      <FilterBox>{DateRange}</FilterBox>
      <Table
        tableName="machineErrors"
        data={rows}
        columns={columns}
        VerticalHeader={VerticalHeader}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagination={setPagination}
        sorting={sorting}
        onSorting={setSorting}
        totalCount={fullSize}
        actions={<TableDownload title="machineErrors" disabled={!fullSize} getCsv={getCsv} />}
      />
    </PageLayout>
  );
};
