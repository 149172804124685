export const en = {
  queryBuilder: "Query builder",
  scheduledReports: "Scheduled reports",
  lossPrevention: "Loss prevention",
  machineManagement: "Machine management",
  machineManagementOverview: "Machine management overview",
  machineErrors: "Machine errors",
  machineErrorsSeverity: "Severity",
  machineReliability: "Machine reliability",
  refillReport: "Refill report",
  cashTotals: "Cash totals",
  manualDeposits: "Manual deposits",
  utilization: "Utilization",
  cashierManagement: "Cashier management",
  cashierManagementOverview: "Cashier management overview",
  cashierBalancing: "Cashier balancing",
  cashierTransactions: "Cashier transactions",
  report: {
    title: "Reports",
    overview: "Reports overview",
    desc: "Build different reports depending on the information you are interested in. Reports are predefined based on cash-handling industry norms.",
    queryBuilderDescription: "Use Dynamic query builder to build new, custom reports from existing data",
    scheduledReportsDescription:
      "Schedule a report when you want to automatically get specific information at certain time intervals and for a specific period in time",
    lossPreventionDescription:
      "Loss prevention report shows information that has to do with protecting the assets of the business.",
    machineManagementDescription:
      "Machine management report shows information that has to do with machine errors, details, and status.",
    cashTotalsDescription:
      "The report shows cash totals by location. That is, dispensable (recyclable) and bankable cash available for withdrawal for bank deposit or CIT pickup. Claimed values, Open shifts, and Smart Vault are reported at the machine level.",
    manualDepositsDescription: "The report shows information on all manually entered deposits or Claimed values.",
    netCashDescription:
      "The report shows a summary of the opening and closing balance amount over the course of a full business day.",
    utilizationDescription: "The report shows data on the cash-in/cash-out flow for the selected period.",
    cashierManagementDescription:
      "Cashier management report shows information that has to do with cashier transactions.",
    cashierBalancingDescription:
      "The report shows the daily balances for users with a certain role. It enables cashier balancing against POS data.",
    cashierTransactionsDescription:
      "The report shows a summary of the transactions made by users with the Cashier role. The transaction types are deposit, dispense, and end-of-shift.",
    machineErrorsDescription:
      "The report shows machine error history. You can filter by group, site, date, machine, severity, and kind (the error code).",
    machineReliabilityDescription:
      "The report shows the history of the total number of sessions and the number of successful sessions during the selected time period. The report shows all errors that occurred during a signed-in session. You can filter the report on group and site.",
    refillReportDescription:
      "The report shows when a machine needs to be emptied or refilled. It shows information on the current container counts, user-configured limits (high and low), and the amount needed for the container to reach the preset maximum level (refill count).",
    onlineStatusDescription: "The report shows the machine's online status for the selected time period.",
    period: "Reporting period",
    totalAmount: "{{currency}} Amount",
    totalCount: "{{currency}} Count",
    openingTotalAmount: "{{currency}} Opening",
    closingTotalAmount: "{{currency}} Closing",
    type: "Type",
    currency: "Currency",
    container: "Container",
    denomination: "Denomination",
    count: "Count",
    total: "Total",
    md: {
      title: "Machine details",
      desc: "The report shows machine details",
      codVersion: "CoD version",
      machineDate: "Machine date",
      accountingDate: "Accounting date",
      lastErrorDate: "The latest error date",
      lastError: "The latest error",
    },
    claimedValues: {
      title: "Claimed values",
      desc: "Use the Claimed values report to get information on actual drop-safe totals based on user-Claimed values at a given time",
    },
    cashSummary: {
      title: "Cash summary",
      desc: "Use the cash summary report to get information on current and historical views, dispensable (recyclable) and bankable cash available for withdrawal for bank deposit or CIT pickup.",
      bankableAmount: "Bank deposit amount",
      bankableCount: "Bank deposit count",
      dispensableAmount: "Dispensable amount",
      dispensableCount: "Dispensable count",
      machineTotal: "Machine total",
      dropSafe: "Drop safe",
    },
    openShifts: {
      title: "Open shifts",
      desc: "Use the Open shifts report to get information on the latest cashier transactions (dispenses) that do not have a following end of shift transaction within the date range.",
      cashierId: "Cashier ID",
      cashierName: "Cashier name",
      role: "Role",
      dateTime: "Date time",
      machineDateTime: "Machine date time",
      transactionType: "Transaction type",
    },
    netCash: {
      title: "Net cash",
      desc: "The report shows a summary of the opening and closing balance amount over the course of a full business day.",
      noAmountsData: "No amounts data",
    },
  },
  cashMgmt: {
    title: "Cash management",
    overview: "Cash management overview",
    desc: "Cash management report shows information that has to do with cash inventory and totals.",
  },
  tranReport: {
    title: "Transactions",
    desc: "Transactions report shows information that has to do with all available transactions.",
    subtype: "Subtype",
    type: "Type",
    tranDate: "Transaction date",
    accountingDate: "Accounting date",
    machineTime: "Machine time",
    machine: "Machine",
    site: "Site",
    tranId: "Transaction ID",
    sequence: "Sequence",
    reference: "Reference",
    machineUser: "Machine user",
    account: "Account",
    roles: "Roles",
    workUnit: "Work unit",
    workUnitGroup: "Work unit group",
    originUser: "Originating user",
    originUserAccount: "Originating user account",
    customData: "Custom data",
    valuesByDeno: "Values by denomination",
    tranCommissions: "Transaction commissions",
    useAccDate: "Use accounting date",
    datePeriod: "Reporting period",
    withTranCorr: "Include correction transactions",
    mixEdited: "Mix edited",
    cardIdentifier: "Machine user card identifier",
    UUID: "Machine UUID",
    timestamp: "Timestamp",
    timezoneOffset: "Time zone UTC offset",
    currency: "Currency",
    total: "Total",
    valBag: {
      nodeId: "Node ID",
      totalAmount: "Total amount",
      type: "Type",
      rejects: "Rejects",
      valNodeId: "Value node ID",
      total: "Total",
      count: "Count",
      decimals: "Decimals",
      currency: "Currency",
      denomination: "Denomination",
      pieceValue: "Piece value",
      exchangeRate: "Exchange rate",
      exchangeRateDecimals: "Exchange rate decimals",
      reference: "Reference",
      valType: "Value type",
      claimedValueType: "Claimed value type",
      customType: "Custom type",
      c2Count: "C2 count",
      c3Count: "C3 count",
      c4bCount: "C4b count",
      creditCat2: "Credit cat 2",
      creditCat3: "Credit cat 3",
    },
  },
  cashInv: {
    title: "Cash inventory",
    desc: "The report shows the amount of cash in each container, per machine and at a certain point in time. The amount is shown per cash type, denomination, and currency.",
    machine: "Machine",
    machineType: "Machine type",
    container: "Container",
    denomination: "Denomination",
    type: "Type",
    site: "Site",
  },
  mos: {
    title: "Online status",
    desc: "Use the online status report to get information on a machine's online status for the selected time period.",
    machine: "Machine",
    site: "Site",
    status: "Status",
    lastEvent: "Last event",
    lastReceived: "Last received",
    timeOfStatusChange: "Time of status change",
  },
  mosCurrent: {
    title: "Current online status",
    desc: "Use the current online status report to keep track of the machine's online status at the current point in time.",
  },
  mDepo: {
    devices: "Devices",
    bagIds: "Bag IDs",
    date: "Date / time",
    type: "Type",
    machineDate: "Machine date / time",
    tranType: "Transaction type",
  },
};
