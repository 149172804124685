import { TKeys } from "i18next";
import { useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getFormTitle } from "base/fields";
import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text } from "common/guideline";

import { getDomainNamesField, getUserPermissionsField, getUserRolesField } from "../fields";
import { UserCreateFormData } from "../types";

type Props = {
  onSubmit: OnSubmit<UserCreateFormData>;
  submitLabel: TKeys;
  initial?: UserCreateFormData;
  updateForm?: boolean;
};

const TwoColsWrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-2`}
`;

const getFields: (updateForm?: boolean) => CustomRenderFields[] = (updateForm) => [
  {
    type: "container",
    Component: getFormTitle("user.identity.title"),
  },
  {
    type: "container",
    Component: TwoColsWrapper,
    fields: [
      {
        type: "text",
        name: "userName",
        label: "user.userName",
        disabled: !!updateForm,
        validate: { type: "string", required: true },
      },
      {
        type: "password",
        name: "password",
        label: "user.password",
        validate: { type: "string", required: true },
      },
      {
        type: "email",
        name: "email",
        label: "user.email",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "firstName",
        label: "user.firstName",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "lastName",
        label: "user.lastName",
        validate: { type: "string", required: true },
      },
    ],
  },
  {
    type: "container",

    Component: getFormTitle("user.access.accessLevel"),
  },
  {
    type: "container",
    Component: TwoColsWrapper,
    fields: [
      getDomainNamesField({ name: "domainName" }),
      getUserRolesField({ name: "userRoleNames" }),
      getUserPermissionsField({ name: "userPermissions" }),
    ],
  },
];

export const UserForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm }) => {
  const [fields] = useState(() => getFields(updateForm));

  return (
    <>
      <Text variant="heading" tKey="user.createUser" tw="pb-4 block" />
      <SchemaForm<UserCreateFormData>
        fields={fields}
        onSubmit={onSubmit}
        initial={initial}
        customRender={customRender}
        SubmitComponent={() => (
          <FormData type="isSubmittig">
            {(isLoading) => (
              <div tw="flex justify-between">
                <Button
                  tw="mt-6"
                  variant="side"
                  onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "USERS" })}
                  data-test="goBack"
                >
                  <Text tKey="machine.goBack" />
                </Button>
                <LoadingButton
                  type="submit"
                  tw="mt-6"
                  isLoading={isLoading}
                  disabled={isLoading}
                  data-test="submitForm"
                >
                  <Text tKey={submitLabel} />
                </LoadingButton>
              </div>
            )}
          </FormData>
        )}
      />
    </>
  );
};
