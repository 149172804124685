import { isEqual, isValid, parse } from "date-fns";
import { CalendarBlank } from "phosphor-react";
import { useMemo, useRef } from "react";
import { DayPicker, InputHTMLAttributes, useInput } from "react-day-picker";
import tw, { css, styled, theme } from "twin.macro";
import "styled-components/macro";
import "react-day-picker/dist/style.css";

import { hasVariant } from "common/guideline/components/helpers";
import { getDate } from "common/helpers";
import { useNonInitialEffect } from "common/hooks";
import { useTranslationWithLocale } from "i18n";

import { Popover } from "../../guideline/components";
import { RawFieldData, fieldFactory } from "../schema";

import { InputProps, InputRaw } from "./Input";

export type DateInputProps = Omit<InputProps, "type" | "value"> & {
  type: "date";
  dateFormat?: string;
  value: string | Date;
  fromDate?: Date;
  toDate?: Date;
  hidePicker?: boolean;
};

const StyledDayPicker = styled(DayPicker)`
  --rdp-cell-size: 36px;
  --rdp-accent-color: ${theme`colors.primary.default`};
  --rdp-background-color: ${theme`colors.primary.accent`};
  --rdp-accent-color-dark: ${theme`colors.primary.default`};
  --rdp-background-color-dark: ${theme`colors.primary.default`};
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 2px solid ${theme`colors.gray.7`};

  .rdp-button {
    ${tw`text-gray-7 text-sm rounded-md`}
  }

  .rdp-head_row,
  .rdp-caption_label {
    ${tw`text-gray-8`}
  }

  .rdp-caption_label {
    ${tw`text-lg`}
  }

  .rdp-day_today {
    ${tw`bg-gray-3`}
  }

  .rdp-day_selected {
    ${tw`bg-primary-default text-gray-1`}
  }

  svg {
    ${tw`text-gray-6 h-3 w-3`}
  }
`;

const DEFAULT_FORMAT = "P";

const CalendarIcon = ({ variant, label }) => {
  const fromTop = hasVariant(variant, "sm") ? 8 : 12;
  const toAdd = label ? 20 : 0;

  return (
    <CalendarBlank
      weight="duotone"
      css={css`
        ${tw`text-gray-6 hover:text-gray-8 right-1 absolute`}
        top: ${fromTop + toAdd}px;
      `}
    />
  );
};

export const DateInputRaw: React.FC<RawFieldData<DateInputProps>> = ({
  onChange,
  value,
  dateFormat: format = DEFAULT_FORMAT,
  fromDate,
  toDate,
  hidePicker,
  ...rest
}) => {
  const defaultSelected = useMemo(() => getDate(value, undefined), [value]);
  const previousDate = useRef<Date | undefined>(defaultSelected);
  const [locale] = useTranslationWithLocale();
  const { inputProps, dayPickerProps, setSelected } = useInput({
    defaultSelected,
    format,
    locale,
    fromDate,
    toDate,
  });

  useNonInitialEffect(() => {
    if (defaultSelected && (!previousDate.current || !isEqual(previousDate.current, defaultSelected))) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected]);

  useNonInitialEffect(() => {
    if (!inputProps.value) {
      onChange("");
      return;
    }

    const date = parse(inputProps.value as string, format, new Date());

    if (!previousDate.current || (isValid(date) && !isEqual(previousDate.current, date))) {
      onChange(date);
    }

    previousDate.current = date;
  }, [onChange, inputProps.value, format]);

  return (
    <div tw="relative">
      <InputRaw
        {...rest}
        type="text"
        value={inputProps.value as string}
        onChange={inputProps.onChange as NonNullable<InputHTMLAttributes["onChange"]>}
        onBlur={(e) => {
          inputProps.onBlur?.(e);
          rest.onBlur?.(e);
        }}
        onFocus={(e) => {
          inputProps.onFocus?.(e);
          rest.onFocus?.(e);
        }}
        placeholderRaw={inputProps.placeholder}
        css={css`
          input {
            padding-right: 22px;
          }
        `}
      />
      {hidePicker ? null : (
        <Popover
          auto
          overflowContainer
          onOpen={rest.onFocus}
          content={<StyledDayPicker {...dayPickerProps} initialFocus />}
        >
          <CalendarIcon variant={rest.variant} label={rest.label} />
        </Popover>
      )}
    </div>
  );
};

export const DateField = fieldFactory(DateInputRaw);
