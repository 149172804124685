import { SmileyXEyes } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as InfoSVG } from "assets/icons/Info.svg";
import { Text } from "common/guideline";

type State = "info" | "error";

type Props = React.ComponentProps<typeof Text> & { className?: string; state: State };

export const InfoState: React.FC<Props> = ({ className, state, ...props }) => (
  <div tw="flex flex-col justify-center items-center" className={className}>
    {state === "error" ? (
      <SmileyXEyes tw="text-error-default text-4xl" weight="duotone" />
    ) : (
      <InfoSVG width={36} height={36} />
    )}
    <Text {...props} />
  </div>
);
