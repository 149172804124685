import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { CustomRenderFieldTypes } from "common/form/renderFields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { FindAllMachineUsersQuery, useFindAllMachineUsersQuery } from "generated";

type MachineUserOption = {
  label: string;
  value: string;
};

type ConfigOptions = {
  getValue?: (v: NonNullable<FindAllMachineUsersQuery["findAllMachineUsers"]>[0]) => MachineUserOption["value"];
  configWithOptions?: (options: MachineUserOption[]) => Partial<CustomRenderFieldTypes["select"]>;
};

const getUseConfig: (options: ConfigOptions) => SelectLiveFieldUseConfig = ({ getValue, configWithOptions }) =>
  function useConfig(prev) {
    const [data = prev] = useMappedQuery((d) => {
      const options: MachineUserOption[] =
        d?.findAllMachineUsers
          ?.flatMap((p) => (p ? [{ label: p.name || "", value: getValue?.(p) || p.nodeId || "" }] : []))
          .sort(makeSorter("label")) || [];

      // eslint-disable-next-line
      // @ts-ignore
      return {
        ...prev,
        isLoading: false,
        options,
        ...configWithOptions?.(options),
      };
    }, useFindAllMachineUsersQuery());

    return [data, !data.isLoading];
  };

export const getSelectMachineUserField = ({
  getValue,
  configWithOptions,
  ...value
}: SelectLiveFieldValueArg & ConfigOptions) =>
  getSelectLiveField(
    {
      label: "mu.title",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    getUseConfig({ getValue, configWithOptions }),
  );
