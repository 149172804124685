import { Row, flexRender, useReactTable } from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import { useMemo, useRef } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getTableConfig } from "../helpers";
import { Pagination } from "../Pagination";
import { TableProvider } from "../TableContext";
import { TableMeta } from "../TableMeta";
import { GenericRecord, TableProps } from "../types";

import { Filtering } from "./Filtering";
import { Sorting } from "./Sorting";

const StyledVerticalTable = styled.div<Pick<TableProps<any>, "showPagination"> & { isLoading?: boolean }>`
  ${tw`flex-1 overflow-hidden`}
  ${({ showPagination }) => !showPagination && tw`rounded-lg border border-gray-3`}

  .tBody {
    ${tw`h-full overflow-y-auto`}
    ${({ isLoading }) => isLoading && tw`animate-pulse [animation-duration:1s]`}

    > div {
      ${tw`relative`}
    }
  }

  .row {
    ${tw`absolute top-0 left-0 w-full border-t-4 px-2 py-4`}
  }

  .tr {
    ${tw`rounded-lg flex justify-between items-center px-4 py-3`}

    &:nth-child(odd) {
      ${tw`bg-gray-1`}
    }

    &:nth-child(even) {
      ${tw`bg-gray-2`}
    }

    span {
      text-align: right;
    }
  }

  .th {
    ${tw`text-gray-6 pr-2 [flex-basis:30%]`}
  }
`;

const Rows: React.FC<{ rows: Row<any>[] } & Pick<TableProps<any>, "VerticalHeader">> = ({ rows, VerticalHeader }) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { virtualItems, totalSize } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });

  return (
    <div className="tBody" ref={tableContainerRef}>
      <div style={{ height: totalSize }}>
        {virtualItems.map((virtualRow) => {
          const row = rows[virtualRow.index];

          return (
            <div
              key={row.id}
              className="row"
              ref={virtualRow.measureRef}
              style={{ transform: `translateY(${virtualRow.start}px)` }}
            >
              {VerticalHeader && (
                <div tw="col-span-2">
                  <VerticalHeader row={row} />
                </div>
              )}

              {row.getVisibleCells().map((cell) =>
                cell.column.columnDef.meta?.hideVertical ? null : (
                  <div key={cell.id} className="tr">
                    <div className="th">{flexRender(cell.column.columnDef.header, cell.getContext() as any)}</div>
                    <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                  </div>
                ),
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export function TableVertical<T extends GenericRecord>({
  data,
  columns,
  VerticalHeader,
  showPagination = true,
  totalCount = data.length || 0,
  error,
  loading,
  onPagination,
  onSorting,
  onFilter,
  pageSize,
  columnFilters,
  pageIndex,
  sorting,
  actions,
  tableName,
}: TableProps<T>): React.ReactElement {
  const config = useMemo(
    () =>
      getTableConfig({
        onSorting,
        onPagination,
        onFilter,
        sorting,
        columnFilters,
        pageSize,
        pageIndex,
        totalCount,
        hasSubRows: false,
      }),
    [onSorting, onPagination, onFilter, sorting, columnFilters, pageSize, pageIndex, totalCount],
  );

  const {
    getHeaderGroups,
    getRowModel,
    getPageCount,
    setPageIndex,
    getCanNextPage,
    getCanPreviousPage,
    nextPage,
    previousPage,
    getState,
    setState,
    setPageSize,
    setColumnOrder,
    getAllLeafColumns,
    getPrePaginationRowModel,
    setSorting,
    setColumnFilters,
  } = useReactTable({
    data,
    columns,
    ...config,
  });

  const { rows } = getRowModel();

  const table = (
    <StyledVerticalTable showPagination={showPagination} isLoading={loading}>
      <TableMeta error={error} loading={loading} dataLength={rows.length} />
      <Rows rows={rows} VerticalHeader={VerticalHeader} />
    </StyledVerticalTable>
  );

  return (
    <TableProvider
      getPrePaginationRowModel={getPrePaginationRowModel}
      getState={getState}
      setState={setState}
      tableName={tableName}
      getAllLeafColumns={getAllLeafColumns}
    >
      <div tw="flex flex-col flex-[1] min-h-[600px]">
        <div tw="pb-2 flex justify-between">
          <Filtering getHeaderGroups={getHeaderGroups} getState={getState} setColumnFilters={setColumnFilters} />
          <Sorting getHeaderGroups={getHeaderGroups} getState={getState} setSorting={setSorting} />
        </div>

        {showPagination ? (
          <Pagination
            getPageCount={getPageCount}
            setPageIndex={setPageIndex}
            getState={getState}
            totalCount={totalCount}
            setPageSize={setPageSize}
            previousPage={previousPage}
            getCanPreviousPage={getCanPreviousPage}
            nextPage={nextPage}
            getCanNextPage={getCanNextPage}
            getAllLeafColumns={getAllLeafColumns}
            setColumnOrder={setColumnOrder}
            actions={actions}
          >
            {table}
          </Pagination>
        ) : (
          table
        )}
      </div>
    </TableProvider>
  );
}
