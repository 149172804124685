import { format, parse } from "date-fns";
import { enUS } from "date-fns/locale";
import { FormatFunction, KeyPrefix, Languages, Namespace } from "i18next";
import { useMemo } from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";

import { CONFIG } from "common/config";
import { customFormatters } from "common/helpers";

type DefaultNamespace = "translation";

// TODO - when more languages will be added, add locale data for them
const getDateLocale = (lang: Languages) => {
  switch (lang) {
    case "en-US":
      return enUS;
    default:
      return enUS;
  }
};

type Options = {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
  lang: Languages | string;
};

type DateI18n = {
  format: (date: Date | number, formatString: string, options: Options) => string;
  parse: (dateString: string, formatString: string, referenceDate: Date | number, options: Options) => Date;
};

export const dateI18n: DateI18n = {
  format: (date, formatString, options) =>
    format(date, formatString, { ...options, locale: getDateLocale(options.lang as Languages) }),
  parse: (dateString, formatString, referenceDate, options) =>
    parse(dateString, formatString, referenceDate, { ...options, locale: getDateLocale(options.lang as Languages) }),
};

export const useTranslationWithLocale = <
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
>(): [Locale, UseTranslationResponse<N, TKPrefix>] => {
  const translation = useTranslation<N, TKPrefix>();
  const locale = useMemo(() => getDateLocale(translation.i18n.language as Languages), [translation.i18n.language]);

  return [locale, translation];
};

const FORMAT_START = "dateFormat";

export const dateFormat: FormatFunction = (value, formatter, lang, options) => {
  if (formatter === FORMAT_START) {
    const _format = options?.formatString;

    if (!CONFIG.isProd && !_format) {
      throw new Error("must provide format string");
    }

    return (
      customFormatters[_format]?.(value, getDateLocale(lang as Languages)) ||
      dateI18n.format(value, _format, { lang: lang as Languages })
    );
  }

  return value;
};
