import create, { StoreApi, UseBoundStore } from "zustand";

import { uniqueID } from "common/helpers";

export type Variant = "success" | "error" | "warning" | "info";
type ToastData = { variant?: Variant; timeout?: false | number };
export type ToastTuple = [string, string | React.ReactNode, ToastData];

type ToastStore = {
  toasts: ToastTuple[];
  // close signal is for animating toast on close
  closeSignal: Record<string, boolean>;
};

type UseToast = UseBoundStore<StoreApi<ToastStore>> & {
  actions: {
    show: (content: string | React.ReactNode, data?: ToastData) => string;
    close: (id: string) => void;
    delete: (id: string) => void;
    deleteAll: () => void;
  };
};

export const useToast = create<ToastStore>(() => ({
  toasts: [],
  closeSignal: {},
})) as UseToast;

useToast.actions = {
  // returns new toast id
  show: (content, data) => {
    const newId = uniqueID();

    useToast.setState((p) => ({
      toasts: [
        ...p.toasts,
        [
          newId,
          content,
          { timeout: data?.timeout === undefined ? 2000 : data.timeout, variant: data?.variant || "info" },
        ] as ToastTuple,
      ],
    }));

    return newId;
  },
  // this function only sends signal to close the toast
  close: (toastId) =>
    useToast.setState((p) => ({
      closeSignal: { ...p.closeSignal, [toastId]: true },
    })),
  // this function closes the toast and removes it from the store
  delete: (toastId) =>
    useToast.setState((p) => ({
      toasts: p.toasts.filter(([id]) => id !== toastId),
      closeSignal: { ...p.closeSignal, [toastId]: false },
    })),
  deleteAll: () =>
    useToast.setState({
      toasts: [],
      closeSignal: {},
    }),
};
