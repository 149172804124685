import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { VariantsByKeys, WithVariants, applyVariant } from "./helpers";

type Props = {
  progress: number;
} & WithVariants<typeof variants>;

type ProgressVariants = "desc" | "success";

const variants: VariantsByKeys<ProgressVariants, { progress: number }> = {
  success: tw`bg-success-default`,
  desc: ({ progress }) => css`
    ${progress > 66 ? tw`bg-error-default` : progress > 33 ? tw`bg-warning-default` : tw`bg-success-default`}
  `,
};

const Progress = styled.div<Props>`
  ${tw`flex items-center h-full transition-all duration-300 pl-2 text-white`}
  width: ${({ progress }) => progress}%;
  ${applyVariant(variants, "desc")}
`;

export const ProgressBar: React.FC<Props> = ({ variant, progress = 0 }) => (
  <div tw="h-8 w-full bg-gray-3 rounded overflow-hidden my-2">
    <Progress progress={progress} variant={variant}>
      {progress.toFixed()}%
    </Progress>
  </div>
);
