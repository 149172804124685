import { Info } from "phosphor-react";
import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { ReactComponent as XCircleSVG } from "assets/icons/XCircle.svg";

import { VariantsByKeys, WithVariants, applyVariant } from "../helpers";
import { Text } from "../Text";

import { Variant } from "./useToast";

const variants: VariantsByKeys<Variant> = {
  success: css`
    ${tw`border-l-success-default`}
    & > svg:first-of-type {
      ${tw`text-success-default`}
    }
  `,
  error: css`
    ${tw`border-l-error-default`}
    & > svg:first-of-type {
      ${tw`text-error-default`}
    }
  `,
  warning: css`
    ${tw`border-l-warning-default`}
    & > svg:first-of-type {
      ${tw`text-warning-default`}
    }
  `,
  info: css`
    ${tw`border-l-primary-default`}
    & > svg:first-of-type {
      ${tw`text-primary-default`}
    }
  `,
};

const StyledWrapper = styled.div<Pick<Props, "variant" | "willClose">>`
  ${tw`max-w-sm w-full bg-gray-1 pointer-events-auto border border-gray-3 border-l-4 rounded overflow-hidden p-4 flex items-start shadow-blue`}
  ${({ willClose }) => (willClose ? tw`animate-slideOut` : tw`animate-slideIn`)}
  animation-fill-mode: forwards;
  ${applyVariant(variants)}
`;

type Props = WithVariants<typeof variants> &
  React.PropsWithChildren<{
    willClose: boolean;
    onClose: () => void;
  }>;

export const Toast: React.FC<Props> = ({ children, onClose, willClose, variant }) => (
  <StyledWrapper willClose={willClose} variant={variant}>
    <Info size={16} weight="duotone" tw="flex-shrink-0" />
    <div tw="ml-3 flex-1 text-xs">{typeof children === "string" ? <Text>{children}</Text> : children}</div>
    <XCircleSVG width={16} height={16} tw="cursor-pointer" onClick={onClose} />
  </StyledWrapper>
);
