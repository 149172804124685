import { Download, IconProps, Pencil, Trash } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { Button } from "common/guideline";

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  Icon?: React.FC<IconProps>;
};

const buttonVariants = ["smSquare", "ghost"] as ["smSquare", "ghost"];

const ActionDeleteButton: React.FC<ButtonProps> = ({ onClick, disabled, Icon = Trash }) => (
  <Button
    variant={buttonVariants}
    tw="text-gray-6 transition-all duration-300 hover:text-error-default"
    onClick={onClick}
    disabled={disabled}
    data-test={onClick ? "showDeleteModal" : undefined}
  >
    <Icon weight="duotone" size={18} />
  </Button>
);

const ActionEditButton: React.FC<ButtonProps> = ({ onClick, disabled, Icon = Pencil }) => (
  <Button
    variant={buttonVariants}
    tw="text-gray-6 transition-all duration-300 hover:text-primary-default"
    onClick={onClick}
    disabled={disabled}
    data-test={onClick ? "showEditModal" : undefined}
  >
    <Icon weight="duotone" size={18} />
  </Button>
);

const ActionDownloadButton: React.FC<ButtonProps> = ({ onClick, disabled }) => (
  <Button
    variant={["smSquare", "ghost"]}
    tw="text-gray-6 transition-all duration-300 hover:text-primary-default"
    onClick={onClick}
    disabled={disabled}
    data-test="downloadAction"
  >
    <Download weight="duotone" size={18} />
  </Button>
);

export const ActionButton = {
  Delete: ActionDeleteButton,
  Edit: ActionEditButton,
  Download: ActionDownloadButton,
};
