import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as NetCashSVG } from "assets/icons/NetCash.svg";
import { PageLayout, useMappedSiteGroupContext } from "base/components";
import { Table, TableDownload, TableTypes, useDateFilter } from "common/guideline";
import { GenerateNetCashReportQuery, useGenerateNetCashReportQuery } from "generated";
import { FilterBox } from "report/components";

import { breadcrumbs } from "./breadcrumbs";
import { getTotalAmountsNetCashRows } from "./totalAmountsNetCashRows";

type NetCashRow = NonNullable<NonNullable<GenerateNetCashReportQuery["generateNetCashReport"]>[0]>;

const getColumns: TableTypes.TranslatedColumns<NetCashRow> = (t) => [
  {
    header: t("machine.machineName"),
    accessorKey: "machineName",
  },
  {
    header: t("location.location_one"),
    accessorKey: "locationName",
  },
];

const toDate = new Date();

export const NetCash = () => {
  const { t, i18n } = useTranslation();
  const [referenceDate, DateFilter] = useDateFilter({ label: "common.rDate", toDate });
  const [{ location, machine, siteGroup }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateNetCashReportQuery({
    variables: {
      input: {
        referenceDate,
        machineNodeIds: machine,
        locationNodeIds: location ? location : siteGroup,
      },
    },
  });

  const rows = data?.generateNetCashReport as NetCashRow[] | undefined;

  const columns = useMemo(
    () => [...getColumns(t, i18n.language), ...getTotalAmountsNetCashRows(rows || [], t)],
    [rows, t, i18n.language],
  );

  return (
    <PageLayout
      title="report.netCash.title"
      subtitle="report.netCash.desc"
      Icon={NetCashSVG}
      breadcrumbs={breadcrumbs}
      withPicker
    >
      <FilterBox>{DateFilter}</FilterBox>
      <Table
        tableName="netCash"
        data={rows || []}
        columns={columns}
        loading={loading}
        error={error}
        initialLoading={previousData === undefined}
        actions={<TableDownload title="report.netCash.title" disabled={!rows?.length} getCsv />}
      />
    </PageLayout>
  );
};
