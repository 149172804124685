import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as UserCircleSVG } from "assets/icons/UserCircle.svg";
import { NamesListTooltip } from "base/components";
import { CommonCells, Table, TableTypes, accessors, usePagination } from "common/guideline";
import { useFindAllDomainNamesQuery, useFindListOfUsersQuery } from "generated";
import { useTranslation } from "i18n";

import { getAccessTranslationString } from "../helpers";

import { RowActions } from "./RowActions";
import { IsUserData } from "./types";

const columns: TableTypes.TranslatedColumns<IsUserData> = (t) => [
  {
    header: t("user.userName"),
    id: "USER_NAME",
    accessorKey: "userName",
  },
  {
    header: t("user.firstName"),
    id: "FIRST_NAME",
    accessorKey: "firstName",
  },
  {
    header: t("user.lastName"),
    id: "LAST_NAME",
    accessorKey: "lastName",
  },
  {
    header: t("user.email"),
    id: "EMAIL",
    accessorKey: "email",
  },
  {
    header: t("user.createdDate"),
    id: "CREATED_DATE",
    accessorFn: (v) => accessors.date(v.createdDate, t),
  },
  {
    header: t("user.lastModified"),
    id: "LAST_MODIFIED",
    accessorFn: (v) => accessors.date(v.lastModifiedDate, t),
  },
  {
    header: t("user.roles"),
    id: "ROLES",
    accessorKey: "userRoles",
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      const value = getValue<IsUserData["userRoles"]>() ?? [];

      return (
        <NamesListTooltip
          count={value.length}
          names={value.map((v) => t(getAccessTranslationString(v?.name) as any)) as string[]}
          Icon={UserCircleSVG}
          data-test="showUserRoles"
        />
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

export const UsersTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(10);
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  // Comment: I am leaving this code here, although I think we should show users per domain, not tenant.
  // const { data, loading, error, previousData } = useFindListOfUsersByTenantQuery({
  //   variables: {
  //     offset: pageIndex * pageSize,
  //     limit: pageSize,
  //   },
  // });

  const domainQuery = useFindAllDomainNamesQuery();
  const domainName = domainQuery.data?.findAllDomainNames?.[0] || "";
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindListOfUsersQuery({
    skip: !domainName,
    variables: {
      domainName,
      offset: pageIndex * pageSize,
      limit: pageSize,
    },
  });

  return (
    <Table
      tableName="users"
      columns={tColumns}
      // data={(data?.findListOfUsersByTenant?.isUserDtoOut || []) as IsUserData[]}
      data={(data?.findListOfUsers?.isUserDtoOut || []) as IsUserData[]}
      loading={loading || domainQuery?.loading}
      initialLoading={previousData === undefined}
      error={error}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPagination={setPagination}
    />
  );
};
