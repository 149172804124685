import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo } from "appRouting";
import { PageLayout } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllLocationsDocument, GetReportHeaderDocument, useCreateLocationMutation } from "generated";

import { LocationForm } from "../components/LocationForm";

import { breadcrumbs } from "./breadcrumbs";

export const LocationCreate = () => {
  const [createLocation] = useCreateLocationMutation();

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="location.create" subtitle="location.createDescription">
      <LocationForm
        submitLabel="location.create"
        onSubmit={(input) =>
          createLocation({
            variables: { input },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(FindAllLocationsDocument, cache);
              evictQuery(GetReportHeaderDocument, cache);
            },
          })
            .then(() => {
              useToast.actions.show("location.success", { variant: "success" });
              navigateTo({ route: "LOCATIONS" });
            })
            .catch(() => useToast.actions.show("location.error", { variant: "error" }))
        }
      />
    </PageLayout>
  );
};
