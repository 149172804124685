import { TKeys } from "i18next";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { useTranslation } from "i18n";

import { WithVariants, applyVariant } from "./helpers";

type Props = WithVariants<typeof variants> &
  JSX.IntrinsicElements["span"] &
  React.PropsWithChildren<{
    tKey?: TKeys | number;
    tValue?: any;
    withoutI18n?: boolean;
    as?: string | React.ComponentType<React.PropsWithChildren<any>>;
  }>;

const variants = {
  description: tw`text-sm text-gray-6`,
  heading: tw`text-gray-8 font-semibold text-base (xl:text-lg)`,
  subHeading: tw`text-gray-8 font-semibold mx-2 text-xs (sm:text-sm)`,
  title: tw`text-gray-7 font-semibold text-lg flex justify-center sm:(justify-start text-2xl)`,
  subtitle: tw`text-xs text-gray-6`,
  paragraph: tw`text-gray-6 text-sm`,
  link: tw`transition-all duration-300 cursor-pointer hover:text-primary-default`,
  xl: tw`text-4xl font-semibold text-primary-default`,
  md: tw`text-primary-default font-semibold text-xl sm:text-3xl`,
  label: tw`text-xs text-gray-10`,
  labelAlt: tw`font-semibold text-gray-7`,
  content: tw`text-gray-7 text-sm pb-1 my-1`,
};

const tryTranslate = (t, tKey, tValue, children) => {
  const key: string | undefined = tKey || (typeof children === "string" ? children : undefined);
  return (key && t(key, tValue)) || children;
};

const TextStyled = styled.span<Pick<Props, "variant">>`
  ${applyVariant(variants)}
`;

export const Text: React.FC<Props> = ({ children, tKey, tValue, withoutI18n, ...rest }) => {
  const { t } = useTranslation();

  return <TextStyled {...(rest as any)}>{withoutI18n ? children : tryTranslate(t, tKey, tValue, children)}</TextStyled>;
};
