import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { Breadcrumb, useToast } from "common/guideline";
import { useMappedQuery } from "common/hooks";
import {
  FindAllLocationsByNodeIdsQuery,
  useFindAllLocationsByNodeIdsQuery,
  useUpdateLocationMutation,
} from "generated";

import { LocationForm, LocationFormData } from "./components/LocationForm";

export type LocationUpdateParams = { nodeId: string };

export const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "LOCATIONS",
    text: "location.location_other",
  },
  {
    route: "LOCATION_UPDATE",
    text: "location.update",
  },
];

type InitialValues = LocationFormData | undefined;

const getInitialValues = ({
  findAllLocationsByNodeIds: location,
}: FindAllLocationsByNodeIdsQuery | undefined = {}): InitialValues =>
  location
    ? {
        name: location?.[0]?.name as string,
        latitude: location?.[0]?.latitude as string,
        longitude: location?.[0]?.longitude as string,
        nodeStatus: location?.[0]?.nodeStatus,
        customerReference: location?.[0]?.customerReference,
        description: location?.[0]?.description,
        city: location?.[0]?.city as string,
        country: location?.[0]?.country as string,
      }
    : undefined;

export const UpdateLocationScreen = () => {
  const { nodeId = "" } = useParams<LocationUpdateParams>();
  const [updateLocation] = useUpdateLocationMutation();
  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues,
    useFindAllLocationsByNodeIdsQuery({
      variables: { nodeIds: [nodeId] },
      skip: !nodeId,
      returnPartialData: true,
    }),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="location.update" subtitle="location.updateDescription">
      <div tw="max-w-screen-md">
        {loading ? null : (
          <LocationForm
            updateForm
            submitLabel="location.update"
            initial={mappedData}
            onSubmit={(input) => {
              updateLocation({
                variables: { nodeId, input },
                ignoreResults: true,
              })
                .then(() => {
                  useToast.actions.show("location.updateSuccess", { variant: "success" });
                  navigateTo({ route: "LOCATIONS" });
                })
                .catch(() => useToast.actions.show("location.updateApiError", { variant: "error" }));
            }}
          />
        )}
      </div>
    </PageLayout>
  );
};
