export const getPercentage = (total: number, partial: number) => (100 * partial) / total;

// linear interpolation function - get  number from 0 to 1 that represents placement of the number between 2 numbers:
// eg - lerp(0, 2, 0.5) === 0.25 because 0.5 is 1/4th between 0 and 2
export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

// convert falsy to 0
export const defaultNumber = (n: number | null | undefined) => n || 0;

export const isBetween = (from: number, to: number, num: number) => num > from && num < to;

export const floatToComa = (v: string | number) => String(v || 0).replace(".", ",");

// (100, 2) => 1 | (100, 4) => 0.01
const numberWithDecimalCountMerge = (amount: number | string, decimalsCount = 2) =>
  // cannot have more than 4 decimals according to https://en.wikipedia.org/wiki/ISO_4217
  decimalsCount < 0 || decimalsCount > 4 ? Number(amount) : Number(amount) / 10 ** decimalsCount;

// (100.2) => [10020, 2] | (101, 3) => 101000
const numberWithDecimalCountSplit = (value: number): [total: number, decimalsCount: number] => {
  const [int, decimals = "00"] = floatToComa(value).split(",");

  const decimalsCount = decimals.length;
  const additionalZero = decimalsCount === 1;

  const number = Number(`${int}${decimals}${additionalZero ? "0" : ""}`);

  return [number, Math.max(decimalsCount, 2)];
};

export const numberWithDecimalCount = {
  merge: numberWithDecimalCountMerge,
  split: numberWithDecimalCountSplit,
};
