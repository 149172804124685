import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getFormTitle } from "base/fields";
import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text, useToast } from "common/guideline";
import { evictQuery, omitByKey } from "common/helpers";
import {
  FindAllTenantsDocument,
  TenantWithExistingUserDtoIn,
  TenantWithNewUserDtoIn,
  useCreateTenantWithExistingUserMutation,
  useCreateTenantWithNewUserMutation,
} from "generated";
import { getDomainNamesField, getUsersField } from "user/pages/User/fields";

export type TenantFormData =
  | (TenantWithExistingUserDtoIn & { createUser?: false })
  | (TenantWithNewUserDtoIn & { createUser: true });

const Wrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const WrapperPadding = styled(Wrapper)`
  ${tw`pt-4`}
`;

// form can send data to 2 separate mutations, thats why it has calculations, logic is simple:
// when `createDomain` is true - new user must be created
// when `createDomain` is false - both, new user and exising user can be added

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "tenantId",
        label: "tenant.create.tenantId",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "tenantName",
        label: "tenant.create.name",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "mqttPassword",
        label: "tenant.create.mqttPassword",
        validate: { type: "string", required: true },
      },

      { type: "container" },

      {
        type: "container",
        Component: WrapperPadding,
        fields: [
          {
            type: "checkbox",
            name: "createDomain",
            label: "tenant.create.createDomain",
            calculation: {
              updates: (value, _, form) => ({
                domainName: undefined,
                createUser: value === true ? true : form.createUser,
              }),
            },
          },
          {
            type: "condition",
            when: "createDomain",
            is: (v) => v !== true,
            fields: [
              {
                type: "checkbox",
                name: "createUser",
                label: "tenant.create.createUser",
                calculation: {
                  updates: () => ({
                    isUserDtoIn: undefined,
                    userName: undefined,
                  }),
                },
              },
            ],
          },
        ],
      },

      { type: "container" },

      {
        type: "condition",
        when: "createDomain",
        is: (v) => v === true,
        fields: [
          {
            type: "text",
            name: "domainName",
            label: "user.access.domain",
            validate: { type: "string", required: true },
          },
        ],
      },
      {
        type: "condition",
        when: "createDomain",
        is: (v) => !v,
        fields: [
          getDomainNamesField({
            name: "domainName",
            calculation: { updates: (_, __, form) => (form.createUser ? {} : { userName: undefined }) },
            validate: { type: "string", required: true },
          }),
        ],
      },

      {
        type: "condition",
        when: "createUser",
        is: (v) => !v,
        fields: [getUsersField({ name: "userName", validate: { type: "string", required: true } })],
      },

      {
        type: "condition",
        when: "createUser",
        is: (v) => v === true,
        fields: [
          { type: "container" },

          {
            type: "container",
            Component: getFormTitle("tenant.create.newUser", true),
          },

          { type: "container" },

          {
            type: "text",
            name: "isUserDtoIn.firstName",
            label: "user.firstName",
            validate: { type: "string", required: true },
          },
          {
            type: "text",
            name: "isUserDtoIn.lastName",
            label: "user.lastName",
            validate: { type: "string", required: true },
          },
          {
            type: "text",
            name: "isUserDtoIn.userName",
            label: "user.userName",
            validate: { type: "string", required: true },
          },
          {
            type: "text",
            name: "isUserDtoIn.email",
            label: "user.email",
            validate: { type: "string", required: true },
          },
          {
            type: "text",
            name: "isUserDtoIn.password",
            label: "user.password",
            validate: { type: "string", required: true },
          },
        ],
      },
    ],
  },
];

export const CreateTenantForm: React.FC = () => {
  const [createWithNewUser] = useCreateTenantWithNewUserMutation({
    ignoreResults: true,
    update: (cache) => evictQuery(FindAllTenantsDocument, cache),
  });
  const [createWithExistingUser] = useCreateTenantWithExistingUserMutation({
    ignoreResults: true,
    update: (cache) => evictQuery(FindAllTenantsDocument, cache),
  });

  const onSubmit: OnSubmit<TenantFormData> = (data) => {
    const mutation = data.createUser
      ? createWithNewUser({
          variables: { tenant: { ...omitByKey("createUser")(data), createDomain: !!data.createDomain } },
        })
      : createWithExistingUser({ variables: { tenant: omitByKey(["createUser", "createDomain"])(data) } });

    return mutation
      .then(() => {
        useToast.actions.show("tenant.create.success", { variant: "success" });
        navigateTo({ route: "TENANT_LIST" });
      })
      .catch(() => useToast.actions.show("tenant.create.apiError", { variant: "error" }));
  };

  return (
    <SchemaForm<TenantFormData>
      fields={fields}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-between mt-6">
          <Button
            variant="side"
            onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "TENANT_LIST" })}
            data-test="goBack"
          >
            <Text tKey="tenant.goBack" />
          </Button>
          <FormData type="isSubmittig">
            {(isLoading) => (
              <LoadingButton type="submit" isLoading={isLoading} disabled={isLoading} data-test="submitForm">
                <Text tKey="tenant.create.submit" />
              </LoadingButton>
            )}
          </FormData>
        </div>
      )}
    />
  );
};
