type OS = "Windows" | "MacIntel" | "linux" | "unknown";

export const CONFIG = {
  isProd: process.env.NODE_ENV === "production",
  userOS: ((navigator as any)?.userAgentData?.platform || navigator?.platform || "unknown") as OS,
  gqlPath: process.env.REACT_APP_GQL_PATH as string,
  gqlOnlineStatusPath: process.env.REACT_APP_GQL_ONLINE_STATUS_PATH as string,
  gqlAlertsPath: process.env.REACT_APP_GQL_ALERT_PATH as string,
  tokenPath: process.env.REACT_APP_TOKEN_PATH,
  oauthPath: process.env.REACT_APP_OAUTH,
  tenantHeaderName: "X-TENANT-ID" as string,
};
