import tw, { styled } from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { uploadPackageFile } from "administration/helpers";
import { ReactComponent as InfoSVG } from "assets/icons/Info.svg";
import { OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text, Tooltip, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllSoftwarePackagesDocument,
  SoftwarePackageDtoIn,
  useCreateSoftwarePackageMutation,
  useUpdateSoftwarePackageMutation,
} from "generated";

import { SoftwarePackageFetched } from "../types";

import { SoftwarePackageParts } from "./SoftwarePackageParts";
import { SoftwarePackageZipInput } from "./SoftwarePackageZipInput";

export type SoftwarePackageFormData = Omit<SoftwarePackageDtoIn, "createdBy" | "nodeId" | "originUri"> & { file: File };

const Wrapper = styled.div`
  ${tw`grid gap-4 grid-flow-row pt-4`}
`;

const getFields: (updateForm: boolean | undefined, initialData) => CustomRenderFields[] = (updateForm, initialData) => [
  {
    type: "container",
    fields: [
      {
        type: "text",
        name: "name",
        label: "administration.sp.name",
        placeholder: "administration.sp.placeholder",
        validate: { type: "string", required: true },
      },
      {
        name: "zipInput",
        type: "custom",
        Component: !updateForm ? SoftwarePackageZipInput : () => <SoftwarePackageParts data={initialData} />,
      },
      {
        type: "hidden",
        name: "fileName",
        validate: { type: "string", required: [true, "administration.sp.fileRequired"] },
      },
      {
        type: "condition",
        when: "fileName",
        is: Boolean,
        fields: [
          {
            type: "container",
            Component: Wrapper,
            fields: [
              {
                type: "textarea",
                name: "releaseInfo",
                label: "administration.sp.releaseInfo",
                rows: 5,
              },
              {
                type: "textarea",
                name: "description",
                label: "administration.sp.description",
                rows: 2,
              },
              {
                type: "checkbox",
                name: "common",
                label: "administration.sp.official",
              },
            ],
          },
        ],
      },
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<SoftwarePackageFormData>;
  initialData?: Partial<SoftwarePackageFormData>;
  updateForm?: boolean;
};

const SoftwarePackagesForm: React.FC<Props> = ({ onSubmit, initialData, updateForm }) => (
  <>
    <div tw="flex mb-4">
      <Text variant="heading" tKey="administration.sp.addSp" tw="block" />
      <Tooltip
        content={
          <span tw="max-w-xs">
            <Text tKey="administration.sp.tooltip" />
          </span>
        }
        data-test="softwarePackageInfo"
      >
        <span tw="h-full flex justify-center items-center ml-1 cursor-pointer">
          <InfoSVG width={20} height={20} />
        </span>
      </Tooltip>
    </div>
    <div tw="h-full overflow-auto">
      <SchemaForm<SoftwarePackageFormData>
        fields={getFields(updateForm, initialData)}
        onSubmit={onSubmit}
        initial={initialData}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end">
            <Button type="submit" tw="mt-6" data-test="submitForm">
              <Text tKey={updateForm ? "administration.sp.update" : "administration.sp.submit"} />
            </Button>
          </div>
        )}
      />
    </div>
  </>
);

export const UpdateSoftwarePackage = ({
  nodeId,
  name,
  description,
  version,
  releaseInfo,
  revision,
  common,
  id,
  label,
  fileName,
  parts,
}: WithoutNullableKeys<SoftwarePackageFetched>) => {
  const [updateSoftwarePackage] = useUpdateSoftwarePackageMutation();

  return useModalComponent({
    modalProps: { variant: ["modal", "md"] },
    Component: (
      <SoftwarePackagesForm
        initialData={{ name, description, version, releaseInfo, revision, common, id, label, fileName, parts }}
        updateForm={true}
        onSubmit={(softwarePackage) =>
          updateSoftwarePackage({ variables: { nodeId, softwarePackage } })
            .then(() => useToast.actions.show("administration.sp.updateSuccess", { variant: "success" }))
            .catch(() => useToast.actions.show("administration.sp.updateError", { variant: "error" }))
        }
      />
    ),
  });
};

export const CreateSoftwarePackage = () => {
  const [createPackage] = useCreateSoftwarePackageMutation();
  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"] },
    Component: (
      <SoftwarePackagesForm
        // TODO delete after file will be used
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit={async ({ file, ...input }) => {
          createPackage({
            variables: { input },
            ignoreResults: true,
            update: (cache) => evictQuery(FindAllSoftwarePackagesDocument, cache),
          })
            .then(async (v) => {
              if (!v.data?.createSoftwarePackage?.nodeId) throw new Error();

              useToast.actions.show("administration.sp.addSuccess", { variant: "success" });

              await uploadPackageFile(v.data?.createSoftwarePackage.nodeId, file);

              toggle();
            })
            .catch(() => useToast.actions.show("administration.sp.addApiError", { variant: "error" }));
        }}
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showModalForm">
      <Text tKey="administration.sp.addSp" />
    </Button>
  );
};
