export const en = {
  mu: {
    title_one: "Machine user",
    title_other: "Machine users",
    name: "Name",
    uploadUsers: "Upload users",
    description: "View, manage, and create machine users",
    create: "Create machine user",
    createDescription: "You create a machine user when you want to create a new Connect device user",
    update: "Update machine user",
    updateDescription: "You update a machine user when you want to update an existing user type machine",
    delete: "Delete machine user",
    deleteDescription: "You delete a machine user when you want to delete an existing user type machine",
    deleteClose: "Close",
    roleName: "Role",
    userId: "User ID",
    success: "Machine user created successfully",
    error: "Machine user creation failed",
    updateSuccess: "Machine user updated successfully",
    updateApiError: "Machine user update failed",
    deleteSuccess: "Machine user deleted successfully",
    deleteError: "Machine user deletion failed",
    goBack: "Back",
    machine: "Machine",
    updateMachineUsers: "Update machine users",
    usersXml: "users.xml",
    selectFile: "Select file",
    noFileSelected: "You need to provide a valid xml file",
    replaceUsers: "Replace existing users?",
    uploadSuccess: "Successfully uploaded machine users from file",
    uploadFailure: "An error occurred when trying upload users from file",
    additionalLogins: "Additional logins",
    noCardLogin: "No card login",
    noDoorLogin: "No door login",
    cardLogin: "Card login",
    doorLogin: "Door login",
    status: "Status",
    validFrom: "Valid from",
    validUntil: "Valid until",
    userSettings: "User settings",
    loginSettings: "Login settings",
    accountSettings: "Account settings",
    role: "Role",
    confirmPassword: "Confirm password",
    noPasswordRequired: "No password required",
    changePinOnFirstLogin: "Change password on first login?",
    noAllowedLogins: "Number of allowed logins",
    deactivated: "Deactivated?",
    passwordsDoNotMatch: "The password and its confirm are not the same",
    unlimited: "Unlimited",
    incorrectTimespan: "Valid until must be greater then Valid from",
    cashier: "Cashier",
    cashierId: "Cashier ID",
    cashierName: "Cashier name",
    cashierRole: "Cashier role",
    cit: "CIT",
    administrator: "Administrator",
    dts: "DTS",
    manager: "Manager",
    noPinLogin: "User ID",
    noPinCard: "Card",
    noPinBio: "Biometrics",
    noPinDoor: "Door",
    loginWith: "No password for:",
  },
};
