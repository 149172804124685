import { useAutoAnimate } from "@formkit/auto-animate/react";
import { TKeys } from "i18next";
import { CaretDown } from "phosphor-react";
import { Children, useEffect, useMemo, useState } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";

import { isRoute, useLocation } from "appRouting";
import { Text } from "common/guideline";

type Props = React.PropsWithChildren<{
  icon?: React.ReactNode;
  name: TKeys;
  nested?: number;
}>;

const NESTED_MARGIN_BASE = 24;

export const SidebarAccordion: React.FC<Props> = ({ icon, name, children, nested }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const [ref] = useAutoAnimate<HTMLDivElement>({ duration: 150 });
  const innerRoutes = useMemo(() => {
    const getInnerRoutes = (ch: React.ReactNode) =>
      Children.map(ch, (c) => {
        if (c && typeof c === "object" && "props" in c) {
          return c.props.route ? c.props.route : c.props.nested ? getInnerRoutes(c.props.children) : false;
        }
      });

    return getInnerRoutes(children) as Parameters<typeof isRoute>[0];
  }, [children]);

  useEffect(() => {
    setOpen(innerRoutes ? isRoute(innerRoutes) : false);
  }, [pathname, innerRoutes]);

  const margin = nested ? NESTED_MARGIN_BASE + (nested - 1) * NESTED_MARGIN_BASE : 0;

  return (
    <div
      tw="border-b border-gray-7 dark:border-gray-3"
      ref={ref}
      css={css`
        > a {
          margin-left: ${margin + NESTED_MARGIN_BASE}px;
        }
      `}
    >
      <span
        className="group"
        tw="flex items-center cursor-pointer transition-colors duration-300 text-gray-3 dark:text-gray-7 hover:text-gray-1 dark:hover:text-gray-9"
        onClick={() => setOpen((p) => !p)}
        data-test={name}
      >
        {icon}
        <Text
          tKey={name}
          css={css`
            margin-left: ${margin}px;
            ${tw`font-semibold text-xs tracking-wide truncate uppercase leading-10 flex-1`}
          `}
        />
        <CaretDown
          size={16}
          weight="bold"
          css={css`
            ${open ? tw`rotate-180` : tw`rotate-0`}
            ${tw`transition-all duration-300 text-gray-3 dark:text-gray-7 hover:text-gray-1 dark:hover:text-gray-9`}
          `}
        />
      </span>

      {open && children}
    </div>
  );
};
