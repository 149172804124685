import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { CustomOption } from "common/form/renderFields";
import { Text } from "common/guideline";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { FindAllLocationsQuery, useFindAllLocationsQuery } from "generated";

type LocationOption = {
  label: string;
  value: string;
  data: NonNullable<NonNullable<FindAllLocationsQuery["findAllLocations"]>[0]>;
};

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const [data = prev, { loading }] = useMappedQuery((d) => {
    const options: LocationOption[] =
      d?.findAllLocations
        ?.flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "", data: p }] : []))
        .sort(makeSorter("label")) || [];

    return {
      ...prev,
      isLoading: false,
      options,
    };
  }, useFindAllLocationsQuery());

  return [data, !loading];
};

const LocationOptionComponent: CustomOption<LocationOption> = (props) => (
  <>
    {props.data.label}
    {props.data.data.country && (
      <div>
        <Text variant="paragraph">
          {props.data.data.country}, {props.data.data.city}
        </Text>
      </div>
    )}
  </>
);

export const getSelectLocationsField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "location.location",
      isLoading: true,
      isMulti: true,
      CustomOption: LocationOptionComponent,
      ...value,
    },
    useConfig,
  );
