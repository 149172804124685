import { ADMINISTRATION } from "administration/paths";
import { ALERT } from "alert/paths";
import { AUTH } from "auth/paths";
import { BASE } from "base/paths";
import { LOCATION } from "location/paths";
import { MACHINE } from "machine/paths";
import { MACHINE_USER } from "machineUser/paths";
import { OPERATIONS } from "operations/paths";
import { PERSONALIZED } from "personalized/paths";
import { REPORT } from "report/paths";
import { TENANT } from "tenant/paths";
import { TRANSPORT } from "transport/paths";
import { USER } from "user/paths";

import { getRoutingComponent } from "./appRouting";

const config = {
  ...ADMINISTRATION,
  ...ALERT,
  ...AUTH,
  ...BASE,
  ...LOCATION,
  ...OPERATIONS,
  ...TENANT,
  ...MACHINE,
  ...MACHINE_USER,
  ...USER,
  ...PERSONALIZED,
  ...REPORT,
  ...TRANSPORT,
};

export const Router = getRoutingComponent(config)((getConfig) => [
  getConfig("LOGIN"),
  getConfig("LAYOUT", [
    // BASE MODULE
    getConfig("HOME"),
    getConfig("SEARCH"),
    getConfig("TERMS"),
    getConfig("RELEASE_NOTES"),
    // ADMINISTRATION MODULE
    getConfig("ADMINISTRATION"),
    getConfig("ALLOCATIONS"),
    getConfig("MACHINE_GROUPS"),
    getConfig("MACHINE_GROUP_CREATE"),
    getConfig("MACHINE_GROUP_UPDATE"),
    getConfig("ROLES"),
    getConfig("PERMISSIONS"),
    getConfig("MACHINE_USER_GROUP"),
    getConfig("POS_TRANSACTIONS"),
    getConfig("CONFIG_TEMPLATES"),
    getConfig("DISPENSE_LIMITS"),
    getConfig("SOFTWARE_PACKAGES"),
    // MACHINE MODULE
    getConfig("MACHINE_LIST"),
    getConfig("MACHINE_CREATE"),
    getConfig("MACHINE_EDIT"),
    // MACHINE USER MODULE
    getConfig("MACHINE_USERS"),
    getConfig("MACHINE_USER_CREATE"),
    getConfig("MACHINE_USER_UPDATE"),
    // TENANT MODULE
    getConfig("TENANT_LIST"),
    getConfig("TENANT_CREATE"),
    getConfig("TENANT_UPDATE"),
    // OPERATIONS MODULE
    getConfig("DASHBOARD"),
    getConfig("CASH_OPERATIONS"),
    getConfig("TECHNOLOGY_OPERATIONS"),
    getConfig("RETAIL_OPERATIONS"),
    // PERSONALIZED MODULE
    getConfig("PERSONALIZED"),
    getConfig("INSIGHTS"),
    // ALERT MODULE
    getConfig("ALERTS"),
    getConfig("ALERTS_CREATE"),
    getConfig("ALERTS_EDIT"),
    getConfig("ALERTS_HISTORY"),
    // REPORT MODULE
    getConfig("REPORTS"),
    getConfig("QUERY_BUILDER"),
    getConfig("SCHEDULED_REPORTS"),
    getConfig("MACHINE_DETAILS"),
    getConfig("MACHINE_MANAGEMENT"),
    getConfig("MACHINE_ERRORS"),
    getConfig("MACHINE_RELIABILITY"),
    getConfig("REFILL_REPORT"),
    getConfig("ONLINE_STATUS"),
    getConfig("ONLINE_STATUS_CURRENT"),
    getConfig("CASH_MANAGEMENT"),
    getConfig("CASH_INVENTORY"),
    getConfig("CASH_TOTALS"),
    getConfig("CASH_SUMMARY"),
    getConfig("CLAIMED_VALUES"),
    getConfig("OPEN_SHIFTS"),
    getConfig("MANUAL_DEPOSITS"),
    getConfig("NET_CASH"),
    getConfig("UTILIZATION"),
    getConfig("CASHIER_MANAGEMENT"),
    getConfig("CASHIER_BALANCING"),
    getConfig("CASHIER_TRANSACTIONS"),
    getConfig("TRANSACTIONS"),
    getConfig("LOSS_PREVENTION"),
    getConfig("ROLLING_BALANCE"),
    // TRANSPORT MODULE
    getConfig("TRANSPORT"),
    getConfig("CORRECTION_REASONS"),
    getConfig("CORRECTION_TRANSACTIONS"),
    // LOCATION MODULE
    getConfig("LOCATIONS"),
    getConfig("LOCATION_CREATE"),
    getConfig("LOCATION_UPDATE"),
    // LOCATION GROUP MODULE
    getConfig("LOCATION_GROUPS"),
    getConfig("LOCATION_GROUP_CREATE"),
    getConfig("LOCATION_GROUP_UPDATE"),
    // USER MODULE
    getConfig("USERS"),
    getConfig("USER_PROFILE"),
    // ROUTES BELOW MUST BE IN THE END OF LIST
    getConfig("404"),
    getConfig("*"),
  ]),
]);
