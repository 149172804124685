import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as TransactionsSVG } from "assets/icons/Transactions.svg";
import { PageLayout, useMappedSiteGroupContext } from "base/components";
import { client } from "client";
import {
  CommonCells,
  CustomGetCsvFn,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  useDateRangeFilter,
  usePagination,
} from "common/guideline";
import { withDefault } from "common/helpers";
import {
  GenerateTransactionsReportDocument,
  GenerateTransactionsReportQuery,
  GenerateTransactionsReportQueryVariables,
  TransactionsReportOrderColumn,
  useGenerateTransactionsReportQuery,
} from "generated";
import { FilterBox, getColumnFilter, useBooleanFilter, useColumnFilters } from "report/components";
import { getTotalAmountsPairRows } from "report/helpers";
import { SortByColumnsData, useSorting } from "report/hooks";
import { TransactionType } from "transport/components";

import { breadcrumbs } from "./breadcrumbs";
import { TransactionsSubRow } from "./TransactionsSubRow";

type TransactionRowsDefault = NonNullable<
  NonNullable<NonNullable<GenerateTransactionsReportQuery["generateTransactionsReport"]>["rows"]>[0]
>;

type RowsReport = TransactionRowsDefault & { __typename: "TransactionsReportRowDto" };
type RowsCorrection = TransactionRowsDefault & { __typename: "CorrectionTransactionReportRowDto" };

export type TransactionRows = Omit<RowsReport, "__typename"> &
  Omit<RowsCorrection, "__typename"> & { __typename: "TransactionsReportRowDto" | "CorrectionTransactionReportRowDto" };

type Filters = NonNullable<GenerateTransactionsReportQuery["generateTransactionsReport"]>["columns"];

const getColumns: (
  filters: Filters,
) => TableTypes.TranslatedColumns<TransactionRows, SortByColumnsData<TransactionsReportOrderColumn>> =
  (filters) => (t) =>
    [
      CommonCells.expander,
      {
        header: t("tranReport.subtype"),
        accessorFn: (v) => withDefault(v.subType),
        enableSorting: false,
      },
      {
        header: t("tranReport.type"),
        accessorKey: "type",
        id: "TYPE",
        cell: TransactionType,
        meta: {
          filter: getColumnFilter(filters, "type"),
        },
      },
      {
        header: t("tranReport.tranDate"),
        id: "DATE",
        accessorFn: (v) =>
          withDefault(
            v.dateTime ? `${accessors.date(v.dateTime, t)} (UTC ${withDefault(v.timeZoneUtcOffset)})` : undefined,
          ),
      },
      {
        header: t("tranReport.accountingDate"),
        id: "ACCOUNTING_DATE",
        accessorFn: (v) => accessors.date(v.accountingDate, t),
      },
      {
        header: t("tranReport.machineTime"),
        accessorFn: (v) =>
          withDefault(
            v.accountingDate ? `${accessors.date(v.accountingDate, t)} (UTC ${v.timeZoneUtcOffset})` : undefined,
          ),
        enableSorting: false,
      },
      {
        header: t("tranReport.machine"),
        id: "MACHINE",
        accessorKey: "machineName",
      },
      {
        header: t("tranReport.site"),
        // TODO - location should be fetched inside row
        accessorFn: () => withDefault(undefined),
        enableSorting: false,
      },
      {
        header: t("tranReport.tranId"),
        id: "TRANSACTION_SEQ",
        accessorKey: "transactionSeq",
        meta: {
          filter: getColumnFilter(filters, "transactionSeq"),
        },
      },
      {
        header: t("tranReport.sequence"),
        accessorKey: "sequence",
        enableSorting: false,
      },
      {
        header: t("tranReport.reference"),
        accessorFn: (v) => withDefault(v.reference),
        enableSorting: false,
      },
      {
        header: t("tranReport.machineUser"),
        id: "MACHINE_USER",
        accessorKey: "machineUserName",
        enableSorting: false,
        meta: {
          filter: getColumnFilter(filters, "machineUserName"),
        },
      },
      {
        header: t("tranReport.account"),
        id: "ACCOUNT",
        accessorKey: "account",
        meta: {
          filter: getColumnFilter(filters, "account"),
        },
      },
      {
        header: t("tranReport.roles"),
        id: "ROLE",
        accessorFn: (v) => withDefault(v.roleName),
        meta: {
          filter: getColumnFilter(filters, "roleName"),
        },
      },
      {
        header: t("tranReport.workUnit"),
        id: "WORK_UNIT_NAME",
        accessorFn: (v) => withDefault(v.workUnitName),
        meta: {
          filter: getColumnFilter(filters, "workUnitName"),
        },
      },
      {
        header: t("tranReport.workUnitGroup"),
        // TODO - should be attached to row
        accessorFn: () => withDefault(undefined),
        enableSorting: false,
      },
      {
        header: t("tranReport.originUser"),
        id: "ORIGIN_USER",
        accessorFn: (v) => withDefault(v.originUser),
      },
      {
        header: t("tranReport.originUserAccount"),
        id: "ORIGIN_USER_ACCOUNT",
        accessorFn: (v) => withDefault(v.originUserAccount),
      },
      {
        header: t("tranReport.customData"),
        id: "customData",
        accessorKey: "customData",
        cell: ({ getValue }) => {
          const value = getValue<TransactionRows["customData"]>();
          return value?.length ? (
            <div tw="flex flex-col space-y-1">
              {value.map((curr: any) => (
                <div key={curr.key}>
                  {curr.key}: {curr.value}
                </div>
              ))}
            </div>
          ) : (
            withDefault(null)
          );
        },
        enableSorting: false,
        meta: {
          csv: {
            accessorFn: (v) => v?.customData?.map((curr: any) => `${curr.key}: ${curr.value}`)?.join(", ") || null,
          },
        },
      },
      {
        header: t("tranReport.valuesByDeno"),
        id: "valuesByDeno",
        accessorKey: "valuesByDenomination",
        cell: ({ getValue }) => {
          const value = getValue<TransactionRows["valuesByDenomination"]>();
          return value?.length ? <span tw="whitespace-pre-line">{value.join("\n")}</span> : withDefault(null);
        },
        enableSorting: false,
        meta: {
          csv: {
            accessorFn: (v) => v?.valuesByDenomination?.join?.(", ") || null,
          },
        },
      },
      {
        header: t("tranReport.tranCommissions"),
        // TODO - should be attached to row
        accessorFn: () => withDefault(undefined),
        enableSorting: false,
      },

      // for correction transactions
      {
        header: t("tranCorr.corrReason"),
        accessorFn: (v) => withDefault(v.correctionReason),
        enableSorting: false,
      },
      {
        header: t("tranCorr.comment"),
        accessorFn: (v) => withDefault(v.comment),
        enableSorting: false,
      },
      {
        header: t("tranCorr.createdBy"),
        accessorFn: (v) => withDefault(v.createdBy),
        enableSorting: false,
      },
    ];

const columnFiltersData = [
  ["transactionType", "TYPE"],
  ["machineUserNames", "MACHINE_USER"],
  ["account", "ACCOUNT"],
  ["transactionSeq", "TRANSACTION_SEQ"],
  ["workUnit", "WORK_UNIT_NAME"],
  ["roles", "ROLE"],
] as const;

const TransactionsTable = () => {
  const { t, i18n } = useTranslation();
  const [{ pageIndex, pageSize }, setPagination] = usePagination();
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<TransactionsReportOrderColumn>();
  const [useAccountingDate, AccountingCheckbox] = useBooleanFilter("accDate", "tranReport.useAccDate");
  const [withCorr, WithCorrCheckbox] = useBooleanFilter("tranCorr", "tranReport.withTranCorr");
  const [dateFilter, DateRange] = useDateRangeFilter("30days", { label: "tranReport.datePeriod" });
  const [filter, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const [{ location, machine, siteGroup }] = useMappedSiteGroupContext(true);
  const messageType = withCorr ? "ALL" : "MACHINE_TRANSACTIONS";
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateTransactionsReportQuery({
    variables: {
      input: {
        messageType,
        limit: pageSize,
        skip: pageIndex * pageSize,
        order,
        orderColumn,
        useAccountingDate,
        fromDate: dateFilter.from,
        toDate: dateFilter.to,
        machineNodeIds: machine,
        locationNodeIds: location ? location : siteGroup,
        ...filter,
      },
    },
  });

  const rows = data?.generateTransactionsReport?.rows as TransactionRows[] | undefined;
  const filters = data?.generateTransactionsReport?.columns;
  const fullSize = data?.generateTransactionsReport?.fullSize || 0;

  const columns = useMemo<TableTypes.ColumnDef<TransactionRows>[]>(
    () => (!rows ? [] : [...getColumns(filters)(t, i18n.language), ...getTotalAmountsPairRows(rows, t)]),
    [t, i18n.language, rows, filters],
  );

  const getCsv: CustomGetCsvFn = async (getOptions) => {
    if (!fullSize) return "";

    const { data: { generateTransactionsReport } = {} } = await client.query<
      GenerateTransactionsReportQuery,
      GenerateTransactionsReportQueryVariables
    >({
      query: GenerateTransactionsReportDocument,
      variables: {
        input: {
          messageType,
          limit: fullSize || 0,
          skip: 0,
          order,
          orderColumn,
          useAccountingDate,
          fromDate: dateFilter.from,
          toDate: dateFilter.to,
          machineNodeIds: machine,
          locationNodeIds: location ? location : siteGroup,
          ...filter,
        },
      },
    });

    return getOptions({ data: (generateTransactionsReport?.rows || []) as TransactionRows[] });
  };

  return (
    <>
      <FilterBox variant="col">
        <div tw="self-start">{DateRange}</div>
        <FilterBox tw="pb-0">
          {AccountingCheckbox}
          {WithCorrCheckbox}
        </FilterBox>
      </FilterBox>

      <Table<TransactionRows>
        tableName="transactions"
        columns={columns}
        data={rows || []}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagination={setPagination}
        sorting={sorting}
        onSorting={setSorting}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        totalCount={fullSize}
        SubRows={TransactionsSubRow}
        actions={<TableDownload title="tranReport.title" disabled={!fullSize} getCsv={getCsv} />}
      />
    </>
  );
};

export const Transactions = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="tranReport.title"
    subtitle="tranReport.desc"
    Icon={TransactionsSVG}
    withPicker
  >
    <TransactionsTable />
  </PageLayout>
);
