import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useGetReportHeaderQuery } from "generated";

type MachineOption = {
  label: string;
  value: string;
};

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const [data = prev, { loading }] = useMappedQuery((d) => {
    const options: MachineOption[] =
      d?.getReportHeader?.machines
        ?.flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "" }] : []))
        .sort(makeSorter("label")) || [];

    return {
      ...prev,
      isLoading: false,
      options,
    };
  }, useGetReportHeaderQuery());

  return [data, !loading];
};

export const getSelectMachinesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "machine.machine",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    useConfig,
  );
