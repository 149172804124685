import { client } from "client";
import { useToast } from "common/guideline";
import { deleteFile, downloadBlob, getUrlContents } from "common/helpers";
import { GetLogLinksDocument, GetLogLinksQuery, GetLogLinksQueryVariables } from "generated";

export const downloadLogArchive = async (key: string | null | undefined) => {
  if (key && key.length > 0) {
    const urlForGet = await getPresignedUrlForLogArchive(key, "urlForGet");
    if (!urlForGet) {
      showError("zts.commands.logs.missingPreSignedUrl");
    } else {
      getUrlContents(urlForGet);
    }
  }
};

export const getLogArchive = async (key: string | null | undefined) => {
  if (key && key.length > 0) {
    const urlForGet = await getPresignedUrlForLogArchive(key, "urlForGet");
    if (!urlForGet) {
      showError("zts.commands.logs.missingPreSignedUrl");
    } else {
      return await downloadBlob(urlForGet, "blob");
    }
  }
};

export const deleteLogArchive = async (key: string) => {
  const urlForDelete = await getPresignedUrlForLogArchive(key, "urlForDelete");
  if (!urlForDelete) {
    showError("zts.commands.logs.missingPreSignedUrl");
  } else {
    deleteFile(urlForDelete);
  }
};

const getPresignedUrlForLogArchive = async (key: string, url: string): Promise<string | null | undefined> =>
  (
    await client.query<GetLogLinksQuery, GetLogLinksQueryVariables>({
      query: GetLogLinksDocument,
      variables: { key },
    })
  ).data?.getLogLinks?.[url];

const showError = (error: string) => {
  useToast.actions.show(error, { variant: "error" });
};
