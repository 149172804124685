import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as BellSVG } from "assets/icons/Bell.svg";
import { NamesByIds, PageLayout } from "base/components";
import { Breadcrumb, Button, CommonCells, Table, TableTypes, Text } from "common/guideline";
import { withDefault } from "common/helpers";
import { useGetAllRulesQuery } from "generated";
import { useTranslation } from "i18n";

import { findTriggeringEvent, getFilteringFormatted, messageFilter } from "../../helpers";

import { RowActions } from "./RowActions";
import { Rule } from "./types";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "alerts.title",
    route: "ALERTS",
  },
];

const getColumns: TableTypes.TranslatedColumns<Rule> = (t) => [
  {
    header: t("alerts.name"),
    accessorKey: "name",
  },
  {
    header: t("alerts.messageType"),
    accessorFn: (v) =>
      withDefault(
        v.filters
          ? t(`alerts.messageTypes.${findTriggeringEvent(v.filters) || "UNKNOWN"}`, { defaultValue: null })
          : null,
      ),
  },
  {
    header: t("alerts.filtering"),
    accessorKey: "filters",
    cell({ getValue }) {
      return (
        <span tw="whitespace-pre-line">
          {withDefault(
            getValue()
              ? getFilteringFormatted(getValue<NonNullable<Rule["filters"]>>(), t).map(
                  ([label, value, machineOrLocation]) => (
                    <div key={label}>
                      <span tw="text-gray-6">{label}:</span>{" "}
                      {machineOrLocation ? (
                        <NamesByIds entity={machineOrLocation} ids={messageFilter.split(value)} />
                      ) : (
                        value
                      )}
                    </div>
                  ),
                )
              : null,
          )}
        </span>
      );
    },
  },
  {
    header: t("alerts.notifications"),
    accessorKey: "recipients",
    cell({ getValue }) {
      return (
        <span tw="whitespace-pre-line">
          {getValue<Rule["recipients"]>()
            ?.map((c) => `${t(`alerts.triggerType.${c?.triggerType || "UNKNOWN"}`)}: ${c?.contact}`)
            .join("\n")}
        </span>
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

export const Alerts = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const { previousData, data = previousData, loading, error } = useGetAllRulesQuery();
  const rows = (data?.getAllRules || []) as Rule[];

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="alerts.title"
      subtitle="alerts.description"
      Icon={BellSVG}
      actions={
        <Link route="ALERTS_CREATE" tw="mx-3">
          <Button>
            <Text tKey="alerts.create" />
          </Button>
        </Link>
      }
    >
      <Table
        tableName="alerts"
        data={rows}
        columns={columns}
        error={error}
        loading={loading}
        initialLoading={previousData === undefined}
      />
    </PageLayout>
  );
};
