import { getDeep } from "./getDeep";

export const setDeep = (o: any = {}, path: string[], value: any) =>
  path.reduceRight((v, k, i, ks) => {
    const parent = i === 0 ? o : getDeep(o, ks.slice(0, i));

    if (isNaN(Number(k))) {
      return Object.assign({}, parent, { [k]: v });
    }

    const arrParent = parent || [];
    arrParent[Number(k)] = v;
    return arrParent;
  }, value);
