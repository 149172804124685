import { TKeys } from "i18next";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { Breadcrumb, Breadcrumbs, Container, Text } from "common/guideline";

import { SiteGroupPicker } from "./SiteGroupContext";

type Props = React.PropsWithChildren<{
  breadcrumbs?: Breadcrumb[];
  title: TKeys;
  pageTitle?: TKeys;
  tValue?: any;
  subtitle?: TKeys;
  withPicker?: boolean;
  filterByMachineUuid?: boolean;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  slim?: boolean;
  actions?: React.ReactNode;
}>;

const UpdateDocumentTitle = memo(({ title }: { title: string }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("base.pageTitle", { title });
  }, [title, t]);

  return null;
});

UpdateDocumentTitle.displayName = "UpdateDocument";

export const PageLayout: React.FC<Props> = ({
  children,
  breadcrumbs,
  title,
  pageTitle = title,
  tValue,
  subtitle,
  withPicker,
  filterByMachineUuid,
  Icon,
  slim = false,
  actions = null,
}) => (
  <>
    {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
    {withPicker && <SiteGroupPicker filterByMachineUuid={filterByMachineUuid} />}
    {slim === false ? (
      <Container variant="borderBottom" tw="flex flex-row items-center justify-between space-x-4 py-2 sm:py-4">
        <div tw="flex gap-2">
          {Icon && (
            <div tw="flex items-center">
              <Icon width={36} height={36} />
            </div>
          )}
          <div tw="flex flex-col">
            <Text tKey={title} tValue={tValue} variant="heading" />
            {subtitle && <Text tKey={subtitle} tw="hidden sm:(max-w-4xl inline-flex)" variant="paragraph" />}
          </div>
        </div>
        {actions}
      </Container>
    ) : null}
    <Container variant="pageWrapper">{children}</Container>
    <UpdateDocumentTitle title={pageTitle} />
  </>
);
