import { useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout, useSiteGroupContext } from "base/components";
import { Button, Text } from "common/guideline";
import { MachineView } from "machine/components";
import { MapOverlay } from "operations/components/MapOverlay";
import { OperationsMap } from "operations/components/OperationsMap";

import { breadcrumbs } from "./breadcrumbs";

const ViewWithMap = () => {
  const [isMapOpen, setIsMapOpen] = useState(false);

  return (
    <div tw="flex h-full relative">
      <Button tw="absolute top-2.5 right-2.5 z-[1]" onClick={() => setIsMapOpen(!isMapOpen)}>
        <Text tKey={isMapOpen ? "operations.closeMap" : "operations.openMap"} />
      </Button>

      {isMapOpen ? <OperationsMap /> : <MapOverlay />}
    </div>
  );
};

export const CashOperations = () => {
  const context = useSiteGroupContext();

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="operations.cashOps.title"
      subtitle="operations.cashOps.desc"
      withPicker
      slim
    >
      {context.machine ? <MachineView /> : <ViewWithMap />}
    </PageLayout>
  );
};
