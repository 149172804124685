import { useMemo, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { cancelConfigurationDeployment, cancelSoftwareInstallation } from "administration/helpers";
import { ReactComponent as EyeSVG } from "assets/icons/Eye.svg";
import { NodeStatusBadge } from "base/components";
import {
  Badge,
  Button,
  CommonCells,
  FullLine,
  Spinner,
  Table,
  TableTypes,
  Text,
  Tooltip,
  accessors,
  localFilters,
  usePagination,
} from "common/guideline";
import { withDefault } from "common/helpers";
import { MachineViewOrderColumn, useGenerateMachineViewTabQuery } from "generated";
import { useTranslation } from "i18n";
import { LicenseInfo } from "machine/components/Licensing/LicenseInfo";
import { useColumnFilters } from "report/components";
import { useSorting } from "report/hooks";

import { MachineVerticalHeader } from "./MachineVerticalHeader";
import { RowActions } from "./RowActions";
import { MachineViewData } from "./types";

type OperationStatusProps = {
  nodeId: string;
  version?: string | null;
  state?: string;
  operation: string;
};

const CurrentVersion: React.FC<OperationStatusProps> = (current: OperationStatusProps) => {
  const currentVersion = current?.version || `machine.remoteOperationMgmt.statuses.UNKNOWN`;

  return (
    <>
      <Badge variant={["text", "neutral"]}>
        <span>
          <Text tKey="machine.remoteOperationMgmt.version" />
          {": "}
          <Text>{currentVersion}</Text>
        </span>
      </Badge>
    </>
  );
};

const OngoingOperation: React.FC<OperationStatusProps> = (ongoingOperation: OperationStatusProps) => {
  const versionBeingInstalled = ongoingOperation?.version || "machine.remoteOperationMgmt.unknown";
  const state = ongoingOperation?.state || "UNKNOWN";
  const stateLabel = `machine.remoteOperationMgmt.statuses.${state}`;
  const [disabled, setDisabled] = useState(false);

  return ongoingOperation.nodeId ? (
    <>
      <FullLine />
      <Badge variant={["text", "info"]} tw="mt-2">
        <span tw="mr-4">
          <Text>{versionBeingInstalled}</Text>
          <span tw="flex mt-2">
            <Text>{stateLabel}</Text> <Spinner />
          </span>
        </span>
        <span tw="align-top">
          <Button
            variant={["primary", "sm"]}
            tw="transition-all duration-300 hover:text-primary-default mt-1"
            disabled={disabled}
            onClick={async () => {
              const result =
                ongoingOperation.operation === "deployment"
                  ? await cancelConfigurationDeployment(ongoingOperation.nodeId)
                  : await cancelSoftwareInstallation(ongoingOperation.nodeId);
              if (result) {
                setDisabled(true);
              }
            }}
          >
            <Text tKey="machine.remoteOperationMgmt.cancel" />
          </Button>
        </span>
      </Badge>
    </>
  ) : null;
};

const columns: TableTypes.TranslatedColumns<MachineViewData> = (t) => [
  {
    header: t("machine.name"),
    id: "MACHINE_NAME",
    accessorFn: (r) => withDefault(r.machine?.name),
    meta: {
      ...localFilters.getTextBaseFilter.meta,
      hideVertical: true,
    },
  },
  {
    header: t("machine.uuid"),
    id: "MACHINE_ID",
    accessorFn: (r) => withDefault(r.machine?.uuid),
  },
  {
    header: t("location.location_one"),
    id: "LOCATION_NAME",
    accessorFn: (r) => withDefault(r.location?.name),
  },
  {
    header: t("machine.nodeStatus"),
    id: "MACHINE_NODE_STATUS",
    accessorFn: (r) => withDefault(r.machine?.nodeStatus),
    cell: NodeStatusBadge,
    enableSorting: false,
  },
  {
    header: t("machine.machineType_one"),
    id: "MACHINE_TYPE",
    accessorFn: (r) => withDefault(r.machine?.machineType?.name),
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.preview"),
    id: "MACHINE_IMAGE_URL",
    accessorFn: (r) => r.machine?.machineType?.imageUrl,
    enableSorting: false,
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      return (
        <div tw="inline-flex">
          <Tooltip
            content={
              <img
                tw="max-w-xs border-2 border-primary-default rounded-lg shadow-blue"
                src={getValue<string | undefined>() ?? "../../img/placeholder.png"}
                alt="PayComplete"
              />
            }
          >
            <EyeSVG width={16} height={16} />
          </Tooltip>
        </div>
      );
    },
  },
  {
    header: t("machine.changed"),
    id: "MACHINE_DATE_TIME",
    enableSorting: false,
    accessorFn: (r) => accessors.date(r.machine?.nodeChangeDate, t),
  },
  {
    header: t("machine.licensing.title_singular"),
    id: "MACHINE_LICENSE",
    minSize: 270,
    cell({ row }) {
      return row.original.license ? (
        <LicenseInfo
          edition={row.original.license.edition || ""}
          licenseKey={row.original.license.licenseKey as any}
          active={row.original.license.subscriptionActive || false}
        />
      ) : (
        <Text tKey="machine.licensing.edition.basic" />
      );
    },
  },
  {
    header: t("machine.remoteOperationMgmt.configuration"),
    id: "MACHINE_CONFIGURATION",
    minSize: 270,
    cell({ row }) {
      return (
        <div>
          <CurrentVersion
            nodeId={row.original.templateDeployment?.nodeId || ""}
            version={row.original.templateDeployment?.template?.version || ""}
            state={row.original.templateDeployment?.state || ""}
            operation="deployment"
          />
          <OngoingOperation
            nodeId={row.original.ongoingDeployment?.nodeId || ""}
            version={row.original.ongoingDeployment?.template?.version || ""}
            state={row.original.ongoingDeployment?.state || ""}
            operation="deployment"
          />
        </div>
      );
    },
  },
  {
    header: t("machine.remoteOperationMgmt.software"),
    id: "MACHINE_SOFTWARE",
    minSize: 270,
    cell({ row }) {
      return (
        <div>
          <CurrentVersion
            nodeId={row.original.softwareInstalled?.nodeId || ""}
            version={row.original.softwareInstalled?.softwarePackage?.version}
            state={row.original.softwareInstalled?.state || ""}
            operation="installation"
          />
          <OngoingOperation
            nodeId={row.original.ongoingSoftwareInstallation?.nodeId || ""}
            version={row.original.softwareInstalled?.softwarePackage?.version}
            state={row.original.ongoingSoftwareInstallation?.state || ""}
            operation="installation"
          />
        </div>
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const noOngoingOperations = (rows) => {
  const noOngoingDeployments = rows?.every((r) => !r?.ongoingDeployment);
  const noOngoingInstallations = rows?.every((r) => !r?.ongoingSoftwareInstallation);

  return noOngoingDeployments && noOngoingInstallations;
};

const columnFiltersData = [["MACHINE_NAME", "MACHINE_NAME"]] as const;

export const MachineTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(10);
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [filter, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<MachineViewOrderColumn>();
  const {
    previousData,
    data = previousData,
    loading,
    error,
    stopPolling,
    startPolling,
  } = useGenerateMachineViewTabQuery({
    variables: {
      input: {
        skip: pageIndex * pageSize,
        limit: pageSize,
        machineViewTabType: "ALL",
        order,
        orderColumn,
        // only one filter for now, wait till beckand will allow filter by multiple columns at a same time
        machineViewFilteringField: filter.MACHINE_NAME
          ? {
              filteringField: "MACHINE_NAME",
              value: filter.MACHINE_NAME,
            }
          : undefined,
      },
    },
    onCompleted: (result) => {
      const doStopPolling: boolean = noOngoingOperations(result.generateMachineViewTab?.rows);
      setTimeout(() => {
        startPolling(8000);
        if (doStopPolling) stopPolling();
      }, 200);
    },
  });

  const fullSize = data?.generateMachineViewTab?.fullSize || 0;

  return (
    <Table
      tableName="machines"
      columns={tColumns}
      data={(data?.generateMachineViewTab?.rows as MachineViewData[]) || []}
      VerticalHeader={MachineVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      onPagination={setPagination}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={fullSize}
      columnFilters={columnFilters}
      onFilter={setColumnFilters}
      sorting={sorting}
      onSorting={setSorting}
    />
  );
};
