import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllMachineUsersDocument, MachineUserXmlImport, useImportMachineUsersMutation } from "generated";
import { getSelectMachinesField } from "machine/components";

import { UsersFileSelection } from "./UsersFileSelection";

export type UploadUsersFormData = MachineUserXmlImport;

const Wrapper = styled.div`
  ${tw`grid gap-4 grid-flow-row pt-4`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      getSelectMachinesField({ name: "machineNodeIds" }),
      {
        name: "usersXml",
        type: "custom",
        Component: UsersFileSelection,
        validate: { type: "string", required: [true, "mu.noFileSelected"] },
      },
      {
        name: "replace",
        type: "checkbox",
        defaultValue: false,
        label: "mu.replaceUsers",
      },
    ],
  },
];

type Props = { onSubmit: OnSubmit<UploadUsersFormData> };

const UploadForm: React.FC<Props> = ({ onSubmit }) => (
  <>
    <Text variant="heading" tKey="mu.uploadUsers" tw="pb-4 block" />
    <div tw="h-full overflow-x-hidden">
      <SchemaForm<UploadUsersFormData>
        fields={fields}
        onSubmit={onSubmit}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end">
            <Button type="submit" tw="mt-6" data-test="submitForm">
              <Text tKey="mu.uploadUsers" />
            </Button>
          </div>
        )}
      />
    </div>
  </>
);

export const UploadUsersForm = () => {
  const showToast = (error: boolean) =>
    useToast.actions.show(error ? "mu.uploadFailure" : "mu.uploadSuccess", {
      variant: error ? "error" : "success",
    });

  const [uploadUsers] = useImportMachineUsersMutation();
  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"] },
    Component: (
      <UploadForm
        onSubmit={(machineUserImport) =>
          uploadUsers({
            variables: { machineUserImport },
            ignoreResults: true,
            update: (cache) => evictQuery(FindAllMachineUsersDocument, cache),
          })
            .then((v) => {
              showToast(!(v.data?.importMachineUsers === true));
              toggle();
            })
            .catch(() => useToast.actions.show(true))
        }
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showFormModal">
      <Text tKey="mu.uploadUsers" />
    </Button>
  );
};
