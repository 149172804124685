import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CopySVG } from "assets/icons/Copy.svg";
import { PageLayout } from "base/components";
import { Badge, FullLine, Text, useToast } from "common/guideline";
import { copyToClipboard, withDefault } from "common/helpers";
import { PrettyJsonObject } from "machine/components/PrettyJsonObject";

import type { Machine } from "./MachineView";

type Props = {
  machine: Machine;
};

export const MachineInformation: React.FC<Props> = ({ machine }) => {
  const devices = machine.details?.devices || [];
  const capabilities = withDefault(machine.details?.capabilities?.join(", "));
  const machineUrl = machine?.machineType?.imageUrl || "";
  const machineName = machine?.machineType?.name || "";

  return (
    <PageLayout title="machine.information" subtitle="machine.info.desc">
      <img
        tw="border-2 border-primary-default rounded-lg shadow-blue mb-4 max-w-xs"
        src={machineUrl ?? "../../img/placeholder.png"}
        alt={machineName ?? "PayComplete"}
      />
      {devices.length === 0 ? (
        <Text tKey="machine.info.devInfoMissing" />
      ) : (
        devices.map((device) => (
          <div
            key={device?.nodeId}
            tw="grid [grid-template-columns:max-content_1fr] [grid-gap:12px] pb-4 text-sm text-gray-9"
          >
            <FullLine />
            <Text variant="labelAlt" tKey="machine.info.deviceId" tValue={{ colon: true }} />
            <div tw="w-auto inline-flex items-center gap-2">
              <Badge variant="info">{withDefault(device?.id)}</Badge>
              <CopySVG
                tw="cursor-pointer"
                width={20}
                height={20}
                onClick={() => copyToClipboard(device?.id || "", () => useToast.actions.show("common.clipboard"))}
              />
            </div>
            <Text variant="labelAlt" tKey="machine.info.capabilities" tValue={{ colon: true }} />
            <span>{capabilities}</span>
            <FullLine />
            <div tw="col-span-2 flex gap-10">
              <div tw="grid grid-cols-2 gap-2">
                <Text variant="labelAlt" tKey="machine.info.firmware" tValue={{ colon: true }} />
                {device?.deviceInfo?.firmware ? (
                  <PrettyJsonObject data={device?.deviceInfo?.firmware} />
                ) : (
                  <span>-</span>
                )}
                <Text variant="labelAlt" tKey="machine.info.accType" tValue={{ colon: true }} />
                <span>{withDefault(device?.deviceInfo?.accType)}</span>
                <Text variant="labelAlt" tKey="machine.info.protocol" tValue={{ colon: true }} />
                <span>{withDefault(device?.deviceInfo?.protocol)}</span>
                <Text variant="labelAlt" tKey="machine.info.hardware" tValue={{ colon: true }} />
                <span>{withDefault(device?.deviceInfo?.hardware)}</span>
                <Text variant="labelAlt" tKey="machine.info.name" tValue={{ colon: true }} />
                <span>{withDefault(device?.deviceInfo?.name)}</span>
                <Text variant="labelAlt" tKey="machine.info.nodeId" tValue={{ colon: true }} />
                <span>{withDefault(device?.deviceInfo?.nodeId)}</span>
                <Text variant="labelAlt" tKey="machine.info.serialNumber" tValue={{ colon: true }} />
                <span>{withDefault(device?.deviceInfo?.serialNumber)}</span>
                <Text variant="labelAlt" tKey="machine.info.version" tValue={{ colon: true }} />
                <span>{withDefault(device?.deviceInfo?.version)}</span>
              </div>
              <div tw="grid grid-cols-2 gap-2">
                <Text variant="labelAlt" tKey="machine.sensor.firmware" tValue={{ colon: true }} />
                {device?.deviceInfo?.firmware ? (
                  <PrettyJsonObject data={device?.deviceInfo?.firmware} />
                ) : (
                  <span>-</span>
                )}
                <Text variant="labelAlt" tKey="machine.sensor.accType" tValue={{ colon: true }} />
                <span>{withDefault(device?.sensorInfo?.accType)}</span>
                <Text variant="labelAlt" tKey="machine.sensor.protocol" tValue={{ colon: true }} />
                <span>{withDefault(device?.sensorInfo?.protocol)}</span>
                <Text variant="labelAlt" tKey="machine.sensor.hardware" tValue={{ colon: true }} />
                <span>{withDefault(device?.sensorInfo?.hardware)}</span>
                <Text variant="labelAlt" tKey="machine.sensor.name" tValue={{ colon: true }} />
                <span>{withDefault(device?.sensorInfo?.name)}</span>
                <Text variant="labelAlt" tKey="machine.sensor.nodeId" tValue={{ colon: true }} />
                <span>{withDefault(device?.sensorInfo?.nodeId)}</span>
                <Text variant="labelAlt" tKey="machine.sensor.serialNumber" tValue={{ colon: true }} />
                <span>{withDefault(device?.sensorInfo?.serialNumber)}</span>
                <Text variant="labelAlt" tKey="machine.sensor.version" tValue={{ colon: true }} />
                <span>{withDefault(device?.sensorInfo?.version)}</span>
              </div>
            </div>
          </div>
        ))
      )}
    </PageLayout>
  );
};
