import { Eye } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { ActionButton } from "base/components";
import { Button, TableTypes } from "common/guideline";
import { downloadLogArchive, getLogArchive } from "zts/helpers/fileAccess";
import { LogFetched } from "zts/types";

import { useDeleteLogModal } from "./components";
import { useConfirmDownloadModal } from "./components/ConfirmDownload";
import { useLogViewerModal } from "./components/LogViewer";

const browseLogArchive = async (zipFileName: string | null | undefined) => {
  await getLogArchive(zipFileName).then((logArchive) => {
    useLogViewerModal(logArchive);
  });
};

export const RowActions: React.FC<TableTypes.VerticalHeaderProps<LogFetched>> = ({ row }) => {
  const [showDelete] = useDeleteLogModal(row.original as WithoutNullableKeys<LogFetched>);
  const [showConfirmDownload] = useConfirmDownloadModal(row.original.zipFileName);
  const tooLarge = row.original.size && row.original.size > 2000;

  return (
    <div tw="overflow-x-auto inline-flex items-start">
      <Button
        variant={["smSquare", "ghost"]}
        tw="text-gray-6 transition-all duration-300 hover:text-primary-default"
        onClick={() => (tooLarge ? showConfirmDownload() : browseLogArchive(row.original.zipFileName))}
        data-test="browseLogArchive"
      >
        <Eye size={18} weight="duotone" />
      </Button>
      <ActionButton.Download onClick={() => downloadLogArchive(row.original.zipFileName)} />
      <ActionButton.Delete onClick={() => showDelete()} />
    </div>
  );
};
