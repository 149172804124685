import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindMachinesEligibleToAttachToMgQuery } from "generated";

type AvailableMachineOption = {
  label: string;
  value: string;
};

const useConfig: SelectLiveFieldUseConfig = (previous) => {
  const [machineData = previous, { loading }] = useMappedQuery((d) => {
    const options: AvailableMachineOption[] =
      d?.findMachinesEligibleToAttachToMG
        ?.flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "" }] : []))
        .sort(makeSorter("label")) || [];

    return {
      ...previous,
      isLoading: false,
      options,
    };
  }, useFindMachinesEligibleToAttachToMgQuery());

  return [machineData, !loading];
};

export const getAvailableMachinesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "administration.mg.availableMachines",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    useConfig,
  );
