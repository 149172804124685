export const PrettyJsonObject: React.FC<{ data: string | null | undefined }> = ({ data }) =>
  data ? (
    <pre>
      {Object.entries(JSON.parse(data)).map(([key, val]) => (
        <span key={key}>
          <span>{`${key}:\n`}</span>
          {Object.entries((val ?? {}) as Record<string, any>).map(([subKey, subVal]) => (
            <span key={subKey}>
              <span>{`${subKey}: ${subVal}\n`}</span>
            </span>
          ))}
        </span>
      ))}
    </pre>
  ) : null;
