import { CaretRight } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { TableTypes, Text, useModal } from "common/guideline";
import { withDefault } from "common/helpers";

import { MachineUserData } from "./types";

const UserDetailsModal: React.FC<TableTypes.VerticalHeaderProps<MachineUserData>> = ({ row }) => (
  <div>
    <div tw="flex justify-between pr-6 mb-2">{row.original.name}</div>
    <div tw="grid text-xs [grid-template-columns:max-content_1fr] [grid-gap:12px] justify-items-start">
      <Text tKey="mu.name" />
      <span>{row.original.name}</span>
      <Text tKey="mu.roleName" />
      <span>{withDefault(row.original.roleName)}</span>
      <Text tKey="mu.userId" />
      <span>{withDefault(row.original.userId)}</span>
    </div>
  </div>
);

const openUserDetailModal = (row: TableTypes.Row<MachineUserData>) =>
  useModal.actions.open({ Component: <UserDetailsModal row={row} />, closeOnRouteChange: true });

export const MachineUserVerticalHeader: React.FC<TableTypes.VerticalHeaderProps<MachineUserData>> = (props) => (
  <div tw="flex justify-between pb-4 items-center">
    <div tw="flex">
      <span>{props.row.original.name}</span>
      <CaretRight size={24} weight="duotone" tw="text-gray-6" onClick={() => openUserDetailModal(props.row)} />
    </div>
  </div>
);
