export const en = {
  location: {
    location_one: "Site",
    location_other: "Sites",
    useLocation: "Use this site",
    cancel: "Cancel",
    siteDetails: "Site details",
    chooseOnMap: "Choose on map",
    longitude: "Longitude",
    latitude: "Latitude",
    customerReference: "Customer reference",
    description: "Description",
    geographicalLocation: "Geographical location",
    country: "Country",
    countryCode: "Country code",
    city: "City",
    region: "State | Province | County",
    zipCode: "ZIP code",
    title: "Sites",
    create: "Create site",
    createDescription: "You create a site to set up a new cash management system",
    update: "Update site",
    updateDescription: "You update a site to edit an existing cash management system",
    delete: "Delete site",
    deleteDescription: "You delete a site when you want to delete an existing cash management system",
    deleteClose: "Close",
    roleName: "Role",
    name: "Site name",
    siteId: "Site ID",
    nodeStatus: "Status",
    nodeStatusCount: "Status count",
    nodeChangeDate: "Changed",
    success: "Site created successfully",
    error: "Site creation failed",
    updateSuccess: "Site updated successfully",
    updateApiError: "Site update failed",
    deleteSuccess: "Site deleted successfully",
    deleteError: "Site deletion failed",
    goBack: "Back",
    mustBeNumeric: "Value must be a numeric type",
    filterSites: "Filter sites",
    list: {
      noData: "No sites found",
    },
  },
};
