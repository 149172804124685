import {
  Column,
  Header,
  PaginationState,
  TableOptions,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { useState } from "react";

import { strictString } from "common/helpers";

import { sortingFns } from "./sortingFns";
import { TableProps } from "./types";

export const getColumnName = (column: Column<any, unknown>) =>
  column.columnDef.meta?.headerName || strictString(column.columnDef.header, column.id);

export const usePagination = (pageSize = 10, pageIndex = 0) => useState<PaginationState>({ pageSize, pageIndex });

export const getCellStyle = (header: Column<any, unknown> | Header<any, unknown>) => {
  const width = header.getSize();
  return { width, flexGrow: width };
};

export const getTableConfig = ({
  onSorting,
  onPagination,
  onFilter,
  sorting,
  columnFilters,
  totalCount = 0,
  pageSize = 0,
  pageIndex = 0,
  hasSubRows,
  defaultColumn,
}: Pick<
  TableProps<any>,
  "onSorting" | "onPagination" | "onFilter" | "sorting" | "columnFilters" | "totalCount" | "pageIndex" | "pageSize"
> & { hasSubRows: boolean; defaultColumn?: TableOptions<any>["defaultColumn"] }) => {
  const result: Omit<TableOptions<any>, "data" | "columns"> = {
    defaultColumn,
    sortingFns,
    paginateExpandedRows: false,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (r) => Boolean(r.subRows?.length || hasSubRows),
    getSubRows: (row: any) => row.subRows,
    autoResetPageIndex: false,
  };

  if (onSorting) {
    if (!result.state) result.state = {};

    result.state.sorting = sorting;
    result.manualSorting = true;
    result.onSortingChange = onSorting as any;
  } else {
    result.getSortedRowModel = getSortedRowModel();
  }

  if (onPagination) {
    if (!result.state) result.state = {};

    const pagination = { pageIndex, pageSize };
    result.state.pagination = pagination;
    result.manualPagination = true;
    result.onPaginationChange = onPagination as any;
    result.pageCount = Math.ceil(totalCount / (pagination.pageSize || 1));
  } else {
    result.getPaginationRowModel = getPaginationRowModel();
  }

  if (onFilter) {
    if (!result.state) result.state = {};

    result.state.columnFilters = columnFilters;
    result.manualFiltering = true;
    result.onColumnFiltersChange = onFilter as any;
  } else {
    result.getFilteredRowModel = getFilteredRowModel();
  }

  return result;
};
