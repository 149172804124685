import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as AlertsHistorySVG } from "assets/icons/AlertsHistory.svg";
import { useAuth } from "auth/hooks";
import { PageLayout, useMappedSiteGroupContext } from "base/components";
import { Table, TableTypes, accessors, useDateRangeFilter, usePagination } from "common/guideline";
import { AlertHistoryOrderColumn, FindAlertsByCriteriaQuery, useFindAlertsByCriteriaQuery } from "generated";
import { useTranslation } from "i18n";
import { FilterBox } from "report/components";
import { useSorting } from "report/hooks";

import { AlertLevelBadge } from "../../components";

import { breadcrumbs } from "./breadcrumbs";

type Alert = NonNullable<NonNullable<NonNullable<FindAlertsByCriteriaQuery["findAlertsByCriteria"]>["rows"]>[0]>;

const getColumns: TableTypes.TranslatedColumns<Alert> = (t) => [
  {
    header: t("alerts.date"),
    id: "DATE_TIME",
    accessorFn: (v) => accessors.date(v.dateTime, t),
  },
  {
    header: t("machine.machine_one"),
    id: "MACHINE_NAME",
    accessorKey: "machineName",
  },
  {
    header: t("location.location_one"),
    id: "LOCATION_NAME",
    accessorKey: "locationName",
  },
  {
    header: t("alerts.level"),
    id: "ALERT_LEVEL",
    accessorKey: "alertLevel",
    cell({ getValue }) {
      return <AlertLevelBadge value={getValue<Alert["alertLevel"]>()} />;
    },
  },
  {
    header: t("alerts.text"),
    id: "ALERT_TEXT",
    accessorKey: "alertText",
  },
  {
    header: t("alerts.type"),
    id: "TRIGGER_TYPE",
    accessorFn: (v) => t(`alerts.triggerType.${v.triggerType || "EMAIL"}`),
  },
  {
    header: t("alerts.contact"),
    id: "CONTACT",
    accessorKey: "contact",
  },
];

export const AlertsHistory = () => {
  const tenantId = useAuth(useAuth.actions.getUser)?.sub || "";
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination();
  const [{ from, to }, DateRange] = useDateRangeFilter();
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<AlertHistoryOrderColumn>();
  const [{ location, machine }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAlertsByCriteriaQuery({
    variables: {
      input: {
        tenantId,
        fromDate: from ? new Date(from).toISOString().replace("Z", "") : from,
        toDate: to ? new Date(to).toISOString().replace("Z", "") : to,
        limit: pageSize,
        skip: pageIndex * pageSize,
        locationNodeIds: location,
        machineNodeIds: machine,
        order,
        orderColumn,
      },
    },
  });

  const rows = (data?.findAlertsByCriteria?.rows ?? []) as Alert[];
  const fullSize = data?.findAlertsByCriteria?.fullSize ?? 0;

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="alerts.history"
      subtitle="alerts.historyDescription"
      Icon={AlertsHistorySVG}
    >
      <FilterBox>{DateRange}</FilterBox>
      <Table
        tableName="alertsHistory"
        data={rows}
        columns={columns}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPagination={setPagination}
        totalCount={fullSize}
        error={error}
        loading={loading}
        sorting={sorting}
        onSorting={setSorting}
        initialLoading={previousData === undefined}
      />
    </PageLayout>
  );
};
