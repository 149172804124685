import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: string; label: string }[] = [
  { value: "administrator", label: "mu.administrator" },
  { value: "cashier", label: "mu.cashier" },
  { value: "cit", label: "mu.cit" },
];

export const getMachineUserRoleField = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "mu.role",
    options,
    ...values,
  });
