import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { WithVariants, applyVariant } from "./helpers";

const variants = {
  neutral: tw`bg-gray-3 text-gray-10 border border-gray-4`,
  error: tw`bg-error-alt text-error-default`,
  success: tw`bg-success-alt text-success-default`,
  warning: tw`bg-warning-alt text-warning-default`,
  info: tw`bg-primary-accent text-primary-default`,
  outlineNeutral: tw`border border-gray-6`,
  text: tw`[text-transform:none]`,
  sm: tw`text-tiny`,
};

type Props = Omit<JSX.IntrinsicElements["span"], "ref"> & WithVariants<typeof variants>;

export const Badge = styled.span<Props>`
  ${tw`inline-flex px-2 py-0.5 items-center justify-center rounded text-xs font-semibold uppercase`}
  ${applyVariant(variants, "neutral")}
`;
