import { isValid } from "date-fns";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getAvailableConfigTemplatesField } from "administration/pages/ConfigTemplates/components";
import { getAvailableSoftwarePackagesField } from "administration/pages/SoftwarePackages/components";
import { getFormTitle } from "base/fields";
import { CustomRenderFields } from "common/form/renderFields";
import { getRemoteMgmtOptions, getRemoteMgmtTimeOptions } from "common/remoteMgmt";

const Wrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

export const getRemoteMgmtSection: () => CustomRenderFields[] = () => [
  {
    type: "container",
    Component: getFormTitle("common.remoteMgmt.title"),
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      getRemoteMgmtOptions({
        name: "remoteMgmtOption",
        label: "common.remoteMgmt.selectOperation",
        defaultValue: "skip",
      }),
      {
        type: "condition",
        when: "remoteMgmtOption",
        is: (value) => value === "configuration",
        fields: [getAvailableConfigTemplatesField({ name: "templateNodeId" })],
      },
      {
        type: "condition",
        when: "remoteMgmtOption",
        is: (value) => value === "software",
        fields: [getAvailableSoftwarePackagesField({ name: "softwarePackageNodeId" })],
      },
      {
        type: "condition",
        when: "remoteMgmtOption",
        is: (value) => value === "configuration" || value === "software",
        fields: [
          getRemoteMgmtTimeOptions({
            name: "timeOption",
            defaultValue: "now",
            calculation: {
              updates: {
                scheduleInfo: (v) => (v === "now" ? null : new Date()),
              },
            },
          }),
          {
            type: "condition",
            when: "timeOption",
            is: (si) => si === "specify",
            fields: [
              {
                type: "date",
                name: "scheduleInfo",
                label: "common.remoteMgmt.dateAndTime",
                fromDate: new Date(),
                dateFormat: "PPpp",
                defaultValue: new Date(),
                validate: (v) => (v ? (isValid(v) ? null : "common.form.err.invalidDate") : "common.form.err.required"),
              },
            ],
          },
        ],
      },
    ],
  },
];
