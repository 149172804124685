import { Plus, Trash } from "phosphor-react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { useFieldArray } from "common/form";
import { CustomRenderFields } from "common/form/renderFields";
import { Button, Text } from "common/guideline";

const Wrapper = styled.div`
  ${tw`flex-1`}
`;

const RecipientsComponent: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div>
    <Text tKey="alerts.recipients" tw="block pb-2" />
    <div tw="grid [grid-template-columns:max-content_1fr_max-content] gap-2 items-end">{children}</div>
  </div>
);

const Row: React.FC<React.PropsWithChildren<{ fieldArrayName: string; index: number }>> = ({
  children,
  fieldArrayName,
  index,
}) => {
  const {
    fields: { length },
    remove,
    append,
  } = useFieldArray(fieldArrayName);

  return (
    <>
      {children}
      <div tw="flex gap-2">
        {length > 1 && (
          <Button variant={["error", "smSquare"]} onClick={() => remove(index)} data-test={`removeRecipient-${index}`}>
            <Trash weight="duotone" />
          </Button>
        )}
        {index + 1 === length ? (
          <Button variant={["primary", "smSquare"]} onClick={() => append({})} data-test={`addRecipient-${index}`}>
            <Plus weight="duotone" />
          </Button>
        ) : null}
      </div>
    </>
  );
};

export const recipientsField: CustomRenderFields = {
  type: "array",
  name: "recipients",
  Component: RecipientsComponent,
  Row,
  defaultValue: [{}],
  validate: {
    type: "array",
    minLength: 1,
    of: { type: "object", shape: { contact: { type: "string", required: true } } },
  },
  fields: [
    {
      type: "select",
      name: "triggerType",
      label: "alerts.type",
      defaultValue: "EMAIL",
      options: [
        {
          label: "alerts.triggerType.EMAIL",
          value: "EMAIL",
        },
        {
          label: "alerts.triggerType.SMS",
          value: "SMS",
        },
        {
          label: "alerts.triggerType.WEBHOOK",
          value: "WEBHOOK",
        },
      ],
    },
    {
      type: "container",
      Component: Wrapper,
      fields: [
        {
          type: "text",
          name: "contact",
          label: "alerts.contact",
        },
      ],
    },
  ],
};
