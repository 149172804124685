export const en = {
  zts: {
    title: "Remote device management",
    commands: {
      title: "Commands",
      command: "Command",
      desc: "Execute a command on the selected machine",
      executeCommand: "Execute command",
      execute: "Execute",
      commandHistory: "History of commands executed on the selected machine",
      started: "Started",
      ended: "Ended",
      status: "Status",
      parameters: "Parameters",
      executedBy: "Executed by",
      deleteSuccess: "Successfully deleted the command",
      deleteError: "An error when attempting to delete the command.",
      deleteTitle: "Delete command from history",
      deleteDesc: "Command will be deleted from history, proceed?",
      close: "Close",
      submit: "Delete",
      selectCommand: "Select command",
      restart: "Restart machine",
      reboot: "Reboot machine",
      temporarilyOffline: "Machine will be temporarily offline, ok?",
      restartSuccess: "Request to restart machine sent, please wait a few minutes.",
      rebootSuccess: "Request to reboot machine sent, please wait a few minutes.",
      restartFailure: "Request to restart machine failed, please try again in a few minutes.",
      rebootFailure: "Request to reboot machine failed, please try again in a few minutes.",
      noTypesSelected: "No types selected",
      statuses: {
        InProgress: "In progress",
        Restarting: "Restarting",
        Rebooting: "Rebooting",
        Failure: "Failed",
        FailedToClaim: "Machine was busy",
        Canceled: "Canceled",
        Ok: "OK",
        Timeout: "Timed out",
      },
      logs: {
        title: "Logs",
        logsDesc: "Retrieve and view logs for the selected machine",
        retrieveLogs: "Retrieve logs",
        retrievedLogs: "Retrieved log archives",
        retrieveLogsModalTitle: "Retrieve logs for selected machine",
        retrieveLogsDesc: "Select period and which log types.",
        viewLogs: "View logs",
        from: "From",
        to: "To",
        fromMustBeSmallerThanTo: "Must be before to",
        toMustBeGreaterThanFrom: "Must be after from",
        retrievalDate: "Retrieval date",
        file: "File",
        fileSize: "Size",
        noFiles: "Number of files",
        retrievedBy: "Retrieved by",
        profiling: "Profiling",
        configuration: "Configuration",
        reports: "Reports",
        history: "History",
        receipts: "Receipts",
        icx: "ICX",
        data: "Data",
        devices: "Devices",
        online: "Online",
        trace: "Trace",
        logTypes: "Log types",
        fullSystemDump: "Full system dump?",
        retrieveLogsSuccess: "Retrieve logs command sent, please refresh this view in a few minutes.",
        retrieveLogsFailure: "An error occurred when sending the command to retrieve logs.",
        deleteSuccess: "Successfully deleted the log archive",
        deleteError: "An error when attempting to delete the log archive.",
        deleteTitle: "Delete log archive",
        deleteDesc: "Metadata and the uploaded log archive will be deleted, proceed?",
        missingPreSignedUrl: "Could not obtain presigned url",
        selectLogFile: "Select log file",
        fileTooLarge: "File is too large!",
        fileTooLargeDesc: "File is too large, do you wish to download instead?",
        noLogsToShow: "An error has occured, or there are no logs to show.",
        err: {
          _1: "Unknown error. Please try again later.",
          _2: "Must be a zip file.",
        },
      },
    },
    remoteUpgrade: {
      upgradeHistory: "Upgrade history",
      upgradeHistoryDesc: "History of software installations on the selected machine",
      timeOfUpgrade: "Upgrade started",
      name: "Name",
      label: "Label",
      softwareVersion: "Software version",
      revision: "Revision",
    },
    remoteConfiguration: {
      configurationHistory: "Configuration history",
      configurationHistoryDesc: "History of configuration deployments on the selected machine",
      scheduledAt: "Scheduled at",
      description: "Description",
    },
    result: "Status",
    name: "Name",
    executedBy: "Executed by",
    latestStatusChange: "Status changed",
  },
};
