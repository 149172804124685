import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { NamesByIds, PageLayout, useMappedSiteGroupContext } from "base/components";
import {
  Breadcrumb,
  CommonCells,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  localFilters,
  useDateFilter,
} from "common/guideline";
import { GenerateOpenShiftsReportQuery, useGenerateOpenShiftsReportQuery } from "generated";
import { FilterBox } from "report/components";
import { getTotalAmountsPairRows } from "report/helpers";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "cashMgmt.title",
    route: "CASH_MANAGEMENT",
  },
  {
    text: "report.openShifts.title",
    route: "OPEN_SHIFTS",
  },
];

type Row = NonNullable<NonNullable<NonNullable<GenerateOpenShiftsReportQuery["generateOpenShiftsReport"]>["rows"]>[0]>;

type TransactionInRow = NonNullable<NonNullable<Row["subRows"]>[0]>;

type OpenShiftRow = Row & TransactionInRow;

const getColumns: TableTypes.TranslatedColumns<OpenShiftRow> = (t) => [
  CommonCells.expander,
  {
    header: t("report.openShifts.cashierId"),
    id: "MACHINE_USER_IDS",
    accessorKey: "userId",
    footer: t("report.total"),
    ...localFilters.getSelectBaseFilter(true),
  },
  {
    header: t("report.openShifts.cashierName"),
    id: "MACHINE_USER_NAMES",
    accessorKey: "userName",
    ...localFilters.getSelectBaseFilter(true),
  },
  {
    header: t("report.openShifts.role"),
    id: "ROLE",
    accessorKey: "role",
    ...localFilters.getSelectBaseFilter(true),
  },
  {
    header: t("report.openShifts.dateTime"),
    accessorFn: (v) => accessors.date(v.accountingDate, t),
  },
  {
    header: t("report.openShifts.machineDateTime"),
    accessorFn: (v) => accessors.date(v.dateTime, t),
  },
  {
    header: t("machine.machine_one"),
    accessorKey: "machineNodeId",
    cell({ getValue }) {
      return <NamesByIds entity="machines" ids={getValue<string[]>() || ""} />;
    },
  },
  {
    header: t("location.location_one"),
    accessorKey: "locationNodeId",
    cell({ getValue }) {
      return <NamesByIds entity="locations" ids={getValue<string[]>() || ""} />;
    },
  },
  {
    header: t("report.openShifts.transactionType"),
    accessorKey: "type",
  },
];

const toDate = new Date();

export const OpenSifts = () => {
  const { t, i18n } = useTranslation();
  const [referenceDate, DateFilter] = useDateFilter({ label: "common.rDate", dateFormat: "PPp", toDate });
  const [{ location, siteGroup, machine }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateOpenShiftsReportQuery({
    variables: {
      input: { referenceDate, locationNodeIds: location ? location : siteGroup, machineNodeIds: machine },
    },
  });

  const rows = data?.generateOpenShiftsReport?.rows as OpenShiftRow[] | undefined;
  // const rows = [{ userId: "12312" }] as OpenShiftRow[] | undefined;
  const totalAmounts = data?.generateOpenShiftsReport?.totalAmounts;

  const columns = useMemo(
    () => [
      ...getColumns(t, i18n.language),
      ...getTotalAmountsPairRows(rows || [], t, {
        footer: (i) => i.column.accessorFn?.({ totalAmounts }, i.header.index),
      }),
    ],
    [rows, t, i18n.language, totalAmounts],
  );

  return (
    <PageLayout title="report.openShifts.title" subtitle="report.openShifts.desc" breadcrumbs={breadcrumbs} withPicker>
      <FilterBox>{DateFilter}</FilterBox>
      <Table
        tableName="openShifts"
        data={rows || []}
        columns={columns}
        loading={loading}
        error={error}
        initialLoading={previousData === undefined}
        actions={<TableDownload title="report.openShifts.title" disabled={!rows?.length} getCsv="withSubRows" />}
      />
    </PageLayout>
  );
};
