import { CoreInstance, PaginationInstance } from "@tanstack/react-table";
import { CaretLeft, CaretRight, DownloadSimple } from "phosphor-react";
import { memo, useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { InputRaw, SelectRaw } from "common/form/renderFields";
import { createSuspenseComponent, getLazyComponent } from "common/helpers";
import { useNonInitialEffect } from "common/hooks";

import { Button } from "../Button";
import { Line } from "../Line";
import { Popover } from "../Popover";
import { Text } from "../Text";

import type { ColumnsManipulateProps } from "./ColumnsManipulate";

const ColumnsManipulate = createSuspenseComponent(
  getLazyComponent(() => import("./ColumnsManipulate"), "ColumnsManipulate"),
);

const StyledPagination = styled.div<Pick<Props, "isVertical">>`
  ${tw`rounded-lg border border-gray-3 h-full overflow-hidden flex flex-col flex-1`}

  & > div:first-of-type {
    ${tw`flex justify-between items-center py-2 px-4 text-sm text-gray-6 border-b border-gray-3`}
    ${({ isVertical }) => isVertical && tw`px-2`}
  }

  & > div:last-of-type {
    ${tw`flex justify-end items-center gap-x-2 py-2 px-4 text-sm border-t border-gray-3`}
    ${({ isVertical }) => isVertical && tw`justify-center`}
  }
`;

const PAGE_SIZES = [10, 50, 100, 200].map((n) => ({ value: n, label: n }));

type Props = Pick<
  PaginationInstance<any>,
  | "getPageCount"
  | "setPageIndex"
  | "setPageSize"
  | "previousPage"
  | "getCanPreviousPage"
  | "nextPage"
  | "getCanNextPage"
> &
  ColumnsManipulateProps & {
    totalCount: number;
    isVertical?: boolean;
    getState: CoreInstance<any>["getState"];
    actions?: React.ReactNode;
  };

const getPage = (page: number) => page + 1 || 1;

export const PaginationRaw: React.FC<React.PropsWithChildren<Props>> = ({
  getPageCount,
  setPageIndex,
  totalCount,
  setPageSize,
  previousPage,
  getCanPreviousPage,
  nextPage,
  getCanNextPage,
  isVertical,
  children,
  getAllLeafColumns,
  setColumnOrder,
  getState,
  actions,
}) => {
  const pageCount = getPageCount();
  const { pageSize, pageIndex } = getState().pagination;
  const [page, setPage] = useState(() => getPage(pageIndex));
  const onPageChange = () => (page > 0 ? setPageIndex(page - 1) : setPage(getPage(pageIndex)));

  useNonInitialEffect(() => {
    setPage(getPage(pageIndex));
  }, [pageIndex]);

  useNonInitialEffect(() => {
    setPage(1);
  }, [pageSize]);

  return (
    <StyledPagination isVertical={isVertical}>
      <div>
        <Text
          tw="text-xs"
          tKey="common.table.pagination.results"
          tValue={{
            from: pageSize * pageIndex + 1,
            to: Math.min(totalCount, pageSize * (pageIndex + 1)),
            count: totalCount,
          }}
        />

        <div tw="flex items-center gap-x-2">
          <Text tw="text-xs hidden sm:inline-flex" tKey="common.table.pagination.perPage" />

          <SelectRaw
            tw="w-18"
            value={pageSize}
            name="pageSize"
            options={PAGE_SIZES}
            onChange={setPageSize}
            variant="sm"
          />

          <ColumnsManipulate getAllLeafColumns={getAllLeafColumns} setColumnOrder={setColumnOrder} />
          {actions && (
            <Popover content={actions} overflowContainer>
              <DownloadSimple size={28} />
            </Popover>
          )}
        </div>
      </div>

      {children}

      <div>
        <Button
          variant={["ghost", "withIcon", "sm"]}
          onClick={previousPage}
          disabled={!getCanPreviousPage()}
          data-test="previousPage"
        >
          <CaretLeft tw="text-primary-default" weight="bold" size="18" />
          {!isVertical && <Text tKey="common.table.pagination.previous" tw="text-xs" />}
        </Button>
        <Line variant="vertical" />

        <InputRaw
          name="page"
          variant={["square", "sm"]}
          type="number"
          min={1}
          max={pageCount}
          value={page}
          onChange={(e) => {
            const nextPage = e.target.value ? Math.min(pageCount, Number(e.target.value)) : e.target.value;
            setPage(nextPage);
          }}
          onBlur={onPageChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") onPageChange();
          }}
        />

        <Text tw="text-gray-6 text-xs" tKey="common.table.pagination.outOf" />
        <span>{pageCount}</span>

        <Line variant="vertical" />
        <Button
          variant={["ghost", "withIcon", "sm"]}
          onClick={nextPage}
          disabled={!getCanNextPage()}
          data-test="nextPage"
        >
          {!isVertical && <Text tKey="common.table.pagination.next" tw="text-xs" />}
          <CaretRight tw="text-primary-default" weight="bold" size="18" />
        </Button>
      </div>
    </StyledPagination>
  );
};

export const Pagination = memo(PaginationRaw);
