import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ActionButton } from "base/components";
import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text, Tooltip, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllMachineUserGroupsDocument,
  MachineUserGroupDtoIn,
  useCreateMachineUserGroupMutation,
  useUpdateMachineUserGroupMutation,
} from "generated";
import { getSelectMachinesField } from "machine/components";
import { getSelectMachineUserField } from "machineUser/components";

type FormData = MachineUserGroupDtoIn & { nodeId: string };

const fields: CustomRenderFields[] = [
  {
    type: "text",
    name: "name",
    label: "machineUserGroup.name",
    validate: { type: "string", required: true },
  },
  getSelectMachineUserField({ name: "machineUserNodeIds" }),
  getSelectMachinesField({ name: "machineNodeIds" }),
];

type Props = {
  onSubmit: OnSubmit<FormData>;
};

type UpdateProps = {
  title: TKeys;
  onSubmit: OnSubmit<FormData>;
  initialData: Partial<FormData>;
  fields: CustomRenderFields[];
};

const MachineUserGroupUpdateFrom: React.FC<UpdateProps> = ({ onSubmit, fields, initialData, title }) => (
  <>
    <Text variant="heading" tKey={title} tw="pb-4 block" />
    <div tw="h-full overflow-auto">
      <SchemaForm<FormData>
        fields={fields}
        onSubmit={onSubmit}
        initial={initialData}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end mt-6">
            <FormData type="isSubmittig">
              {(isLoading) => (
                <LoadingButton type="submit" data-test="submitForm" isLoading={isLoading} disabled={isLoading}>
                  <Text tKey="machineUserGroup.update" />
                </LoadingButton>
              )}
            </FormData>
          </div>
        )}
      />
    </div>
  </>
);

const MachineUserGroupFrom: React.FC<Props> = ({ onSubmit }) => (
  <>
    <Text variant="heading" tKey="machineUserGroup.addMachineUserGroups" tw="pb-4 block" />
    <div tw="h-full overflow-auto">
      <SchemaForm<FormData>
        fields={fields}
        onSubmit={onSubmit}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end mt-6">
            <FormData type="isSubmittig">
              {(isLoading) => (
                <LoadingButton type="submit" data-test="submitForm" isLoading={isLoading} disabled={isLoading}>
                  <Text tKey="machineUserGroup.create" />
                </LoadingButton>
              )}
            </FormData>
          </div>
        )}
      />
    </div>
  </>
);

export const UpdateName: React.FC<Pick<FormData, "name" | "nodeId">> = (data) => {
  const [updateName] = useUpdateMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <MachineUserGroupUpdateFrom
        title="machineUserGroup.updateName"
        fields={[fields[0]]}
        initialData={data}
        onSubmit={({ name }) => {
          updateName({
            variables: { input: { name }, nodeId: data.nodeId },
            ignoreResults: true,
          })
            .then(() => {
              useToast.actions.show("machineUserGroup.updateSuccess", { variant: "success" });
              toggle();
            })
            .catch(() => useToast.actions.show("machineUserGroup.updateError", { variant: "error" }));
        }}
      />
    ),
  });

  return (
    <Tooltip content="Update Name">
      <ActionButton.Edit onClick={() => toggle()} />
    </Tooltip>
  );
};

// TODO wait for an API to allow add and remove in one mutation
// export const UpdateMachineUsers: React.FC<Pick<FormData, "machineUserNodeIds" | "nodeId">> = (data) => {
//   const [toggle] = useModalComponent({
//     modalProps: { variant: ["modal", "md"], backdropClose: false },
//     Component: (
//       <SmartMachineUserGroupUpdateFrom
//         title="machineUserGroup.updateMachineUsers"
//         fields={[fields[1]]}
//         initialData={data}
//         onSubmit={(input) => {
//           console.log(input);
//         }}
//       />
//     ),
//   });

//   return (
//     <Tooltip content="Update machine users">
//       <ActionButton.Edit onClick={() => toggle()} />
//     </Tooltip>
//   );
// };

// export const UpdateMachines: React.FC<Pick<FormData, "machineNodeIds" | "nodeId">> = (data) => {
//   const [toggle] = useModalComponent({
//     modalProps: { variant: ["modal", "md"], backdropClose: false },
//     Component: (
//       <SmartMachineUserGroupUpdateFrom
//         title="machineUserGroup.updateMachines"
//         fields={[fields[2]]}
//         initialData={data}
//         onSubmit={(input) => {
//           console.log(input);
//         }}
//       />
//     ),
//   });

//   return (
//     <Tooltip content="Update machines">
//       <ActionButton.Edit onClick={() => toggle()} />
//     </Tooltip>
//   );
// };

export const CreateMachineUserGroup = () => {
  const [createGroup] = useCreateMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <MachineUserGroupFrom
        onSubmit={(input) => {
          createGroup({
            variables: { input },
            ignoreResults: true,
            update: (cache) => evictQuery(FindAllMachineUserGroupsDocument, cache),
          })
            .then(() => {
              useToast.actions.show("machineUserGroup.addSuccess", { variant: "success" });
              toggle();
            })
            .catch(() => useToast.actions.show("machineUserGroup.addError", { variant: "error" }));
        }}
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showFormModal">
      <Text tKey="machineUserGroup.addMachineUserGroups" />
    </Button>
  );
};
