import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ActionButton } from "base/components";
import { useModalComponent } from "common/guideline";

import { DeleteMachineGroup } from "../components";

import { RowMachineGroupDataProps } from "./types";

export const RowActions: React.FC<RowMachineGroupDataProps> = ({ row }) => {
  const [show] = useModalComponent({
    Component: <DeleteMachineGroup nodeId={row.original.nodeId as string} name={row.original.name as string} />,
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
      <Link route="MACHINE_GROUP_UPDATE" params={{ nodeId: row.original.nodeId as string }}>
        <ActionButton.Edit />
      </Link>
    </div>
  );
};
