import { subYears } from "date-fns";
import { Warning, WarningOctagon } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { InfoState, PageLayout } from "base/components";
import { Line, Text } from "common/guideline";
import { withDefault } from "common/helpers";
import { GenerateMachineErrorsReportQuery, useGenerateMachineErrorsReportQuery } from "generated";

import { RmSeverityBadge } from "../RmSeverityBadge";

import type { Machine } from "./MachineView";

type MachineRows = NonNullable<
  NonNullable<NonNullable<GenerateMachineErrorsReportQuery["generateMachineErrorsReport"]>["rows"]>[0]
> & { __typename: "ErrorsReportRowDto" };

type Props = {
  nodeId: Machine["nodeId"];
};

const fromDate = subYears(new Date(), 1).toISOString();
const toDate = new Date().toISOString();

const ErrorList: React.FC<Props> = ({ nodeId }) => {
  const { data, loading } = useGenerateMachineErrorsReportQuery({
    skip: !nodeId,
    variables: {
      input: {
        skip: 0,
        limit: 10,
        order: "DESC",
        fromDate,
        toDate,
        machineNodeIds: [nodeId || ""],
      },
    },
  });

  const errors = data?.generateMachineErrorsReport?.rows as MachineRows[] | undefined;

  return errors ? (
    <div>
      <ul tw="space-y-4">
        {errors.map((error, i) => (
          <li key={error.nodeId}>
            {i !== 0 && <Line tw="mb-4" />}

            <div tw="flex">
              {error.severity === "Error" ? (
                <WarningOctagon size={28} weight="duotone" tw="text-error-default" />
              ) : (
                <Warning size={28} weight="duotone" tw="text-warning-default" />
              )}

              <div tw="flex flex-col flex-1 pl-2">
                <div tw="flex justify-between pb-2">
                  <span>
                    <RmSeverityBadge value={error.severity} /> {error.kind}
                  </span>
                  <div tw="text-sm whitespace-nowrap text-gray-8">
                    <Text tKey="dateFormat" tValue={{ date: new Date(error.dateTime), formatString: "PPp" }} />
                  </div>
                </div>

                <Text variant="paragraph" tKey={withDefault(error.information)} />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : loading ? null : (
    <InfoState state="info" tKey="machine.noErrorsFound" />
  );
};

export const MachineHealth: React.FC<Props> = (props) => (
  <PageLayout title="machine.health" subtitle="machine.machineHealthDescription">
    <ErrorList {...props} />
  </PageLayout>
);
