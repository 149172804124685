import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const ScheduledReports = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="scheduledReports" subtitle="report.scheduledReportsDescription" />
);
