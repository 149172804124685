import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";
import { Button, CommonCells, Table, TableTypes, Text, accessors, usePagination } from "common/guideline";
import { useGetAllLogsQuery } from "generated";
import { useTranslation } from "i18n";
import { LogFetched } from "zts/types";

import { useCommandModal } from "../components/CommandForm";

import { RowActions } from "./RowActions";

const getColumns: TableTypes.TranslatedColumns<LogFetched> = (t) => [
  {
    header: t("zts.commands.logs.retrievalDate"),
    accessorFn: (v) => accessors.date(v.retrievalDate, t),
    minSize: 170,
  },
  {
    header: t("zts.commands.logs.from"),
    accessorFn: (v) => accessors.date(v.fromDate, t),
    minSize: 170,
  },
  {
    header: t("zts.commands.logs.to"),
    accessorFn: (v) => accessors.date(v.toDate, t),
    minSize: 170,
  },
  {
    header: t("zts.commands.logs.fileSize"),
    accessorKey: "size",
    width: 100,
  },
  {
    header: t("zts.commands.logs.noFiles"),
    accessorKey: "numberOfLogfiles",
  },
  {
    header: t("zts.commands.logs.retrievedBy"),
    accessorKey: "retrievedBy",
    width: 120,
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const CommandForm = ({ machine }) => {
  const [show] = useCommandModal(machine.nodeId);

  return (
    <Button onClick={() => show()} data-test="showCommandModal">
      <Text tKey="zts.commands.logs.retrieveLogs" />
    </Button>
  );
};

const LogsTable = ({ machine }) => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(10);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGetAllLogsQuery({
    variables: {
      machineNodeId: machine.nodeId,
      cpPageRequest: {
        page: pageIndex,
        size: pageSize,
      },
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const rows = (data?.getAllLogs || []) as LogFetched[];

  return (
    <Table<LogFetched>
      tableName="logs"
      columns={columns}
      data={rows}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      onPagination={setPagination}
      pageIndex={pageIndex}
      pageSize={pageSize}
    />
  );
};

export const Logs = ({ machine }) => (
  <PageLayout
    title="zts.commands.logs.retrievedLogs"
    subtitle="zts.commands.logs.logsDesc"
    actions={<CommandForm machine={machine} />}
  >
    <LogsTable machine={machine} />
  </PageLayout>
);
