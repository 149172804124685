import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { NodeStatusBadge } from "base/components";
import { CommonCells, Table, TableTypes, Tooltip, accessors, usePagination } from "common/guideline";
import { getMinMaxDates } from "common/helpers";
import { useFindAllMachineGroupsQuery } from "generated";
import { dateI18n, useTranslation } from "i18n";
import { MachinesList } from "machine/components";

import { MachineGroupVerticalHeader } from "./MachineGroupVerticalHeader";
import { RowActions } from "./RowActions";
import { MachineGroupData } from "./types";

const columns: TableTypes.TranslatedColumns<MachineGroupData> = (t, lang) => [
  {
    header: t("administration.mg.name"),
    accessorKey: "name",
    aggregate: "uniqueCount",
    Aggregated: (d) => t("administration.mg.namesCount", { count: d.value }),
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("administration.mg.nodeStatus"),
    accessorKey: "nodeStatus",
    aggregate: "count",
    Aggregated: (d) => t("administration.mg.nodeStatusCount", { count: d.value }),
    cell: NodeStatusBadge,
  },
  {
    header: t("administration.mg.nodeChangeDate"),
    accessorFn: (d) => accessors.date(d.nodeChangeDate, t),
    aggregate: (l) => getMinMaxDates(l.flat().map((d) => dateI18n.parse(d, "PPpp", new Date(), { lang }).getTime())),
    Aggregated: ({ value }) => t("dateFormat", { date: value, formatString: "PPperiod" }),
  },
  {
    header: t("administration.mg.machines"),
    accessorKey: "machineNodeIds",
    aggregate: (l) => l.flat(),
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      const value = (getValue<MachineGroupData["machineNodeIds"]>() || []) as string[];
      const machinesCount = value?.length || 0;

      return (
        <div tw="inline-block">
          <Tooltip content={<MachinesList nodeIds={value} />} data-test="showMachines">
            <div tw="flex items-center text-primary-default">
              <ComputerTowerSVG width={18} height={18} tw="mr-2" />
              {machinesCount}
            </div>
          </Tooltip>
        </div>
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

export const MachineGroupTable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(10);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllMachineGroupsQuery({
    variables: {
      cpPageRequest: {
        page: pageIndex,
        size: pageSize,
      },
    },
  });

  return (
    <Table
      tableName="machineGroups"
      columns={tColumns}
      data={(data?.findAllMachineGroups || []) as MachineGroupData[]}
      VerticalHeader={MachineGroupVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      onPagination={setPagination}
      pageIndex={pageIndex}
      pageSize={pageSize}
    />
  );
};
