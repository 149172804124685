import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";
import { useEffect, useLayoutEffect } from "react";

import { useLocalStorage } from "common/hooks";

import type { TableContextType } from "./TableContext";

type StorageState = { columnOrder: ColumnOrderState; columnVisibility: VisibilityState };

const getStorageName = (tableName: string) => `__t_${tableName}__`;

export const TableStorageConfig: React.FC<{ tableContext: TableContextType }> = ({ tableContext }) => {
  const { getState, setState, tableName, getAllLeafColumns } = tableContext;
  const { columnOrder, columnVisibility } = getState();
  const [storageValue, setStorage] = useLocalStorage<StorageState>(
    { columnOrder, columnVisibility },
    getStorageName(tableName),
  );

  useEffect(() => {
    setStorage({ columnOrder, columnVisibility });
  }, [columnOrder, columnVisibility, setStorage]);

  useLayoutEffect(() => {
    const columns = getAllLeafColumns();
    const left = columns.flatMap((c) => (c.columnDef.meta?.pin === "left" ? c.id : []));
    const right = columns.flatMap((c) => (c.columnDef.meta?.pin === "right" ? c.id : []));

    setState((p) => ({ ...p, columnPinning: { left, right }, ...storageValue }));
    // only on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
