import { ApolloCache, DocumentNode } from "@apollo/client";
import { getOperationName as getName } from "@apollo/client/utilities";

import { omitByKey } from "./object";

export const getOperationName = (doc: DocumentNode) => getName(doc) || undefined;

export const evictQuery = (query: DocumentNode, cache: ApolloCache<any>) => {
  const fieldName = getOperationName(query);
  if (fieldName) cache.evict({ id: "ROOT_QUERY", fieldName });
};

export const omitTypenames = omitByKey("__typename");
