import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout, ValueWidget, useMappedSiteGroupContext } from "base/components";
import {
  Breadcrumb,
  CommonCells,
  Table,
  TableDownload,
  TableTypes,
  Text,
  useDateFilter,
  useSelectFilter,
} from "common/guideline";
import {
  ClaimedValuesGroupFactor,
  GenerateClaimedValuesReportQuery,
  useGenerateClaimedValuesReportQuery,
} from "generated";
import { FilterBox } from "report/components";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "cashMgmt.title",
    route: "CASH_MANAGEMENT",
  },
  {
    text: "report.claimedValues.title",
    route: "CLAIMED_VALUES",
  },
];

type ClaimedValueRow = NonNullable<
  NonNullable<NonNullable<GenerateClaimedValuesReportQuery["generateClaimedValuesReport"]>["rows"]>[0]
>;

const getColumns: TableTypes.TranslatedColumns<ClaimedValueRow> = (t) => [
  CommonCells.expander,
  {
    header: t("machine.machine_one"),
    accessorKey: "machine",
  },
  {
    header: t("location.location_one"),
    accessorKey: "site",
  },
  {
    header: t("report.type"),
    accessorKey: "type",
  },
  {
    header: t("report.container"),
    accessorKey: "container",
  },
  {
    header: t("report.currency"),
    accessorKey: "currency",
  },
  {
    header: t("report.denomination"),
    accessorKey: "denomination",
  },
  {
    header: t("report.count"),
    accessorKey: "count",
  },
  {
    header: t("report.total"),
    accessorKey: "total",
  },
];

const toDate = new Date();
const groupFactorOptions: { value: ClaimedValuesGroupFactor; label: string }[] = [
  { value: "MACHINE", label: "machine.machine_one" },
  { value: "SITE", label: "location.location_one" },
  { value: "TYPE", label: "report.type" },
];

export const ClaimedValues = () => {
  const { t, i18n } = useTranslation();
  const [referenceDate, DateFilter] = useDateFilter({ label: "common.rDate", dateFormat: "PP", toDate });
  const [groupFactor = "MACHINE", SelectFilter] = useSelectFilter<ClaimedValuesGroupFactor>({
    label: "common.table.groupBy",
    initial: "MACHINE",
    options: groupFactorOptions,
    name: "groupFactor",
  });
  const [{ location, siteGroup }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateClaimedValuesReportQuery({
    variables: { input: { referenceDate, groupFactor, locationNodeIds: location ? location : siteGroup } },
  });

  const rows = (data?.generateClaimedValuesReport?.rows || []) as ClaimedValueRow[];
  const totalAmounts = data?.generateClaimedValuesReport?.totalAmounts;
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);

  return (
    <PageLayout
      title="report.claimedValues.title"
      subtitle="report.claimedValues.desc"
      breadcrumbs={breadcrumbs}
      withPicker
    >
      <FilterBox>
        {DateFilter}
        <div tw="min-w-[150px]">{SelectFilter}</div>
      </FilterBox>
      <Table
        tableName="claimedValues"
        data={rows}
        columns={columns}
        loading={loading}
        error={error}
        initialLoading={previousData === undefined}
        actions={<TableDownload title="report.claimedValues.title" disabled={!rows.length} getCsv />}
      />
      {totalAmounts?.length ? (
        <div tw="text-xs pt-4">
          <Text tKey="report.total" variant="heading" tValue={{ colon: true }} />
          <div tw="grid [grid-template-columns:repeat(auto-fill,_minmax(230px,_1fr))] gap-4 pt-4">
            {totalAmounts?.map((t, i) => (
              <ValueWidget
                key={i}
                title="report.totalAmount"
                tValue={{ currency: t?.key }}
                value={t?.value ? Number(t?.value) : 0}
              />
            ))}
          </div>
        </div>
      ) : null}
    </PageLayout>
  );
};
