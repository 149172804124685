import { useCallback } from "react";

import { FieldDataKey, useFieldData } from "../field";

export type ConditionProps = React.PropsWithChildren<{
  when: string;
  is: string | number | ((v: any) => boolean);
  scope?: FieldDataKey;
}>;

export const Condition: React.FC<ConditionProps> = ({ when, is, children, scope = "values" }) => {
  const checker = useCallback((value) => (typeof is === "function" ? is(value) : value === is), [is]);
  const condition = useFieldData(when, scope as any, checker);
  return condition ? <>{children}</> : null;
};
