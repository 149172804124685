import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindListOfUsersQuery } from "generated";

type UserRoleOption = {
  label: string;
  value: string;
};

const LIMIT = 1000;

const useConfig: SelectLiveFieldUseConfig = (previous) => {
  const domainName = (useFieldData("domainName", "values") || "") as string;
  const [users = previous, { loading }] = useMappedQuery(
    (d) => {
      const options: UserRoleOption[] =
        d?.findListOfUsers?.isUserDtoOut
          ?.flatMap((r) => (r?.userName ? [{ label: r.userName, value: r.userName }] : []))
          .sort(makeSorter("label")) || [];

      return {
        ...previous,
        isLoading: false,
        options,
      };
    },
    useFindListOfUsersQuery({
      variables: {
        domainName,
        limit: LIMIT,
        offset: 0,
      },
    }),
  );

  return [users, !loading];
};

export const getUsersField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "user.title_one",
      isLoading: true,
      isMulti: false,
      ...value,
    },
    useConfig,
  );
