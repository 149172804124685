import { ReactComponent as UsersSVG } from "assets/icons/Users.svg";
import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";
import { UserCreate } from "./UserCreate/UserCreate";
import { UsersTable } from "./UsersTable/UsersTable";

export const Users = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="user.title_other"
    subtitle="user.desc"
    Icon={UsersSVG}
    actions={<UserCreate />}
  >
    <UsersTable />
  </PageLayout>
);
