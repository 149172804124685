export const en = {
  machine: {
    machine_one: "Machine",
    machine_other: "Machines",
    machineStatus: "Machine status",
    deviceInfo: "Device details",
    sensorInfo: "Sensor details",
    name: "Name",
    noName: "Unnamed machine",
    contents: "Contents",
    machineContents: "Machine contents",
    machineContentsDescription: "Shows information on machine contents",
    usage: "Usage",
    machineUsage: "Machine usage",
    machineUsageDescription: "Shows information on the value of deposit and dispense transactions on the machine.",
    health: "Health",
    machineHealth: "Machine health",
    machineHealthDescription:
      "Displays latest 10 active errors on the machine, that is, errors that have not yet been cleared.",
    information: "Information",
    extendedInfo: "Extended information",
    errorCode: "Error code",
    cleared: "Cleared",
    refillStatus: "Refill status",
    refillStatusTooltip:
      "'$t(machine.refillStatuses.REFILL)' if any recycler denomination drops below the configured minimum amount. '$t(machine.refillStatuses.APPROACHING)' if any denomination drops below the configured default level. '$t(machine.refillStatuses.OK)' if there is no action required",
    machineAlias: "Machine alias",
    machineSn: "Machine S/N:",
    machineName: "Machine name",
    machineType_one: "Machine type",
    machineType_other: "Machine types",
    machinesCount: "Machines count",
    nodeStatus: "Status",
    nodeChangeDate: "Node change date",
    changed: "Changed",
    createMachine: "Create machine",
    createMachineDescription: "You create a machine when you want to create a new device to Connect",
    createMachineSuccess: "Machine created successfully",
    createMachineError: "Machine creation failed",
    editMachine: "Edit machine",
    updateMachine: "Update machine",
    editMachineDescription: "Please change machine details to edit them",
    editMachineSuccess: "Machine saved successfully",
    editMachineError: "Machine update failed",
    delete: "Delete",
    deleteMachine: "Delete machine",
    deleteDescription: "Machine <0>{{name}}</0> will be deleted permanently.",
    deleteSuccess: "Machine deleted successfully",
    deleteError: "Machine deletion failed",
    deleteClose: "Close",
    nodeId: "Node ID",
    dateTime: "Machine date / time",
    version: "Version",
    receiver: "Receiver",
    sequence: "Sequence",
    messageSequence: "Message sequence",
    messageType: "Message type",
    uuid: "Machine UUID",
    comment: "Comment",
    deviceId: "Device",
    deleted: "Deleted",
    detached: "Detached",
    inactive: "Inactive",
    active: "Active",
    unknown: "Unknown",
    online: "Online",
    offline: "Offline",
    refill: "Refill",
    ok: "OK",
    goBack: "Back",
    approaching: "Approaching",
    notAvailable: "N/A",
    kind: "Kind",
    resolved: "Resolved",
    device: "Device",
    filterMachines: "Filter machines",
    preview: "Preview",
    severity: "Severity",
    severityType: {
      Error: "Error",
      Warning: "Warning",
    },
    status: {
      CatchingUp: "Catching up",
      Offline: "Offline",
      Online: "Online",
      Unknown: "Unknown",
    },
    availableForDispense: "Available for dispense",
    availableForDispenseTooltip: "Displays the amount that can be dispensed from the machine",
    notDispensable: "Not dispensable",
    notDispensableTooltip: "Displays the bankable amount in the machine",
    availabilityRatio: "Dispense availability ratio",
    availabilityRatioTooltip: "The ratio of available cash for dispense to total cash, for the selected currency",
    openShifts: "Open shifts",
    cashInCirculation: "Cash in circulation",
    netCashChange: "Change in net cash",
    availableCashChange: "Change in available cash",
    notes: "Notes",
    notesTooltip: "Value of all bank notes currently in device for the selected currency",
    counterfeitNotes: "Counterfeit notes",
    counterfeitNotesTooltip: "Value of all notes that were detected as counterfeit for the selected currency",
    checks: "Checks",
    checksTooltip: "Total value of all checks for the selected currency",
    coins: "Coins",
    coinsTooltip: "The value of all coins currently in the device for the selected currency",
    suspectNotes: "Suspect notes",
    suspectNotesTooltip:
      "Value of all notes that were detected as suspect or did not achieve the fitness level as configured, for the selected currency",
    vouchers: "Vouchers",
    vouchersTooltip: "Total value of all vouchers for the selected currency",
    claimedValue: "Claimed value",
    claimedValueTooltip:
      "Value of manually entered items for the selected currency (generally placed in drop box or bag)",
    timelineExplorer: "Timeline explorer",
    timelineExplorerDescription: "Sorts the transactions (last 1,000 transactions are shown) by transaction date",
    noData: "No machines found",
    specName: "Name",
    licenseLabel: "License label",
    licenseLevel: "License level",
    noErrorsFound: "No errors found for this machine",
    showBy: "Show by",
    type: "Type",
    aboveDefault: "Above default levels",
    aboveMinimum: "Above minimum, but below default",
    belowMinimum: "Below mininum levels",
    totalDeposits: "Total deposits",
    totalDepositsTooltip: "Total value of all deposits since the start of day time.",
    totalDispenses: "Total dispenses",
    totalDispensesTooltip: "Total value of all dispenses since the start of day time.",
    changeNetCash: "Change in net cash",
    changeNetCashTooltip:
      "Difference between the cash available for dispense at the start of the day and the cumulative total of all cash deposits.  Positive value means you are generating cash and negative value means you are consuming cash.",
    changeAvailableCash: "Change in available cash",
    changeAvailableCashTooltip:
      "Difference between the cash available for dispense at the start of the day and the current cash available for dispense.",
    usageSinceMidnight: "Show usage since today at 00:00",
    devInfoMissing: "There are no connected machines",
    info: {
      deviceId: "Device ID",
      desc: "Shows miscellaneous information on the machine. For example, the machine type and activation date",
      devInfo: "Machine information",
      devInfoMissing: "There are no connected devices",
      firmware: "Firmware",
      hardware: "Hardware",
      accType: "Access type",
      protocol: "Protocol",
      name: "Name",
      nodeId: "Device info ID",
      serialNumber: "Serial number",
      version: "Version",
      capabilities: "Capabilities",
    },
    licensing: {
      title: "Licensing (optional)",
      title_singular: "License",
      currentLicense: "Current license",
      noLicenseId: "To upgrade license, the machine needs to be online.",
      keepBasic: "Keep basic license",
      upgrade: "Upgrade license",
      option: "Option",
      discountCode: "Discount code (optional)",
      costAcknowledgement: "I acknowledge that there may be a cost associated with this license upgrade.",
      agreementNeeded: "Agreement is needed.",
      currentlyActive: "Currently active",
      currentlyInactive: "Currently inactive",
      licenseKey: "License key",
      active: "Active",
      unclickToActivate: "License subscription is active, unclick to deactivate.",
      clickToActivate: "License subscription is currently deactivated, click to activate.",
      edition: {
        basic: "Basic license",
        enterpriseSubscription: "Enterprise subscription",
        unknown: "Unknown",
      },
    },
    sensor: {
      deviceId: "Device ID",
      desc: "Shows miscellaneous information on the machine. For example, the machine type and activation date",
      devInfo: "Machine information",
      devInfoMissing: "There are no connected devices",
      firmware: "Firmware",
      hardware: "Hardware",
      accType: "Access type",
      protocol: "Protocol",
      name: "Name",
      nodeId: "Sensor info ID",
      serialNumber: "Serial number",
      version: "Version",
      capabilities: "Capabilities",
    },
    refillStatuses: {
      APPROACHING: "Approaching",
      OK: "OK",
      REFILL: "Refill",
    },
    remoteOperationMgmt: {
      deployedConfiguration: "Deployed configuration",
      configuration: "Configuration",
      software: "Software",
      version: "Version",
      installing: "Installing",
      status: "Status",
      cancel: "Cancel",
      unknown: "Unknown",
      statuses: {
        CANCELED: "Canceled",
        CANCELING: "Canceling",
        DEPLOYING: "Sent",
        DONE: "Done",
        FAILEd: "Failed",
        INSTALLING: "Installing",
        PENDING: "Pending",
        SENT: "Sent",
        TIMEOUT: "Timeout",
        WAITING: "Waiting",
        UNKNOWN: "Unknown",
        undefined: "Unknown",
      },
    },
  },
};
