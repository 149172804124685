import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo } from "appRouting";
import { PageLayout } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllMachineUsersDocument, useCreateMachineUserMutation } from "generated";

import { breadcrumbs } from "./breadcrumbs";
import { MachineUserForm } from "./MachineUserForm";

export const MachineUserCreate = () => {
  const [createMachineUser] = useCreateMachineUserMutation();

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="mu.create" subtitle="mu.createDescription">
      <MachineUserForm
        submitLabel="mu.create"
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit={({ confirmPin, unlimitedNoLogins, nodeId, ...input }) =>
          createMachineUser({
            variables: { input },
            ignoreResults: true,
            update: (cache) => evictQuery(FindAllMachineUsersDocument, cache),
          })
            .then(() => {
              useToast.actions.show("mu.success", { variant: "success" });
              navigateTo({ route: "MACHINE_USERS" });
            })
            .catch(() => useToast.actions.show("mu.error", { variant: "error" }))
        }
      />
    </PageLayout>
  );
};
