import { TKeys } from "i18next";
import { Warning } from "phosphor-react";
import tw from "twin.macro";
import "styled-components/macro";

import { Button, Spinner, Text } from "common/guideline";

type Props = React.PropsWithChildren<{
  actionText: TKeys;
  loading?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}>;

export const EntityDelete: React.FC<Props> = ({ actionText, loading, onClose, onDelete, children }) => (
  <div>
    <div tw="flex items-center flex-col pb-8">
      <Warning weight="duotone" size={48} tw="text-error-default" />
      <Text tKey={actionText} variant="heading" />
      <div tw="pt-4 text-center">{children}</div>
    </div>
    <div tw="flex justify-end">
      {onClose && (
        <Button onClick={onClose} variant="ghost" tw="mr-4" disabled={loading} data-test="closeDeleteModal">
          <Text tKey="common.close" />
        </Button>
      )}
      <Button onClick={onDelete} variant="error" disabled={loading} data-test="submitDelete">
        {loading ? <Spinner /> : <Text tKey={actionText} />}
      </Button>
    </div>
  </div>
);
