import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { usePermissionAccess } from "auth/hooks";
import { NamesListTooltip, NodeStatusBadge } from "base/components";
import { CommonCells, Table, TableTypes, accessors } from "common/guideline";
import { useFindAllTenantsQuery } from "generated";
import { useTranslation } from "i18n";

import { RowActions } from "./RowActions";
import { TenantVerticalHeader } from "./TenantVerticalHeader";
import { TenantData } from "./types";

const getColumns: (showRowActions?: boolean) => TableTypes.TranslatedColumns<TenantData> = (showRowActions) => (t) => {
  const columns: TableTypes.ColumnWithSubAccessor<TenantData>[] = [
    {
      header: t("tenant.list.tenantId"),
      id: "tenantId",
      accessorKey: "tenantId",
    },
    {
      header: t("tenant.list.name"),
      accessorKey: "name",
      meta: {
        hideVertical: true,
      },
    },
    {
      header: t("tenant.list.status"),
      accessorKey: "nodeStatus",
      cell: NodeStatusBadge,
    },
    {
      header: t("tenant.list.changed"),
      accessorFn: (d) => accessors.date(d.nodeChangeDate, t),
    },
    {
      header: t("tenant.create.contractNumber"),
      id: "contractNumber",
      accessorKey: "contractNumber",
    },
    {
      header: t("tenant.create.endOfDay"),
      id: "endOfDay",
      accessorKey: "endOfDay",
    },
    {
      header: t("tenant.create.commonSoftwarePackageUploadEnabled"),
      id: "packageUploadEnabled",
      accessorKey: "commonSoftwarePackageUploadEnabled",
      cell: CommonCells.BooleanCell,
      size: 180,
    },
    {
      header: t("machine.machineType_other"),
      accessorKey: "machineTypes",
      enableSorting: false,
      cell({ getValue }) {
        const value = getValue<TenantData["machineTypes"]>() ?? [];
        return (
          <NamesListTooltip
            count={value.length}
            names={value.map((v) => v?.name as string)}
            Icon={ComputerTowerSVG}
            data-test="showMachineTypes"
          >
            -
          </NamesListTooltip>
        );
      },
    },
  ];

  if (showRowActions) {
    columns.push(CommonCells.getActionsCell<TenantData>({ size: 120, cell: (c) => <RowActions row={c.row} /> }));
  }

  return columns;
};

export const TenantTable = () => {
  const { t, i18n } = useTranslation();
  const showRowActions = usePermissionAccess("canUpdateTenant");
  const tColumns = useMemo(() => getColumns(showRowActions)(t, i18n.language), [t, i18n.language, showRowActions]);
  const { previousData, data = previousData, loading, error } = useFindAllTenantsQuery();
  const tenants = (data?.findAllTenants as TenantData[]) || [];

  return (
    <Table
      tableName="tenants"
      columns={tColumns}
      data={tenants}
      VerticalHeader={TenantVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};
