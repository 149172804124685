import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindUserRolesListQuery } from "generated";

import { getAccessTranslationString } from "../helpers";

type UserRoleOption = {
  label: string;
  value: string;
};

const LIMIT = 1000;

const useConfig: SelectLiveFieldUseConfig = (previous) => {
  const domainName = (useFieldData("domainName", "values") || "") as string;

  const [userRolesData = previous, { loading }] = useMappedQuery(
    (d) => {
      const options: UserRoleOption[] =
        d?.findUserRolesList?.userRoles
          ?.flatMap((r) => (r ? [{ label: getAccessTranslationString(r.name), value: r.name || "" }] : []))
          .sort(makeSorter("label")) || [];

      return {
        ...previous,
        isLoading: false,
        options,
      };
    },
    useFindUserRolesListQuery({
      variables: {
        domainName,
        limit: LIMIT,
        offset: 0,
      },
    }),
  );

  return [userRolesData, !loading];
};

export const getUserRolesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "user.roles",
      isLoading: true,
      isMulti: false,
      ...value,
    },
    useConfig,
  );
