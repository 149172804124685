import { useRef } from "react";

import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useMappedQuery } from "common/hooks";
import { useFindAllSoftwarePackagesQuery } from "generated";

type AvailableSoftwarePackageOption = {
  label: string;
  value: string;
};

const LIST_SIZE = 1000;

const useConfig: SelectLiveFieldUseConfig = (previous) => {
  const listSizeRef = useRef(LIST_SIZE);

  const [packageData = previous, { loading, refetch }] = useMappedQuery(
    (d) => {
      const options: AvailableSoftwarePackageOption[] =
        d?.findAllSoftwarePackages?.flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "" }] : [])) || [];

      return {
        ...previous,
        isLoading: false,
        options,
        onMenuScrollToBottom: () => {
          if (options.length === listSizeRef.current) {
            listSizeRef.current += LIST_SIZE;
            refetch({ cpPageRequest: { page: 0, size: listSizeRef.current } });
          }
        },
      };
    },
    useFindAllSoftwarePackagesQuery({
      variables: {
        cpPageRequest: {
          page: 0,
          size: listSizeRef.current,
        },
      },
    }),
  );

  return [packageData, !loading];
};

export const getAvailableSoftwarePackagesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "administration.sp.selectSoftwarePackage",
      isLoading: true,
      ...value,
    },
    useConfig,
  );
