import { performRemoteMgmtOperation } from "administration/helpers";
import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { Container, useToast } from "common/guideline";
import { useMappedQuery } from "common/hooks";
import {
  FindAllSmartLocationGroupsQuery,
  useFindAllSmartLocationGroupsQuery,
  useUpsertSmartLocationGroupMutation,
} from "generated";

import { LocationGroupForm, LocationGroupFormData } from "../components/LocationGroupForm";

import { breadcrumbs } from "./breadcrumbs_update";

export type LocationGroupUpdateParams = { nodeId: string };

type InitialValues = LocationGroupFormData | undefined;

const getInitialValues =
  (nodeId: string) =>
  ({ findAllSmartLocationGroups: LocationGroups }: FindAllSmartLocationGroupsQuery | undefined = {}): InitialValues => {
    const locationGroup = LocationGroups?.find((lg) => lg?.nodeId === nodeId);
    const lNodeIds = locationGroup?.locations?.map((l) => l?.nodeId).filter(Boolean) as string[] | undefined;

    if (locationGroup) {
      return {
        nodeId: locationGroup?.nodeId,
        name: locationGroup?.name,
        authorizedUsers: locationGroup?.authorizedUsers,
        countries: locationGroup?.countries,
        cities: locationGroup?.cities,
        locationNodeIds: lNodeIds,
      };
    }
  };

export const LocationGroupUpdate = () => {
  const { nodeId = "" } = useParams<LocationGroupUpdateParams>();
  const [updateLocationGroup] = useUpsertSmartLocationGroupMutation();

  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues(nodeId),
    useFindAllSmartLocationGroupsQuery(),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="administration.lg.update"
      subtitle="administration.lg.updateDescription"
    >
      <Container variant="pageWrapper">
        {loading ? null : (
          <LocationGroupForm
            submitLabel="administration.lg.update"
            initialData={mappedData}
            onSubmit={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              timeOption,
              remoteMgmtOption,
              templateNodeId,
              softwarePackageNodeId,
              scheduleInfo,
              ...smartGroup
            }) =>
              updateLocationGroup({
                variables: { smartGroup },
                ignoreResults: true,
              })
                .then(async (v) => {
                  if (!v.data?.upsertSmartLocationGroup?.nodeId) throw new Error();

                  const machineNodeIds = v.data?.upsertSmartLocationGroup?.locations?.map((l) => l?.machineNodeIds) as
                    | (string | null)[]
                    | null
                    | undefined;

                  useToast.actions.show("administration.lg.updateSuccess", { variant: "success" });

                  performRemoteMgmtOperation(
                    remoteMgmtOption,
                    machineNodeIds,
                    templateNodeId,
                    softwarePackageNodeId,
                    scheduleInfo,
                  );

                  navigateTo({ route: "LOCATION_GROUPS" });
                })
                .catch(() => useToast.actions.show("administration.lg.error", { variant: "error" }))
            }
          />
        )}
      </Container>
    </PageLayout>
  );
};
