import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PageLayout, useSiteGroupContext } from "base/components";
import { Breadcrumb, Table, TableDownload, TableTypes, accessors, useDateFilter } from "common/guideline";
import { GetMachinesOnlineStatusQuery, useGetMachinesOnlineStatusQuery } from "generated";
import { FilterBox } from "report/components";
import { useTenant } from "tenant/context";

import { StatusBadge } from "./components";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "machineManagement",
    route: "MACHINE_MANAGEMENT",
  },
  {
    text: "mosCurrent.title",
    route: "ONLINE_STATUS_CURRENT",
  },
];

type OnlineStatusType = NonNullable<
  NonNullable<NonNullable<GetMachinesOnlineStatusQuery["getMachinesOnlineStatus"]>>[0]
>;

const getColumns: TableTypes.TranslatedColumns<OnlineStatusType> = (t) => [
  {
    header: t("mos.machine"),
    id: "machineName",
    accessorKey: "machineName",
  },
  {
    header: t("mos.site"),
    id: "locationName",
    accessorKey: "locationName",
  },
  {
    header: t("mos.status"),
    id: "onlineStatus",
    accessorKey: "onlineStatus",
    cell: StatusBadge,
  },
  {
    header: t("mos.lastEvent"),
    id: "eventDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.date(v.eventDate, t),
    meta: {
      csv: {
        accessorKey: "eventDate",
      },
    },
  },
  {
    header: t("mos.lastReceived"),
    id: "receivedDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.date(v.receivedDate, t),
    meta: {
      csv: {
        accessorKey: "receivedDate",
      },
    },
  },
];

const toDate = new Date();

export const OnlineStatusCurrent = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [date, DateRange] = useDateFilter({ label: "common.rDate", dateFormat: "PPp", toDate });
  const { machine, location } = useSiteGroupContext();
  const tenantId = useTenant(useTenant.actions.getTenantId) || "";
  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useGetMachinesOnlineStatusQuery({
    variables: {
      input: {
        dateTime: date,
        tenantId,
        machineUuid: machine,
        locationId: location,
      },
    },
  });
  const rows = (data?.getMachinesOnlineStatus || []) as OnlineStatusType[];

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="mosCurrent.title"
      subtitle="mosCurrent.desc"
      withPicker
      filterByMachineUuid
    >
      <FilterBox>{DateRange}</FilterBox>
      <Table
        tableName="onlineStatus"
        columns={columns}
        data={rows}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        actions={<TableDownload title="mosCurrent.title" disabled={!rows.length} getCsv />}
      />
    </PageLayout>
  );
};
