import { TKeys } from "i18next";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { FormData, OnSubmit, SchemaForm } from "common/form";
import { BaseOption, CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text } from "common/guideline";
import { TIME_FORMAT, getDateFieldFormatHelper } from "common/helpers";
import { TenantDtoIn } from "generated";
import { getAllMachineTypesField } from "machine/fields";

export type TenantFormData = Omit<TenantDtoIn, "machineTypes"> & {
  machineTypes: BaseOption<TenantDtoIn["machineTypes"][0]>[];
  tenantId: string;
};

const Wrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "tenantId",
        label: "tenant.create.tenantId",
        validate: { type: "string", required: true },
        disabled: true,
      },
      {
        type: "text",
        name: "name",
        label: "tenant.create.name",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "contractNumber",
        label: "tenant.create.contractNumber",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "billingId",
        label: "tenant.create.billingId",
        validate: { type: "string", required: true },
      },
      getAllMachineTypesField({ name: "machineTypes", validate: { type: "array", required: true } }),
      {
        type: "date",
        name: "endOfDay",
        label: "tenant.create.endOfDay",
        dateFormat: TIME_FORMAT,
        hidePicker: true,
        ...getDateFieldFormatHelper(TIME_FORMAT),
      },
      {
        type: "checkbox",
        name: "commonSoftwarePackageUploadEnabled",
        label: "tenant.create.commonSoftwarePackageUploadEnabled",
      },
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<TenantFormData>;
  submitLabel: TKeys;
  initial?: TenantFormData;
};

export const TenantForm: React.FC<Props> = ({ onSubmit, submitLabel, initial }) => (
  <SchemaForm<TenantFormData>
    fields={fields}
    initial={initial}
    onSubmit={onSubmit}
    customRender={customRender}
    SubmitComponent={() => (
      <div tw="flex justify-between mt-6">
        <Button
          variant="side"
          onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "TENANT_LIST" })}
          data-test="goBack"
        >
          <Text tKey="tenant.goBack" />
        </Button>
        <FormData type="isSubmittig">
          {(isLoading) => (
            <LoadingButton type="submit" isLoading={isLoading} disabled={isLoading} data-test="submitForm">
              <Text tKey={submitLabel} />
            </LoadingButton>
          )}
        </FormData>
      </div>
    )}
  />
);
