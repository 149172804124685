import { ApolloProvider } from "@apollo/client";
import { StrictMode } from "react";

import "i18n";
import { HistoryRouter } from "appRouting";
import { SiteGroupContextProvider } from "base/components";
import { client } from "client";
import { ModalComponent, ModalProvider, ThemeProvider, ToastProvider } from "common/guideline";
import { Router } from "Router";

// TODO get rid off visual helper below, once development will be completed
// import "./debug.css";

export const App: React.FC = () => (
  <StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider>
        <HistoryRouter>
          <SiteGroupContextProvider>
            <Router />
            <ModalProvider DefaultWrapper={ModalComponent} />
            <ToastProvider />
          </SiteGroupContextProvider>
        </HistoryRouter>
      </ThemeProvider>
    </ApolloProvider>
  </StrictMode>
);
