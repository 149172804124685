import { navigateTo } from "appRouting";
import { Button, Text, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindListOfUsersDocument, useCreateUserMutation } from "generated";

import { UserForm } from "../components/UserForm";
import { modifyCreatePayload } from "../helpers";

export const UserCreate = () => {
  const [createUser] = useCreateUserMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <UserForm
        updateForm={false}
        submitLabel="user.createUser"
        onSubmit={(input) => {
          createUser({
            variables: { isUserDtoInDto: modifyCreatePayload(input) },
            ignoreResults: true,
            update: (cache) => evictQuery(FindListOfUsersDocument, cache),
          })
            .then(async (v) => {
              if (!v.data?.createUser) throw new Error();

              useToast.actions.show("user.createSuccess", { variant: "success" });

              navigateTo({ route: "USERS" });
            })
            .catch(() => useToast.actions.show("user.createError", { variant: "error" }));
        }}
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showUserFormModal">
      <Text tKey="user.createUser" />
    </Button>
  );
};
