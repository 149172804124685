import { addSeconds, isAfter } from "date-fns";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useCallback } from "react";
import create from "zustand";
import { persist } from "zustand/middleware";

import { client } from "client";
import { CONFIG } from "common/config";

// UserRole/global_admin - tenant creation, access to all tenants from all domains
// UserRole/global_support - cant create tenants, only updating them
// UserRole/admin - can create and update tenants for his domain
// UserRole/user
type Roles = "UserRole/global_admin" | "UserRole/global_support" | "UserRole/admin" | "UserRole/user";

// `ALL` for all or `string` is for particular entity nodeId
type PermissionsString =
  | `Permission/tenant:${"ALL" | string}`
  | `Permission/machine:${"ALL" | string}`
  | `Permission/siteGroup:${"ALL" | string}`
  | `Permission/site:${"ALL" | string}`;

type Groups = PermissionsString | Roles;

type UserFromToken = Required<JwtPayload> & {
  groups: Groups[]; // roles
  aut: string;
  azp: string;
  scope: "openid";
};

type Permissions = {
  tenantAll: boolean;
  machineAll: boolean;
  siteGroupAll: boolean;
  siteAll: boolean;
  canCreateTenant: boolean;
  canUpdateTenant: boolean;
  canTenantStatusChange: boolean;
  canTenantFeaturesChange: boolean;
};

type PermissionsByEntity = {
  tenant: string[];
  machine: string[];
  siteGroup: string[];
  site: string[];
};

type UserPermissionsAndRoles = {
  role: Roles | null;
  permissions: Permissions;
  permissionsByEntity: PermissionsByEntity;
};

type User = Omit<UserFromToken, "tenants"> & UserPermissionsAndRoles;

type TokenResponse = {
  access_token: string;
  refresh_token: string;
  scope: "openid";
  id_token: string;
  token_type: "Bearer";
  expires_in: number;
};

type ErrorResponse = {
  error: "invalid_grant" | string;
};

type AuthError = ErrorResponse & {
  // error to pass to translation
  tError: string;
};

const translatableErrors = ["invalid_grant"];

const getAuthErrorMessageKey = (errorMessage?: AuthError["error"]) =>
  errorMessage ? (translatableErrors.includes(errorMessage) ? `auth.err.${errorMessage}` : errorMessage) : "";

const getPermissionByEntity = (entity: keyof PermissionsByEntity, groups: Groups[]) =>
  groups
    .filter((g) => g.startsWith(`Permission/${entity}:`) && g !== `Permission/${entity}:ALL`)
    .map((g) => g.replace(`Permission/${entity}:`, ""));

const getUserPermissionsAndRoles = (groups: Groups[]): UserPermissionsAndRoles => ({
  role: (groups.find((g) => g.startsWith("UserRole/")) as Roles) ?? null,
  permissions: {
    tenantAll: groups.includes("Permission/tenant:ALL"),
    machineAll: groups.includes("Permission/machine:ALL"),
    siteGroupAll: groups.includes("Permission/siteGroup:ALL"),
    siteAll: groups.includes("Permission/site:ALL"),
    canCreateTenant: groups.some((g) => ["UserRole/global_admin"].includes(g)),
    canUpdateTenant: groups.some((g) =>
      ["UserRole/global_admin", "UserRole/global_support", "UserRole/admin"].includes(g),
    ),
    canTenantStatusChange: groups.some((g) =>
      ["UserRole/global_admin", "UserRole/global_support", "UserRole/admin"].includes(g),
    ),
    canTenantFeaturesChange: groups.some((g) => ["UserRole/global_admin", "UserRole/global_support"].includes(g)),
  },
  permissionsByEntity: {
    tenant: getPermissionByEntity("tenant", groups),
    machine: getPermissionByEntity("machine", groups),
    siteGroup: getPermissionByEntity("siteGroup", groups),
    site: getPermissionByEntity("site", groups),
  },
});

type AuthStore = {
  loading: boolean;
  isAuthenticated: boolean;
  hasError: boolean;
  user: null | User;
  error: null | AuthError;
  tokenData: null | TokenResponse;
  expiresAt: null | number;
};

type AuthStoreActions = {
  logIn: (data: { username: string; password: string }) => Promise<boolean>;
  logOut: () => Promise<void>;
  getToken: () => null | NonNullable<AuthStore["tokenData"]>["access_token"];
  getUser: () => AuthStore["user"];
  isRole: (role: Roles | Roles[]) => boolean;
  hasPermission(
    rolesToCheck: keyof User["permissions"] | Array<keyof User["permissions"]>,
    checker?: "some" | "every",
  ): boolean;

  // maybe in future
  // reauthenticate(): Promise<void>;
  // forgotPassword(username: string): Promise<any>;
  // resetPassword(options?: unknown): Promise<any>;
  // validateResetToken(resetToken: string | null): Promise<any>;
};

const STORAGE_KEY = "__au__";

export const useAuth = Object.assign(
  create(
    persist<AuthStore>(
      () => ({
        error: null,
        hasError: false,
        tokenData: null,
        loading: false,
        user: null,
        isAuthenticated: false,
        expiresAt: null,
      }),
      {
        name: STORAGE_KEY,
        partialize: (s) =>
          ({
            ...s,
            loading: false,
            error: null,
            hasError: false,
            user: s.user
              ? {
                  ...s.user,
                  ...getUserPermissionsAndRoles(s.user.groups || []),
                }
              : s.user,
          } as AuthStore),
      },
    ),
  ),
  { actions: {} as AuthStoreActions },
);

let expiredInterval: NodeJS.Timeout;

const createExpiredInterval = ({ isAuthenticated, expiresAt }: Pick<AuthStore, "isAuthenticated" | "expiresAt">) => {
  if (isAuthenticated && expiresAt) {
    expiredInterval = setInterval(() => {
      if (isAfter(new Date(), expiresAt)) {
        useAuth.actions.logOut();
      }
    }, 1000 * 60);
  }
};

const getAuthToken = async () =>
  fetch(CONFIG.oauthPath as string)
    .then<{ id: string; secret: string }>((r) => r.json())
    .then(({ id, secret }) => `Basic ${window.btoa(`${id}:${secret}`)}`);

const accessTokenToUser = (accessToken: string) => {
  const userFromToken = jwtDecode<UserFromToken>(accessToken);
  return {
    ...userFromToken,
    ...getUserPermissionsAndRoles(userFromToken.groups || []),
  } as User;
};

useAuth.actions = {
  logIn: async ({ username, password }) => {
    clearInterval(expiredInterval);
    useAuth.setState({ loading: true, error: null, hasError: false, expiresAt: null });

    let error: AuthError | null = null;

    const authToken = await getAuthToken();
    const tokenData = await fetch(
      `${CONFIG.tokenPath}?${new URLSearchParams({
        username,
        password,
        grant_type: "password",
        scope: "openid",
      }).toString()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
          Authorization: authToken,
        },
      },
    )
      .then<TokenResponse | ErrorResponse>((r) => r.json())
      .then((r) => {
        if ("error" in r) {
          throw new Error(r.error);
        } else {
          return r;
        }
      })
      .catch<null>((e) => {
        error = { error: e.message, tError: getAuthErrorMessageKey(e.message) };
        return null;
      });

    const user = tokenData?.access_token ? accessTokenToUser(tokenData.access_token) : null;
    const hasError = Boolean(error);
    const isAuthenticated = Boolean(user && !hasError);
    const expiresAt = tokenData?.expires_in ? addSeconds(new Date(), tokenData?.expires_in).getTime() : null;

    useAuth.setState({ loading: false, tokenData, isAuthenticated, error, user, hasError, expiresAt });
    createExpiredInterval({ isAuthenticated, expiresAt });

    return isAuthenticated;
  },

  logOut: async () => {
    clearInterval(expiredInterval);
    useAuth.setState({
      isAuthenticated: false,
      tokenData: null,
      user: null,
      error: null,
      hasError: false,
      loading: false,
      expiresAt: null,
    });
    await client.resetStore();
  },

  isRole: (role) => {
    const userRole = useAuth.getState()?.user?.role;

    if (!userRole) return false;

    return Array.isArray(role) ? role.includes(userRole) : role === userRole;
  },

  hasPermission: (permissions, checker = "some") => {
    const { user } = useAuth.getState();

    if (user) {
      const toCheck = Array.isArray(permissions) ? permissions : [permissions];
      return toCheck[checker]((perm) => user?.permissions?.[perm]);
    }

    return false;
  },

  getToken: (store = useAuth.getState()) => store.tokenData?.access_token || null,
  getUser: (store = useAuth.getState()) => store.user,
} as AuthStoreActions;

useAuth.persist.onFinishHydration(createExpiredInterval);

export const useIsRole = (role: Roles | Roles[]) => useAuth(useCallback(() => useAuth.actions.isRole(role), [role]));

export const usePermissionAccess = (
  permissions: keyof User["permissions"] | Array<keyof User["permissions"]>,
  checker?: "some" | "every",
) => useAuth(useCallback(() => useAuth.actions.hasPermission(permissions, checker), [permissions, checker]));
