import { Trans } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { useAuth } from "auth/hooks";
import { Avatar, Popover, Text } from "common/guideline";
import { TenantSelect } from "tenant/components";

export const Navbar = () => {
  const user = useAuth(useAuth.actions.getUser);
  const userName = user?.sub || "";

  return (
    <div tw="flex items-center sm:gap-x-3">
      <TenantSelect />
      <Popover
        placement="bottom-end"
        data-test="userAvatar"
        content={(close) => (
          <ul tw="text-xs p-1 leading-5 text-gray-6">
            <li>
              <Trans i18nKey="common.helloPopover" values={{ name: userName }}>
                {[<span tw="font-semibold" key="1" />]}
              </Trans>
            </li>
            <li>
              <Link route="USER_PROFILE" onClick={close}>
                <Text variant={["link", "subtitle"]} tKey="common.myProfile" />
              </Link>
            </li>
            <li>
              <Link route="TERMS" onClick={close}>
                <Text variant={["link", "subtitle"]} tKey="common.terms" />
              </Link>
            </li>
            <li>
              <a href="https://docs.paycomplete.com/" target="_blank" rel="noreferrer">
                <Text variant={["link", "subtitle"]} tKey="common.help" />
              </a>
            </li>
            <li>
              <Text
                tKey="common.signOut"
                variant={["link", "subtitle"]}
                tw="cursor-pointer"
                data-test="signOut"
                onClick={useAuth.actions.logOut}
              />
            </li>
          </ul>
        )}
      >
        <Avatar fullName={userName} />
      </Popover>
    </div>
  );
};
