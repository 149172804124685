import { WithoutNullableKeys } from "types";

import { useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindListOfUsersDocument,
  useUpdateUserPasswordMutation,
  useUpdateUserPermissionOrRoleMutation,
} from "generated";

import { UserUpdateForm } from "../components/UserUpdateForm";
import { getUpdatePasswordPayload, modifyUpdatePayload } from "../helpers";
import { IsUserData } from "../UsersTable/types";

export const useUserUpdate = ({
  userName,
}: // Comment: Below are the props I would expect to be present in each record. There is an ongoing backend work for this.
// domainName,
// locationNodeIdsPermission,
// tenantIdsPermission,
// locationGroupNodeIdsPermission,
// machineNodeIdsPermission,
// userRoles,
WithoutNullableKeys<IsUserData>) => {
  const [updateUser] = useUpdateUserPermissionOrRoleMutation();
  const [updateUserPassword] = useUpdateUserPasswordMutation();

  return useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <UserUpdateForm
        initial={{ userName, domainName: "" }}
        onSubmit={(input) => {
          updateUser({
            variables: { isUserUpdateDtoIn: modifyUpdatePayload(input) },
            ignoreResults: true,
            update: (cache) => evictQuery(FindListOfUsersDocument, cache),
          })
            .then(() => {
              const updatePasswordPayload = getUpdatePasswordPayload(input);

              if (updatePasswordPayload) {
                updateUserPassword({
                  variables: updatePasswordPayload,
                  ignoreResults: true,
                })
                  .then(() => {
                    useToast.actions.show("user.updatedPasswordSuccess", { variant: "success" });
                  })
                  .catch(() => useToast.actions.show("user.updatedPasswordError", { variant: "error" }));
              }
              useToast.actions.show("user.updateSuccess", { variant: "success" });
            })
            .catch(() => useToast.actions.show("user.updateError", { variant: "error" }));
        }}
        submitLabel={"user.update"}
      />
    ),
  });
};
